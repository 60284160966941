import { useQuery, gql } from '@apollo/client';
import {
    AccountSubscriptionInfo,
    BillingPlanKind,
    SubscriptionsPage__EnterpriseTrialsTab,
    SubscriptionsPage__EnterpriseTrialsTabVariables,
} from '../../types';
import { SubscriptionResultBox } from './SubscriptionResultBox';
import { Tags } from './SubscriptionTags';
import pluralize from '../../helpers/pluralize';

const RECENTLY_EXPIRED_QUERY = gql`
    query SubscriptionsPage__EnterpriseTrialsTab {
        enterpriseTrialAccounts {
            id
            name
            currentPlan {
                id
                kind
                name
            }
            currentSubscription {
                autoRenew
                canceledAt
                expiresAt
                currentPeriodStartedAt
                currentPeriodEndsAt
            }
            expiredTrialSubscription {
                expiresAt
            }
            isLocked
        }
    }
`;
const EnterpriseTrialsTab = () => {
    const { data, loading, error } = useQuery<
        SubscriptionsPage__EnterpriseTrialsTab,
        SubscriptionsPage__EnterpriseTrialsTabVariables
    >(RECENTLY_EXPIRED_QUERY);

    if (loading) return <p className="italic">Loading...</p>;
    if (error) return <div className="error">{error.message}</div>;

    const allAccounts = data?.enterpriseTrialAccounts
        ?.filter((a: AccountSubscriptionInfo) => a.currentPlan.kind !== BillingPlanKind.ENTERPRISE_INTERNAL)
        .sort(
            (a, b) =>
                new Date(a.currentSubscription?.currentPeriodEndsAt ?? '').getTime() -
                new Date(b.currentSubscription?.currentPeriodEndsAt ?? '').getTime()
        )
        .map((a) => {
            return {
                id: `${a.name} [${a.id}]`,
                linkUrl: `/a/${a.id}`,
                description: (
                    <>
                        Expires on{' '}
                        {new Intl.DateTimeFormat().format(
                            new Date(
                                a.currentSubscription?.currentPeriodEndsAt ?? a.expiredTrialSubscription?.expiresAt ?? 0
                            )
                        )}
                    </>
                ),
                tags: <Tags account={a} isLocked={!!a.isLocked} />,
            };
        });

    return (
        <>
            <p className="px-6 font-bold text-xl">{pluralize('account', allAccounts?.length ?? 0)}</p>
            <SubscriptionResultBox results={allAccounts} />
        </>
    );
};

export { EnterpriseTrialsTab };
