import { useQuery, gql } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import Page from '../components/Page';
import RawMetricsBox from '../components/RawMetricsBox';
import * as Types from '../types';
import moment from 'moment';
import { HeaderContext } from '../helpers/HeaderContext';
import { Input } from '@chakra-ui/react';

const RAW_USAGE_QUERY = gql`
    fragment RawUsage__PageQuery__QueryStats on QueryStatsRecord {
        metrics {
            totalRequestCount
            cachedRequestsCount
            forbiddenOperationCount
            registeredOperationCount
            requestsWithErrorsCount
            uncachedRequestsCount
        }
    }
    fragment RawUsage__PageQuery__BillingUsageStats on BillingUsageStatsRecord {
        metrics {
            operationCount
        }
    }
    query RawUsage__PageQuery($to: Timestamp, $from: Timestamp!) {
        stats(to: $to, from: $from) {
            queryStats {
                ...RawUsage__PageQuery__QueryStats
            }
            billingUsageStats {
                ...RawUsage__PageQuery__BillingUsageStats
            }
        }
    }
`;

export default function RawUsage() {
    const [from, setFrom] = useState<string>(moment().subtract(2, 'days').startOf('day').format('YYYY-MM-DD'));
    const [to, setTo] = useState<string>(moment().subtract(1, 'days').startOf('day').format('YYYY-MM-DD'));
    const { setContent } = useContext(HeaderContext);
    useEffect(() => {
        setContent(<></>);
    }, [setContent]);

    const { data, loading, error } = useQuery<Types.RawUsage__PageQuery>(RAW_USAGE_QUERY, {
        variables: {
            from: moment(from).startOf('day').toDate(),
            to: moment(to).startOf('day').toDate(),
        },
    });
    return (
        <Page>
            {loading ? (
                <div className="d-flex justify-content-center my-5">
                    <div className="spinner-grow text-primary center" role="status">
                        <span className="sr-only" />
                    </div>
                </div>
            ) : error ? (
                error.message
            ) : (
                data && (
                    <>
                        <h2>System Wide Usage Metrics</h2>
                        <div className="flex flex-col">
                            <label htmlFor="fromInput" className="font-bold text-l">
                                From (00:00 midnight)
                            </label>
                            <Input
                                id="fromInput"
                                className="w-fit"
                                size={'md'}
                                type="date"
                                onChange={(e) => {
                                    setFrom(e.target.value);
                                }}
                                value={from}
                                max={to}
                            />
                        </div>

                        <div className="flex flex-col mt-2">
                            <label htmlFor="toInput" className="font-bold text-l">
                                To (00:00 midnight)
                            </label>
                            <Input
                                id="fromInput"
                                className="w-fit"
                                size={'md'}
                                type="date"
                                onChange={(e) => {
                                    setTo(e.target.value);
                                }}
                                value={to}
                                max={from}
                            />
                        </div>
                        <div className="border mt-4 px-4">
                            <div className="my-5">
                                <div className="font-bold text-l pb-2">Billing Operations</div>
                                {data.stats.billingUsageStats.map((metric, index) => (
                                    <RawMetricsBox queryData={metric} key={`billing-${index}`} />
                                ))}
                            </div>
                            <div className="my-5">
                                <div className="font-bold text-l pb-2">
                                    Query Sampled Operations (possibly affected by report-size sampling)
                                </div>
                                {data.stats.queryStats.map((metric, index) => (
                                    <RawMetricsBox queryData={metric} key={`sample-${index}`} />
                                ))}
                            </div>
                        </div>
                    </>
                )
            )}
        </Page>
    );
}
