import { gql, useMutation } from '@apollo/client';
import {
    useDisclosure,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    Input,
    FormControl,
    useToast,
    FormLabel,
    Stepper,
    Step,
    StepIndicator,
    StepStatus,
    StepNumber,
    StepIcon,
    useSteps,
    Box,
    StepTitle,
    FormErrorMessage,
} from '@chakra-ui/react';
import { useState } from 'react';
import type { Metadata_Account__UpdateSSODomains, Metadata_Account__UpdateSSODomainsVariables } from '../../../types';
import { MinusIcon, PlusIcon, PencilIcon } from '@heroicons/react/24/outline';
import validator from 'validator';

const CONNECTION_FRAGMENT = gql`
    fragment Metadata_ConnectionFragment_UpdateDomains on SsoConnection {
        id
        idpId
        domains
        state
        ... on SamlConnection {
            metadata {
                entityId
                ssoUrl
                verificationCerts {
                    id
                    notAfter
                    notBefore
                    pem
                }
            }
        }
    }
`;
const STEPS = [
    {
        step: 0,
        title: 'Email Domains',
        header: 'Email domains',
        description: 'Update email domains with the SSO configuration.',
    },
    {
        step: 1,
        title: 'Review',
        header: 'Review',
        description: 'Review the new email domains for the SSO configuration.',
    },
];

const ACCOUNT_UPDATE_SSO_DOMAINS = gql`
    mutation Metadata_Account__UpdateSSODomains(
        $replaceSsoConnectionDomainsId: ID!
        $accountId: ID!
        $domains: [String!]!
    ) {
        account(id: $accountId) {
            replaceSsoConnectionDomains(id: $replaceSsoConnectionDomainsId, domains: $domains) {
                ...Metadata_ConnectionFragment_UpdateDomains
            }
        }
    }
    ${CONNECTION_FRAGMENT}
`;

type UpdateSSODomainsWizardProps = {
    accountId: string;
    connectionId: string;
    existingDomains: string[];
};

export const UpdateSSODomainsWizard = ({ accountId, connectionId, existingDomains }: UpdateSSODomainsWizardProps) => {
    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { activeStep, goToNext, goToPrevious } = useSteps({
        index: 0,
        count: STEPS.length - 1,
    });
    const [domains, setDomains] = useState<string[]>(existingDomains);

    const [updateSSODomains] = useMutation<
        Metadata_Account__UpdateSSODomains,
        Metadata_Account__UpdateSSODomainsVariables
    >(ACCOUNT_UPDATE_SSO_DOMAINS);

    const isInvalid = (index: number): boolean => !validator.isFQDN(domains[index]);

    return (
        <div>
            <Button
                onClick={onOpen}
                disabled={accountId === 'apollo'}
                className="disabled:cursor-not-allowed"
                size="sm"
                variant="primary"
            >
                <PencilIcon className="w-4 h-4 mr-1" />
                <div className="pl-1">Update associated email domains</div>
            </Button>

            <Modal isOpen={isOpen} onClose={onClose} size="lg">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{STEPS[activeStep].header}</ModalHeader>
                    <ModalBody>
                        <Stepper index={activeStep} size="sm" className="mb-4">
                            {STEPS.map((step) => (
                                <Step key={step.step}>
                                    <StepIndicator>
                                        <StepStatus
                                            complete={<StepIcon />}
                                            incomplete={<StepNumber />}
                                            active={<StepNumber />}
                                        />
                                    </StepIndicator>
                                    <Box>
                                        <StepTitle>{step.title}</StepTitle>
                                    </Box>
                                </Step>
                            ))}
                        </Stepper>
                        {activeStep === 0 && (
                            <>
                                <div className="flex flex-row">
                                    <div className="font-bold">Email Domains</div>
                                    <div
                                        className="ml-auto items-center inline-flex flex-row hover:cursor-pointer pr-3"
                                        onClick={() => {
                                            setDomains([...domains, '']);
                                        }}
                                    >
                                        <div>Add email domain</div>
                                        <PlusIcon className="stroke-black dark:stroke-white w-6" />
                                    </div>
                                </div>
                                <hr className="pb-3" />
                                <div className="flex flex-col space-y-2">
                                    {domains.map((domain, i) => {
                                        return (
                                            <FormControl isInvalid={isInvalid(i)} key={i}>
                                                <FormLabel
                                                    onClick={() => {
                                                        // must have at least one domain
                                                        if (domains.length === 1) {
                                                            return;
                                                        }
                                                        let newDomains = domains.slice();
                                                        newDomains.splice(i, 1);
                                                        setDomains(newDomains);
                                                    }}
                                                >
                                                    <div className="flex flex-row items-center font-semibold text-sm">
                                                        <div>Email Domain</div>
                                                        {domains.length > 1 && (
                                                            <div className="ml-auto hover:cursor-pointer">
                                                                <MinusIcon className="stroke-black dark:stroke-white w-6" />
                                                            </div>
                                                        )}
                                                    </div>
                                                </FormLabel>
                                                <Input
                                                    key={i}
                                                    required
                                                    placeholder="Email domain"
                                                    value={domains[i]}
                                                    onChange={(e) => {
                                                        let newDomains = domains.slice();
                                                        newDomains[i] = e.target.value;
                                                        setDomains(newDomains);
                                                    }}
                                                />
                                                <FormErrorMessage>
                                                    {domain[i] === '' ? 'Email is required' : 'Invalid email domain'}
                                                </FormErrorMessage>
                                            </FormControl>
                                        );
                                    })}
                                    <div className="flex flex-row space-x-2 ml-auto items-center">
                                        <Button
                                            mt={4}
                                            colorScheme="blue"
                                            type="submit"
                                            size="sm"
                                            variant="primary"
                                            isDisabled={domains.reduce(
                                                (acc, d) => acc || isInvalid(domains.indexOf(d)),
                                                false
                                            )}
                                            onClick={async (e) => {
                                                e.preventDefault();
                                                goToNext();
                                            }}
                                        >
                                            Next
                                        </Button>
                                        <Button variant="secondary" size="sm" onClick={onClose} className="mt-4">
                                            Close
                                        </Button>
                                    </div>
                                </div>
                            </>
                        )}
                        {activeStep === 1 && (
                            <>
                                <div>
                                    <div className="font-bold">Email Domains</div>
                                    <ul className="list-disc">
                                        {domains.map((domain, i) => {
                                            return (
                                                <li key={i} className="ml-2">
                                                    {domain}
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                                <div className="flex flex-row space-x-2">
                                    <Button
                                        mt={4}
                                        variant="primary"
                                        size="sm"
                                        type="submit"
                                        className="ml-auto"
                                        onClick={async () => {
                                            let { data, errors } = await updateSSODomains({
                                                variables: {
                                                    accountId: accountId,
                                                    domains: domains.filter((d) => !isInvalid(domains.indexOf(d))),
                                                    replaceSsoConnectionDomainsId: connectionId,
                                                },
                                                errorPolicy: 'all',
                                                refetchQueries: ['Metadata_Account__MetadataQuery'],
                                            });

                                            if (errors) {
                                                toast({
                                                    title: 'Error submitting config',
                                                    description: errors.map((e) => e.message).join('\n'),
                                                    status: 'error',
                                                });
                                            }
                                            if (data?.account?.replaceSsoConnectionDomains?.id) {
                                                toast({
                                                    title: 'Success',
                                                    description: 'SSO configuration submitted successfully',
                                                    status: 'success',
                                                });
                                                onClose();
                                            }
                                        }}
                                    >
                                        Submit
                                    </Button>
                                    <Button mt={4} variant="secondary" size="sm" onClick={() => goToPrevious()}>
                                        Back
                                    </Button>
                                    <Button variant="secondary" size="sm" onClick={onClose} className="mt-4">
                                        Close
                                    </Button>
                                </div>
                            </>
                        )}
                    </ModalBody>
                </ModalContent>
            </Modal>
        </div>
    );
};
