import React, { FunctionComponent, useState, useRef, useEffect } from 'react';
import { useMutation, PureQueryOptions } from '@apollo/client';
import { PencilIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { classNames } from '../helpers/classnames';
import { Button } from '@chakra-ui/react';

interface FieldEditProps {
    label?: string;
    placeholder?: string;
    variables?: object;
    overrideVariableWithInputValue?: string;
    mutation: any;
    refetch?: [PureQueryOptions];
    confirmMessage?: string;
    children?: React.ReactNode;
    labelClassName?: string;
    editButtonText?: string;
}

const FieldEdit: FunctionComponent<FieldEditProps> = ({
    label,
    placeholder,
    variables,
    overrideVariableWithInputValue,
    mutation,
    refetch,
    children,
    confirmMessage,
    labelClassName,
    editButtonText = 'Edit',
}) => {
    const inputRef = useRef(null);
    const [value, update] = useState<string | null>(null);
    const [open, toggle] = useState(false);
    useEffect(() => {
        // @ts-ignore
        if (open && inputRef && inputRef.current) inputRef.current.focus();
    }, [open]);

    const combinedVariables = Object.assign(
        {},
        variables,
        overrideVariableWithInputValue && {
            [overrideVariableWithInputValue]: value,
        }
    );

    const hide = () => {
        toggle(false);
        update(null);
    };

    const [underlyingMutate, { error, loading, called }] = useMutation(
        mutation,
        // @ts-ignore
        Object.assign(
            {
                onCompleted: hide,
            },
            refetch && {
                refetchQueries: [{ query: refetch, variables: combinedVariables }],
            },
            variables && {
                variables: combinedVariables,
            }
        )
    );

    const mutate = confirmMessage
        ? () => {
              if (window.confirm(confirmMessage)) {
                  underlyingMutate();
              } else {
                  hide();
              }
          }
        : underlyingMutate;

    return (
        <div className="flex-flex-col items-align">
            {open ? (
                <>
                    {label && (
                        <>
                            <p className={labelClassName}>{label}:</p>{' '}
                        </>
                    )}
                    <input
                        ref={inputRef}
                        type="text"
                        disabled={loading}
                        value={value || ''}
                        placeholder={placeholder || value || ''}
                        className="text-primary bg-primary disabled:text-disabled rounded px-2"
                        onKeyDown={(e) => {
                            if (e.keyCode === 27) {
                                // escape
                                hide();
                            } else if (e.keyCode === 13) {
                                // enter
                                mutate();
                            }
                        }}
                        onChange={(e) => {
                            // @ts-ignore
                            update(e.target.value);
                        }}
                    />{' '}
                    <div className="flex flex-row mb-2">
                        <Button
                            disabled={loading}
                            onClick={() => {
                                mutate();
                            }}
                            variant={'primary'}
                            size={'sm'}
                        >
                            Submit
                        </Button>
                        <Button
                            onClick={() => {
                                toggle(false);
                            }}
                            variant={'primary'}
                            size={'sm'}
                        >
                            <XMarkIcon className="w-4 h-4 mr-1" />
                            <div>Cancel</div>
                        </Button>
                    </div>
                    {loading && <p className="italic"> Loading...</p>}
                    {error && <p className="error"> {error.message}</p>}
                </>
            ) : (
                <div className="">
                    {label && (
                        <>
                            <p className={classNames('mr-2', labelClassName)}>{label}:</p>{' '}
                        </>
                    )}
                    {children}{' '}
                    <Button
                        onClick={() => {
                            toggle(true);
                        }}
                        variant={'primary'}
                        size={'sm'}
                    >
                        <PencilIcon className="w-4 h-4 mr-1" />
                        <div className="pl-1">{editButtonText}</div>
                    </Button>
                    {!loading && called && !error && <p className="italic"> Updated!</p>}
                </div>
            )}
        </div>
    );
};

export default FieldEdit;
