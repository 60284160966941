import React, { useCallback, useState } from 'react';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import DeletedBillingTab from '../components/DeletedBillingTab';
import Tab from './Account/TabNav';

const DeletedAccount = () => {
    const { internalAccountId } = useParams();
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState<string>('billing');
    const handleOnClick = useCallback(
        (path: string) => {
            setActiveTab(path);
            navigate(path, { replace: false });
        },
        [navigate]
    );

    /**
     * Tabs for navigation
     */
    const tabs: { id: string; text: string }[] = [
        {
            id: 'billing',
            text: 'Billing',
        },
    ];
    return (
        <>
            {!internalAccountId ? (
                'We could not find an account in your URL to use for lookup.'
            ) : (
                <>
                    <>
                        <ul className="flex flex-row flex-shrink">
                            {tabs.map((tab) => (
                                <Tab
                                    key={tab.id}
                                    id={tab.id}
                                    text={tab.text}
                                    active={activeTab}
                                    setActive={(key: string) => handleOnClick(key)}
                                />
                            ))}
                        </ul>
                        <main>
                            <Routes>
                                <Route
                                    path="billing"
                                    key="billing"
                                    element={
                                        <DeletedBillingTab setActiveTab={setActiveTab} accountId={internalAccountId} />
                                    }
                                />
                                <Route
                                    path="/"
                                    element={
                                        <DeletedBillingTab setActiveTab={setActiveTab} accountId={internalAccountId} />
                                    }
                                />
                            </Routes>
                        </main>
                    </>
                </>
            )}
        </>
    );
};

export default DeletedAccount;
