import { Key, useState } from 'react';
import { Link } from 'react-router-dom';
import { intersection, isEqual, uniq } from 'lodash';
import Fuse from 'fuse.js';
import { Switch } from '@headlessui/react';
import { ChevronDoubleDownIcon, ChevronDoubleUpIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { classNames } from '../helpers/classnames';

/**
 * Title: Top-left, required to show a little content
 * Description: More context; not required
 * Context: Context for the user to make a better decision. Should be pills or the like.
 * Info: Additional info/CTA
 * _________________________
 * |title           context|
 * |                       |
 * |description        info|
 * |_______________________|
 */
export type ResultBoxPropsResults = {
    // uniquely identify each result, either through userID or likewise
    id: Key;
    // name of the result- used for search
    name: string;
    // title of the result- usually a name, but can be a JSX element to provide some customization
    title: JSX.Element;
    // A small description about the result
    description: JSX.Element;
    // Additional info about the result, such as modification dates or other information to help the user
    info?: JSX.Element;
    // Context about the result, such as pill icons or otherwise
    context?: JSX.Element;
    // Where to link to
    linkUrl: string;
    // tags to be used for filtering
    tags?: Array<ResultFilter>;
    // lastly, metadata to aid in search results for fuzzy searching
    metadata?: Array<string>;
};
export type ResultFilter = {
    label: string;
    tags: Array<string>;
};
type ResultsBoxProps = {
    searchTerm: string;
    results?: Array<ResultBoxPropsResults>;
    showFuzzyToggle?: boolean;
    includeBorder?: boolean;
};

export const ResultsBox = ({ results, searchTerm, showFuzzyToggle = true, includeBorder = true }: ResultsBoxProps) => {
    let [openFilters, setOpenFilters] = useState(false);
    const [fuzzyEnabled, setFuzzyEnabled] = useState(true);
    let [filters, setFilters] = useState<Array<string>>([]);

    if (!results) {
        return <></>;
    }

    // local fuzzy search, similar to the previous search experience; helps improve
    // the results.
    const fuse = new Fuse(results ?? [], {
        keys: ['id', 'name', 'metadata'],
        findAllMatches: true,
        threshold: 0.4,
    });

    if (searchTerm !== '' && fuzzyEnabled && showFuzzyToggle) {
        results = fuse.search(searchTerm).map((v) => {
            return v.item;
        });
    }

    // on checking a filter box, it adds to the list of filters
    const onCheck = (value: string) => {
        filters.includes(value) ? setFilters(filters.filter((v) => v !== value)) : setFilters([...filters, value]);
    };

    let tags: { [key: string]: ResultFilter } = {};
    results.forEach((v) => {
        v.tags?.forEach((v) => {
            if (!tags[v.label]) {
                tags[v.label] = { ...v };
            }
            tags[v.label].tags = tags[v.label].tags.concat(...v.tags);
        });
    });

    if (filters) {
        results = results.filter((v) => {
            let aTags = uniq(v.tags?.map((i) => i.tags).flat());
            return isEqual(intersection(aTags, filters), filters);
        });
    }

    return (
        <div
            className={classNames(
                'overflow-hidden bg-primary rounded-lg mx-6',
                includeBorder ? 'border border-1 border-primary shadow' : ''
            )}
        >
            <div className="p-4 sm:px-6 flex flex-row justify-between items-start">
                {Object.keys(tags).length > 0 && (
                    <div className="flex flex-col pb-2 pl-6">
                        <button
                            className="text-left pt-2 text-heading font-semibold rounded flex flex-row items-center"
                            onClick={() => setOpenFilters(!openFilters)}
                        >
                            {!openFilters ? (
                                <ChevronDoubleDownIcon className="h-4 w-4 mr-2" />
                            ) : (
                                <ChevronDoubleUpIcon className="h-4 w-4 mr-2" />
                            )}
                            {!openFilters ? 'Open Filters' : 'Close filters'}
                        </button>
                        <div
                            className={classNames(
                                openFilters ? 'pt-2 h-100 opacity-100' : 'h-0 opacity-0 overflow-hidden',
                                'block transition-all ease-in-out duration-200'
                            )}
                        >
                            {Object.keys(tags).map((key) => {
                                let category = tags[key];
                                category.tags = uniq(category.tags).sort();
                                return (
                                    <React.Fragment key={key}>
                                        <div className="font-bold text-lg pb-2">{key}</div>
                                        <ul className="items-center text-sm font-medium rounded-lg shrink inline-flex divide-x flex-wrap flex-row">
                                            {category?.tags.map((v) => (
                                                <li className="px-8" key={v}>
                                                    <div className="flex items-center pl-3">
                                                        <input
                                                            id={v}
                                                            type="checkbox"
                                                            value={v}
                                                            checked={filters.includes(v ?? '')}
                                                            onChange={(e) => {
                                                                onCheck(e.target.value);
                                                            }}
                                                            className="w-4 h-4 text-primary bg-brand-tertiary rounded border-primary focus:ring-info focus:ring-2"
                                                        />
                                                        <label htmlFor={v} className="py-3 ml-2 text-sm font-medium">
                                                            {v}
                                                        </label>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    </React.Fragment>
                                );
                            })}
                        </div>
                    </div>
                )}
                {showFuzzyToggle && (
                    <Switch.Group as="div" className="shrink-0 ml-auto pl-3 flex items-center justify-between">
                        <span className="flex flex-grow flex-col">
                            <Switch.Label as="span" className="text-md font-medium" passive>
                                Use local search to improve results.
                            </Switch.Label>
                            <Switch.Description as="span" className="text-xs max-w-sm">
                                To show higher-quality results, you can use a local fuzzy search to improve the quality
                                of results. Disable to return all results returned from the API.
                            </Switch.Description>
                        </span>
                        <Switch
                            checked={fuzzyEnabled}
                            onChange={setFuzzyEnabled}
                            className={classNames(
                                fuzzyEnabled ? 'bg-btn-primary' : 'bg-btn-secondary-disabled',
                                'text-white relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none'
                            )}
                        >
                            <span
                                aria-hidden="true"
                                className={classNames(
                                    fuzzyEnabled ? 'translate-x-5' : 'translate-x-0',
                                    'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                )}
                            />
                        </Switch>
                    </Switch.Group>
                )}
            </div>
            <ul className="divide-y divide-theme-c3 p-6">
                {results.map((result) => (
                    <li key={result.id} className="hover:bg-secondary">
                        <Link to={result.linkUrl} className="block hover:no-underline">
                            <div className="px-4 py-4 sm:px-6">
                                <div className="flex items-center justify-between">
                                    <div className="truncate text-lg font-bold flex flex-row text-heading">
                                        {result.title}
                                    </div>
                                    {result.context}
                                </div>
                                <div className="mt-2 sm:flex sm:justify-between">
                                    <div className="mt-2 flex items-center text-sm text-silver-darkest sm:mt-0 italic text-primary">
                                        <div>{result.description}</div>
                                    </div>
                                    <div className="mt-2 flex items-center text-sm text-silver-darkest sm:mt-0 italic text-secondary">
                                        <div>{result.info}</div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    );
};
