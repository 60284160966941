import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import {
    Bars3Icon,
    CalendarIcon,
    WrenchIcon,
    FolderIcon,
    HomeIcon,
    InboxIcon,
    UsersIcon,
    XMarkIcon,
    BuildingLibraryIcon,
    UserIcon,
    ChevronDoubleLeftIcon,
    ChevronDoubleRightIcon,
} from '@heroicons/react/24/outline';
import { Link, Params } from 'react-router-dom';
import Logout from './components/Logout';
import { classNames } from './helpers/classnames';
import { HeaderContext } from './helpers/HeaderContext';
import { ReactComponent as ApolloIcon } from '@apollo/icons/logos/LogoSymbol.svg';
import { Tooltip } from '@chakra-ui/react';

type SidebarProps = {
    children: React.ReactNode;
    path: String;
    params: Readonly<Params<string>>;
    fullName?: String | null | undefined;
};

export default function Sidebar(props: SidebarProps) {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [content, setContent] = useState(<></>);
    const [isSidebarExpanded, setIsSidebarExpanded] = useState(
        JSON.parse(localStorage.getItem('isSidebarExpanded') || 'true')
    );
    const navigation = [
        {
            name: 'Home',
            href: '/',
            icon: HomeIcon,
            current: props.path === '/',
            visible: true,
        },
        {
            name: 'Account',
            href: `/a/${props.params['*']?.substring(2)}`,
            icon: FolderIcon,
            current: props.path.startsWith('/a/'),
            visible: props.path.startsWith('/a/'),
            children: [],
        },
        {
            name: 'Users',
            href: `/u/${props.params['*']?.substring(2)}`,
            icon: UsersIcon,
            current: props.path.startsWith('/u/'),
            visible: props.path.startsWith('/u/'),
        },
        {
            name: 'Plan',
            href: `/p/${props.params['*']?.substring(2)}`,
            icon: BuildingLibraryIcon,
            current: props.path.startsWith('/p/'),
            visible: props.path.startsWith('/p/'),
        },
        {
            name: 'Subscriptions',
            href: '/subscriptions',
            icon: InboxIcon,
            current: props.path === '/subscriptions',
            visible: true,
        },
        {
            name: 'Cron Jobs',
            href: '/cron',
            icon: CalendarIcon,
            current: props.path === '/cron',
            visible: true,
        },
        {
            name: 'Raw Data',
            href: '/raw',
            icon: WrenchIcon,
            current: props.path === '/raw',
            visible: true,
        },
    ];

    const items = navigation.map(
        (item) =>
            item.visible && (
                <Link
                    key={item.name}
                    to={item.href}
                    className={classNames(
                        item.current ? 'bg-neutral' : 'hover:bg-secondary',
                        'group flex items-center px-2 py-2 text-sm lg:text-base font-medium rounded text-primary gap-4'
                    )}
                >
                    <Tooltip placement="right" label={item.name} isDisabled={isSidebarExpanded}>
                        <item.icon
                            className={classNames(
                                item.current ? 'bg-neutral' : 'group-hover:text-secondary',
                                'flex-shrink-0 h-6 w-6 text-primary'
                            )}
                            aria-hidden="true"
                        />
                    </Tooltip>
                    {isSidebarExpanded && item.name}
                </Link>
            )
    );
    return (
        <HeaderContext.Provider value={{ content, setContent }}>
            <div className="flex flex-col">
                <div className="flex flex-row h-12 sticky top-0 bg-primary text-heading w-full z-10 items-center border-b border-1 border-primary">
                    <div
                        className={classNames(
                            isSidebarExpanded ? 'md:w-56' : '',
                            'h-full hidden md:flex flex-shrink-0 items-center px-4'
                        )}
                    >
                        <ApolloIcon className="h-6 w-auto fill-icon-primary" />
                    </div>
                    <div className="pl-4 w-full">{content}</div>
                </div>
                <div className="text-primary bg-secondary relative">
                    {/* START: Collapsed column */}
                    <Transition.Root show={sidebarOpen} as={Fragment}>
                        <Dialog as="div" className="relative z-40 md:hidden" onClose={setSidebarOpen}>
                            <Transition.Child
                                as={Fragment}
                                enter="transition-opacity ease-linear duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="transition-opacity ease-linear duration-300"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="fixed inset-0 bg-primary bg-opacity-75" />
                            </Transition.Child>

                            <div className="fixed inset-0 z-40 flex text-white">
                                <Transition.Child
                                    as={Fragment}
                                    enter="transition ease-in-out duration-300 transform"
                                    enterFrom="-translate-x-full"
                                    enterTo="translate-x-0"
                                    leave="transition ease-in-out duration-300 transform"
                                    leaveFrom="translate-x-0"
                                    leaveTo="-translate-x-full"
                                >
                                    <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-primary">
                                        <Transition.Child
                                            as={Fragment}
                                            enter="ease-in-out duration-300"
                                            enterFrom="opacity-0"
                                            enterTo="opacity-100"
                                            leave="ease-in-out duration-300"
                                            leaveFrom="opacity-100"
                                            leaveTo="opacity-0"
                                        >
                                            <div className="absolute top-0 right-0 -mr-12 pt-2">
                                                <button
                                                    type="button"
                                                    className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-focussed"
                                                    onClick={() => setSidebarOpen(false)}
                                                >
                                                    <span className="sr-only">Close sidebar</span>
                                                    <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                                </button>
                                            </div>
                                        </Transition.Child>
                                        <div className="h-0 flex-1 overflow-y-auto pt-5 pb-4">
                                            <div className="flex flex-shrink-0 items-center px-4">
                                                <ApolloIcon className="h-8 w-auto fill-icon-primary" />
                                            </div>
                                            <nav className="mt-5 space-y-1 px-2">{items}</nav>
                                        </div>
                                        <div className="flex flex-shrink-0 p-4">
                                            <div className="flex items-center group flex-shrink-0">
                                                <div className="ml-3">
                                                    <p className="text-base font-medium text-primary">
                                                        {props.fullName}
                                                    </p>
                                                    <div className="text-sm font-medium text-secondary group-hover:text-brand-tertiary">
                                                        <Logout />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                                <div className="w-14 flex-shrink-0" />
                                {/* Force sidebar to shrink to fit close icon */}
                            </div>
                        </Dialog>
                    </Transition.Root>
                    {/* END: Collapsed column */}

                    {/* Static sidebar for desktop */}
                    <div
                        className={classNames(
                            isSidebarExpanded ? 'md:w-56' : '',
                            'hidden md:fixed md:inset-y-0 md:flex md:flex-col border-r border-primary pt-6'
                        )}
                    >
                        <div className="flex min-h-0 flex-1 flex-col bg-primary text-primary">
                            <div className="flex flex-1 flex-col overflow-y-auto pt-5 pb-4">
                                <nav className="mt-5 flex-1 space-y-1 px-2">{items}</nav>
                            </div>
                            <div className="flex gap-4 flex-shrink-0 bg-primary p-4 items-center">
                                <Tooltip placement="right" label={props.fullName} isDisabled={isSidebarExpanded}>
                                    <UserIcon
                                        className="group-hover:text-secondary flex-shrink-0 h-6 w-6 text-primary"
                                        aria-hidden="true"
                                    />
                                </Tooltip>
                                {isSidebarExpanded && (
                                    <div className="flex flex-col group flex-shrink-0">
                                        <div className="text-base font-medium text-primary break-words md:w-40">
                                            {props.fullName}
                                        </div>
                                        <div className="text-sm font-medium text-secondary hover:text-brand-tertiary">
                                            <Logout />
                                        </div>
                                    </div>
                                )}
                            </div>
                            <button
                                onClick={() => {
                                    localStorage.setItem('isSidebarExpanded', JSON.stringify(!isSidebarExpanded));
                                    setIsSidebarExpanded(!isSidebarExpanded);
                                }}
                                className="flex gap-4 flex-shrink-0 bg-primary p-4 items-center hover:bg-secondary group text-sm lg:text-base font-medium rounded text-primary"
                            >
                                <Tooltip placement="right" label="Expand Sidebar" isDisabled={isSidebarExpanded}>
                                    {isSidebarExpanded ? (
                                        <ChevronDoubleLeftIcon
                                            className="group-hover:text-secondary flex-shrink-0 h-6 w-6 text-primary"
                                            aria-hidden="true"
                                        />
                                    ) : (
                                        <ChevronDoubleRightIcon
                                            className="group-hover:text-secondary flex-shrink-0 h-6 w-6 text-primary"
                                            aria-hidden="true"
                                        />
                                    )}
                                </Tooltip>
                                {isSidebarExpanded && <>Collapse Sidebar</>}
                            </button>
                        </div>
                    </div>

                    <div className="inline-flex fixed top-0 z-10 bg-primary text-primary hover:text-secondary hover:bg-brand-tertiary pl-1 pt-1 sm:pl-1 sm:pt-1 md:hidden shrink">
                        <button
                            type="button"
                            className="-ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded text-white hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                            onClick={() => setSidebarOpen(true)}
                        >
                            <span className="sr-only">Open sidebar</span>
                            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>
                    <div className={classNames(isSidebarExpanded ? 'md:pl-56' : 'md:pl-14', 'flex flex-col')}>
                        <main className="grow">
                            <div className="mx-auto">{props.children}</div>
                        </main>
                    </div>
                </div>
            </div>
        </HeaderContext.Provider>
    );
}
