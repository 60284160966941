import { CheckIcon } from '@heroicons/react/24/outline';
import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay } from '@chakra-ui/react';

type ConfirmationModalProps = {
    show: boolean;
    setShow: (key: boolean) => void;
    message?: string | JSX.Element;
    confirmButtonText?: string;
    callback: () => void;
    variant?: string;
};
const ConfirmationModal = ({
    show,
    setShow,
    message,
    confirmButtonText = 'Confirm',
    callback,
    variant = 'primary',
}: ConfirmationModalProps) => {
    return (
        <Modal isOpen={show} onClose={() => setShow(!show)}>
            <ModalOverlay />
            <ModalCloseButton />
            <ModalContent>
                <ModalBody className="flex flex-col items-center rounded-lg shadow-sm space-y-2 text-center">
                    <div className="font-bold text-xl">Confirmation</div>
                    <div>{message}</div>
                    <div className="inline-flex space-x-4">
                        <Button type="button" variant="secondary" size="sm" onClick={() => setShow(false)}>
                            Cancel
                        </Button>
                        <Button
                            type="button"
                            variant={variant}
                            size="sm"
                            onClick={() => {
                                callback();
                                setShow(false);
                            }}
                        >
                            <CheckIcon className="h-5 w-5 stroke-icon-primary" aria-hidden="true" />
                            {confirmButtonText}
                        </Button>
                    </div>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export { ConfirmationModal };
