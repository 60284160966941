import { useState } from 'react';
import { calcMonthTotals, calcTotal, UsageCache } from '../helpers/UsageCalc';
import UsageDetailModal from './UsageDetailModal';
import UsageTableRow from './UsageTableRow';
import { Button, Table, Tbody, Td, Th, Thead, Tooltip, Tr } from '@chakra-ui/react';
import { InformationCircleIcon } from '@heroicons/react/24/outline';

interface AccountUsageProps {
    title: string;
    groupText: string;
    cache: UsageCache;
    children?: React.ReactNode;
    type: string;
    shouldShowDetailedCounts: boolean;
}

export default function AccountUsageBox(props: AccountUsageProps) {
    const [modalState, setModalState] = useState<boolean>(false);
    const [title, setTitle] = useState<string>('');
    const showModal = (title: string) => {
        setTitle(title);
        setModalState(!modalState);
    };
    const shouldShow = props.shouldShowDetailedCounts;
    return (
        <>
            <UsageDetailModal
                type={props.type}
                cache={props.cache}
                groupText={props.groupText}
                state={modalState}
                setState={(state) => setModalState(state)}
                setTitle={title}
                shouldShowDetailedCounts={props.shouldShowDetailedCounts}
            />

            <h2 className="text-2xl pb-2 font-extrabold inline-block pr-10 text-heading">{props.title}</h2>

            <Button type="button" variant={'primary'} onClick={() => showModal(props.title)} size={'sm'}>
                Metrics Detail
            </Button>
            {props.children}
            <Table size="sm" variant="unstyled">
                <Thead className="text-heading">
                    <Tr>
                        <Th>Month</Th>
                        <Th>Total Operations</Th>
                        {shouldShow ? (
                            <>
                                <Th>Queries</Th>
                                <Th>Mutations</Th>
                                <Tooltip
                                    placement="top"
                                    label="The initial subscription operation sent by a client to the Router that's
                                            responsible for starting a new subscription"
                                >
                                    <Th>
                                        <div className="flex items-center">
                                            Subscription Registrations
                                            <InformationCircleIcon className="h-4 w-4 ml-2" />
                                        </div>
                                    </Th>
                                </Tooltip>

                                <Tooltip
                                    placement="top"
                                    label="The resolution of the client subscription's selection set in response to a
                                            subscription enabled subgraph source event"
                                >
                                    <Th>
                                        <div className="flex items-center">
                                            Subscription Notifications
                                            <InformationCircleIcon className="h-4 w-4 ml-2" />
                                        </div>
                                    </Th>
                                </Tooltip>
                            </>
                        ) : null}
                    </Tr>
                </Thead>
                <Tbody>
                    <UsageTableRow
                        metrics={calcMonthTotals(props.cache)}
                        shouldShowDetailedCounts={props.shouldShowDetailedCounts}
                    />
                </Tbody>
                <Tbody>
                    <Tr className="font-bold">
                        <Td>Grand Total:</Td>
                        <Td>{calcTotal(props.cache)}</Td>
                        {shouldShow ? (
                            <>
                                <Td>{calcTotal(props.cache, 'queries')}</Td>
                                <Td>{calcTotal(props.cache, 'mutations')}</Td>
                                <Td>{calcTotal(props.cache, 'subscriptionRequests')}</Td>
                                <Td>{calcTotal(props.cache, 'subscriptionEvents')}</Td>
                            </>
                        ) : null}
                    </Tr>
                </Tbody>
            </Table>
        </>
    );
}
