import moment from 'moment';
import { CountMap } from '../helpers/UsageCalc';
import { Td, Tr } from '@chakra-ui/react';

interface UsageTableRowProps {
    metrics: CountMap;
    includeThirdRow?: Boolean;
    shouldShowDetailedCounts: boolean;
    agentKey?: string;
}

const UsageTableRow = ({ metrics, includeThirdRow, shouldShowDetailedCounts, agentKey }: UsageTableRowProps) => {
    return (
        <>
            {Object.entries(metrics).map(([monthKey, metricValuesByOpType], monthI) => {
                const totals = (metricValuesByOpType?.total || '').toLocaleString();
                const queries = shouldShowDetailedCounts ? (metricValuesByOpType?.queries || '').toLocaleString() : '';
                const mutations = shouldShowDetailedCounts
                    ? (metricValuesByOpType?.mutations || '').toLocaleString()
                    : '';
                const subRequests = shouldShowDetailedCounts
                    ? (metricValuesByOpType?.subscriptionRequests || '').toLocaleString()
                    : '';
                const subEvents = shouldShowDetailedCounts
                    ? (metricValuesByOpType?.subscriptionEvents || '').toLocaleString()
                    : '';

                return (
                    <Tr key={monthI} className="even:bg-secondary border-b !border-primary border-1">
                        {includeThirdRow && <Td className="col-md-1">{monthI === 0 ? agentKey : ''}</Td>}
                        <Td className="col-md-1">{moment(new Date(monthKey)).format('MMMM YYYY')}</Td>
                        <Td className="col-md-1">{totals}</Td>
                        {shouldShowDetailedCounts ? (
                            <>
                                <Td className="col-md-1">{queries}</Td>
                                <Td className="col-md-1">{mutations}</Td>
                                <Td className="col-md-1">{subRequests}</Td>
                                <Td className="col-md-1">{subEvents}</Td>
                            </>
                        ) : null}
                    </Tr>
                );
            })}
        </>
    );
};

export default UsageTableRow;
