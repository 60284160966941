const Config = {
    recurlyURL: process.env.REACT_APP_RECURLY_URL,
    frontendURL: process.env.REACT_APP_FRONTEND_URL,
    backendURL: process.env.REACT_APP_BACKEND_URL,
    launchDarklyClientSideId: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID!,
    metronomeURL: process.env.REACT_APP_METRONOME_URL,
    pulsarEnableMockOperationTypeStats: process.env.REACT_APP_PULSAR_ENABLE_MOCK_OPERATION_TYPE_STATS,
    fileUploadURL: process.env.REACT_APP_FILE_UPLOAD_URL,
};

export default Config;
