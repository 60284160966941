import * as Types from '../types';

interface RawMetricsBox_Props {
    queryData: Types.RawUsage__PageQuery__QueryStats | Types.RawUsage__PageQuery__BillingUsageStats;
}

export default function RawMetricsBox({ queryData }: RawMetricsBox_Props) {
    // get metrics fieldname/value.
    const metric = Object.entries(queryData.metrics).filter(
        // ...and strip out __typename
        (value) => value[0] !== '__typename'
    ) as [string, number][];

    return (
        <>
            <table className="table table-md">
                <tbody>
                    {metric.map(([name, val]: [string, number]) => {
                        return (
                            <tr key={name} className="even:bg-secondary bg-primary font-medium">
                                <th scope="row" className="col-md-2 pr-4 text-left font-semibold">
                                    {name}
                                </th>
                                <td className="col-md-2"> {val.toLocaleString()}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </>
    );
}
