import React, { HTMLAttributes } from 'react';
import { classNames } from '../helpers/classnames';

interface Props {
    back?: boolean;
    logout?: boolean;
    children: React.ReactNode;
}

const Page: React.FC<Props & HTMLAttributes<HTMLDivElement>> = ({ children, className }) => (
    <div
        className={classNames(
            className,
            'bg-primary p-6 m-6 rounded-lg border border-1 border-primary dark:border-0 shadow',
            className?.includes('mt-') ? '' : 'mt-4'
        )}
    >
        {children}
    </div>
);

export default Page;
