import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, useToast, Button } from '@chakra-ui/react';
import { FileUpload } from '../../../FileUpload';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import {
    New_Certificate__Add_Certificate,
    New_Certificate__Add_CertificateVariables,
    New_Certificate__ParseMetadata,
    New_Certificate__ParseMetadataVariables,
} from '../../../../types';

type NewCertificateModalProps = {
    accountId: string;
    isOpen: boolean;
    onClose: () => void;
    existingCerts: string[];
};
const NEW_CERTIFICATE_PARSE_METADATA_METADATA_FRAGMENT = gql`
    fragment New_Certificate__SamIdpMetadataFragment on ParsedSamlIdpMetadata {
        entityId
        ssoUrl
        verificationCerts {
            pem
            subjectDN
            notAfter
            notBefore
        }
    }
`;

const NEW_CERTIFICATE_PARSE_METADATA_QUERY = gql`
    query New_Certificate__ParseMetadata($metadata: String!) {
        sso {
            parseSamlIdpMetadata(metadata: $metadata) {
                ...New_Certificate__SamIdpMetadataFragment
            }
        }
    }
    ${NEW_CERTIFICATE_PARSE_METADATA_METADATA_FRAGMENT}
`;

const NEW_CERTIFICATE_ADD_CERTIFICATE_MUTATION = gql`
    mutation New_Certificate__Add_Certificate($accountId: ID!, $pem: String!) {
        account(id: $accountId) {
            saml {
                addVerificationCert(pem: $pem) {
                    metadata {
                        entityId
                        ssoUrl
                    }
                }
            }
        }
    }
`;

export const NewCertificateModal = ({ isOpen, onClose, accountId, existingCerts }: NewCertificateModalProps) => {
    const toast = useToast();
    const [parse] = useLazyQuery<New_Certificate__ParseMetadata, New_Certificate__ParseMetadataVariables>(
        NEW_CERTIFICATE_PARSE_METADATA_QUERY
    );
    const [addCertificate] = useMutation<New_Certificate__Add_Certificate, New_Certificate__Add_CertificateVariables>(
        NEW_CERTIFICATE_ADD_CERTIFICATE_MUTATION
    );
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>New Certificate</ModalHeader>
                <ModalBody>
                    <FileUpload
                        onFileAccepted={async (file) => {
                            if (!file.name.endsWith('.xml')) {
                                toast({
                                    title: 'Invalid file type',
                                    description: 'Please upload a valid XML file',
                                    status: 'error',
                                });
                                return;
                            }
                            let string = new TextDecoder().decode(await file.arrayBuffer());

                            let { data } = await parse({
                                variables: {
                                    metadata: string,
                                },
                            });

                            if (!data?.sso) {
                                toast({
                                    title: 'Invalid file',
                                    description: 'Please upload a valid XML file',
                                    status: 'error',
                                });
                                return;
                            }
                            if (data.sso.parseSamlIdpMetadata?.verificationCerts?.length === 0) {
                                return;
                            }
                            let addedCerts = 0;
                            for (const cert of data.sso.parseSamlIdpMetadata?.verificationCerts ?? []) {
                                if (existingCerts.includes(cert.pem)) {
                                    return;
                                }
                                let { errors } = await addCertificate({
                                    variables: {
                                        accountId: accountId,
                                        pem: cert.pem,
                                    },
                                });

                                if (!errors) {
                                    addedCerts++;
                                }
                            }

                            if (addedCerts === 0) {
                                toast({
                                    title: 'No new certificates added',
                                    description: 'All certificates already exist',
                                    status: 'info',
                                });
                            } else {
                                toast({
                                    title: 'Certificates added',
                                    description: `${addedCerts} certificate(s) added`,
                                    status: 'success',
                                });
                            }

                            onClose();
                        }}
                    />
                    <div className="flex flex-row">
                        <Button variant="secondary" size="sm" onClick={onClose} className="mt-4 ml-auto">
                            Close
                        </Button>
                    </div>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
