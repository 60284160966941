import { Tooltip } from '@chakra-ui/react';
import * as Types from '../../types';
import { gql, Mutation } from '@apollo/react-components';

interface VerificationButtonProps {
    userId: string;
    emailVerified: boolean;
}

const RESEND_USER_VERIFICATION_EMAIL_MUTATION = gql`
    mutation User__ResendUserVerificationEmailMutation($userId: ID!) {
        user(id: $userId) {
            resendVerificationEmail
        }
    }
`;

export function ResendVerificationButton({ userId, emailVerified }: VerificationButtonProps) {
    const tooltip = 'Send the user a new verification email.';

    return emailVerified ? null : (
        <>
            <Mutation<Types.User__ResendUserVerificationEmailMutation>
                variables={{ userId: userId }}
                mutation={RESEND_USER_VERIFICATION_EMAIL_MUTATION}
            >
                {(mutate, { loading, error, called }) => (
                    <div>
                        <Tooltip placement="right" label={tooltip}>
                            <button
                                onClick={() => {
                                    mutate();
                                }}
                                className="mt-1 h-7 px-3 font-semibold rounded border border-slate-200 bg-gray-100 text-slate-900 dark:bg-gray-800 dark:text-gray-100 hover:bg-gray-200 dark:hover:bg-gray-700"
                            >
                                Resend User Verification Email
                            </button>
                        </Tooltip>{' '}
                        {loading ? <p className="italic">Loading...</p> : ''}
                        {error?.message || ''}
                        {!loading && called ? <p className="italic">Sent!</p> : ''}
                    </div>
                )}
            </Mutation>
        </>
    );
}
