import { gql, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { SAFAssessmentDetails, SAFAssessmentDetailsVariables } from '../../types';
import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { parse } from 'papaparse';

type BestPractice = {
    ID: string;
    Title: string;
    Stage: string;
    Impact: string;
    BuildLOE: string;
    BuyLOE: string;
    Description: string;
};
type FormattedBestPractices = { [key: string]: BestPractice };
type Stages = { [key: string]: FormattedBestPractices };
type LocalStoreSAF = { bestPractices: FormattedBestPractices; stages: Stages; expiry: number };

const BPS_CSV_URL = 'https://saf.apollographql.com/bps.csv';

const SAF_ASSESSMENT_QUERY = gql`
    query SAFAssessmentDetails($safAssessmentId: ID!, $serviceId: ID!) {
        service(id: $serviceId) {
            safAssessment(id: $safAssessmentId) {
                id
                startedAt
                completedAt
                planItems {
                    id
                    bestPracticeId
                    notes
                    isDeprioritized
                }
                responses {
                    id
                    questionId
                    response
                    comment
                }
            }
        }
    }
`;

export const SAFAssessment = () => {
    const { graphId, assessment } = useParams();
    const [bestPractices, setBestPractices] = useState<FormattedBestPractices>();
    const [stages, setStages] = useState<Stages>();

    // TODO: remove this entirely and move logic to API instead; this is a temp fix to
    // allow for the admin view to be visible
    useEffect(() => {
        let bestPractices: FormattedBestPractices = {};
        let stages: Stages = {};
        // check if local storage has an item
        let item = localStorage.getItem('safLocalStore');
        // if it does and it hasn't expired, use it
        if (item && JSON.parse(item).expiry > Date.now()) {
            // wrap in try/catch to catch bad entries
            try {
                let localStore: LocalStoreSAF = JSON.parse(localStorage.getItem('safLocalStore') || '');
                // set the state to the local stored object
                setBestPractices(localStore.bestPractices);
                setStages(localStore.stages);
                return;
            } catch (e) {
                localStorage.removeItem('safLocalStore');
            }
        }
        // if it doesn't or it has expired, fetch the data and store it
        fetch(BPS_CSV_URL)
            .then((response) => response.text())
            .then((text) => {
                const { data } = parse<BestPractice>(text, { header: true });

                /**
                 * convert the data into the two formats needed:
                 *      bestPractices: { bpID: { ... } }
                 *      stages: { stage: { bpID: { ... } } }
                 *  which allows for easier mapping of the data into the tables needed for admin views
                 */

                data.forEach((d) => {
                    bestPractices[d.ID] = d;
                });
                data.forEach((d) => {
                    if (!stages[d.Stage]) {
                        stages[d.Stage] = {};
                    }
                    stages[d.Stage][d.ID] = d;
                });
                // empty initialization of bestPractices and stages returns a string of undefined and an empty object;
                // deleting to keep it easy
                delete bestPractices['undefined'];
                delete stages['undefined'];

                // store the data in local storage for 24 hours
                localStorage.setItem(
                    'safLocalStore',
                    JSON.stringify({ bestPractices: bestPractices, stages: stages, expiry: Date.now() + 8.64e7 })
                );
                // finally set the state
                setBestPractices(bestPractices);
                setStages(stages);
            });
    }, []);

    const { data, loading, error } = useQuery<SAFAssessmentDetails, SAFAssessmentDetailsVariables>(
        SAF_ASSESSMENT_QUERY,
        {
            variables: { safAssessmentId: assessment ?? '', serviceId: graphId ?? '' },
        }
    );

    if (loading) {
        return (
            <div>
                <div>Loading...</div>
            </div>
        );
    }

    if (error) {
        return (
            <div>
                <div>Error: {error.message}</div>
            </div>
        );
    }

    const assessmentData = data?.service?.safAssessment;
    if (!assessmentData) {
        return (
            <div>
                <div>No assessment found.</div>
            </div>
        );
    }
    let answeredBps = assessmentData.responses.flatMap((response) => {
        return response.response;
    });

    return (
        <div>
            <div>
                <>
                    <div>
                        <div>Assessment ID: {assessmentData.id}</div>
                        <div>
                            Graph: <Link to={`../../graphs/g/${graphId}`}>{graphId}</Link>
                        </div>
                        <div>Started at: {assessmentData.startedAt}</div>
                        <div>Completed at: {assessmentData.completedAt}</div>
                    </div>
                </>
                {assessmentData.planItems.length > 0 ? (
                    <div className="pt-2 pb-4">
                        <Accordion allowToggle>
                            <AccordionItem>
                                <AccordionButton>
                                    Plan Items <AccordionIcon />
                                </AccordionButton>
                                <AccordionPanel>
                                    <Table size="sm">
                                        <Thead>
                                            <Tr>
                                                <Th>BP ID</Th>
                                                <Th>BP Title</Th>
                                                <Th>Notes</Th>
                                                <Th>Deprioritized?</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {assessmentData?.planItems.map((item) => (
                                                <Tr key={item.id}>
                                                    <Td>{item.bestPracticeId}</Td>
                                                    <Td>{bestPractices?.[item.bestPracticeId]?.Title}</Td>
                                                    <Td>{item.notes}</Td>
                                                    <Td>{item.isDeprioritized ? 'Yes' : 'No'}</Td>
                                                </Tr>
                                            ))}
                                        </Tbody>
                                    </Table>
                                </AccordionPanel>
                            </AccordionItem>
                        </Accordion>
                    </div>
                ) : (
                    <>No plan items.</>
                )}
            </div>
            <div className="pb-4">
                {assessmentData.responses.length > 0 ? (
                    <>
                        <div className="pb-4">
                            {' '}
                            <Accordion allowToggle>
                                <AccordionItem>
                                    <AccordionButton>
                                        Per-stage responses <AccordionIcon />
                                    </AccordionButton>
                                    <AccordionPanel>
                                        {stages && (
                                            <div className="flex flex-col">
                                                {Object.keys(stages).map((stage) => (
                                                    <div className="py-2 first:pt-0">
                                                        <div className="text-lg text-heading font-semibold pb-2">
                                                            {stage}
                                                        </div>
                                                        <Table size="sm">
                                                            <Thead>
                                                                <Tr>
                                                                    <Th>Title</Th>
                                                                    <Th>Checked?</Th>
                                                                </Tr>
                                                            </Thead>
                                                            <Tbody>
                                                                {Object.keys(stages[stage])
                                                                    .sort((a, b) => {
                                                                        return (bestPractices?.[a]?.Title ?? 1) >
                                                                            (bestPractices?.[b]?.Title ?? 1)
                                                                            ? 1
                                                                            : -1;
                                                                    })
                                                                    .map((response) => (
                                                                        <Tr
                                                                            key={response}
                                                                            className={
                                                                                answeredBps.includes(response)
                                                                                    ? ''
                                                                                    : 'font-bold dark:text-red-light text-red'
                                                                            }
                                                                        >
                                                                            <Td>{bestPractices?.[response].Title}</Td>
                                                                            <Td>
                                                                                {answeredBps.includes(response)
                                                                                    ? 'yes'
                                                                                    : 'no'}
                                                                            </Td>
                                                                        </Tr>
                                                                    ))}
                                                            </Tbody>
                                                        </Table>
                                                    </div>
                                                ))}
                                                <div></div>
                                            </div>
                                        )}
                                    </AccordionPanel>
                                </AccordionItem>
                            </Accordion>
                        </div>
                        <div>
                            <Accordion allowToggle>
                                <AccordionItem>
                                    <AccordionButton>
                                        Per-question responses <AccordionIcon />
                                    </AccordionButton>
                                    <AccordionPanel>
                                        <Table variant="simple" size="sm">
                                            <Thead>
                                                <Tr>
                                                    <Th>Question ID</Th>
                                                    <Th>Response</Th>
                                                    <Th>Comment</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {assessmentData.responses.map((response) => (
                                                    <Tr key={response.id}>
                                                        <Td>{response.questionId}</Td>
                                                        <Td>{response.response.join(', ')}</Td>
                                                        <Td>{response.comment}</Td>
                                                    </Tr>
                                                ))}
                                            </Tbody>
                                        </Table>
                                    </AccordionPanel>
                                </AccordionItem>
                            </Accordion>
                        </div>
                    </>
                ) : (
                    <>No responses captured.</>
                )}
            </div>
        </div>
    );
};
