import { gql, useFragment, useMutation } from '@apollo/client';
import { ACCOUNT_METADATA_FRAGMENT, ACCOUNT_METADATA_QUERY } from '../../../pages/Account/Metadata';
import {
    Metadata_AccountMetadataCommonFragment,
    Metadata_Account__DeleteSSOConfiguration,
    Metadata_Account__DeleteSSOConfigurationVariables,
    Metadata_Account__DisableSSO,
    Metadata_Account__DisableSSOVariables,
    Metadata_Account__EnableSSO,
    Metadata_Account__EnableSSOVariables,
    Metadata_Account__FinalizeSSO,
    Metadata_Account__FinalizeSSOVariables,
    Metadata_Account__UpdatePingOneSSOIDPID,
    Metadata_Account__UpdatePingOneSSOIDPIDVariables,
    OrganizationSSOProvider,
    SsoConnectionState,
    SsoConnectionStateV2,
} from '../../../types';
import { UpdateBaseConfigSAMLWizard } from './SAML';
import FieldEdit from '../../FieldEdit';
import { ACCOUNT_USERS_FRAGMENT } from '../../../pages/Account/Users';
import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Badge,
    Button,
    Divider,
    FormLabel,
    Icon,
    Input,
    Textarea,
    Tooltip,
    useToast,
} from '@chakra-ui/react';
import { useState } from 'react';
import { ConfirmationModal } from '../../ConfirmationModal';
import { NewCertificateModal } from './SAML/NewCertificateModal';
import { ReactComponent as IconCode } from '@apollo/icons/default/IconCode.svg';
import { ReactComponent as IconInfo } from '@apollo/icons/default/IconInfoSolid.svg';
import { ReactComponent as IconOutlink } from '@apollo/icons/default/IconOutlink.svg';
import { ReactComponent as IconClipboard } from '@apollo/icons/default/IconClipboard.svg';
import { BaseSSOWizard } from './BaseSAMLSSOWizard';
import { UpdateBaseConfigOIDCWizard } from './OIDC';
import Config from '../../../config';
import { UpdateSSODomainsWizard } from './UpdateSSODomainsWizard';
import { InformationalModal } from '../../InformationalModal';
import moment from 'moment';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/outline';

type SSOProps = {
    accountId: string;
};
export const CONNECTION_FRAGMENT = gql`
    fragment Metadata_ConnectionFragment on SsoConnection {
        id
        idpId
        domains
        state
        stateV2
        ... on SamlConnection {
            metadata {
                entityId
                ssoUrl
                wantsSignedRequests
                verificationCerts {
                    id
                    notAfter
                    notBefore
                    pem
                    subjectDN
                }
            }
        }
    }
`;

const ACCOUNT_UPDATE_PINGONE_SSO_IDPID_MUTATION = gql`
    mutation Metadata_Account__UpdatePingOneSSOIDPID($accountId: ID!, $idpid: String) {
        account(id: $accountId) {
            updatePingOneSSOIDPID(idpid: $idpid) {
                id
                sso {
                    provider
                    idpid
                }
                ...AccountUsers
            }
        }
    }
    ${ACCOUNT_USERS_FRAGMENT}
`;

const ACOUNT_DELTTE_SSO_CONFIGURATION_MUTATION = gql`
    mutation Metadata_Account__DeleteSSOConfiguration($id: ID!) {
        ssoV2 {
            deleteSsoConnection(id: $id) {
                ...Metadata_ConnectionFragment
            }
        }
    }

    ${CONNECTION_FRAGMENT}
`;

const ACCOUNT_DISABLE_SSO_MUTATION = gql`
    mutation Metadata_Account__DisableSSO($id: ID!) {
        ssoV2 {
            disableSsoConnection(id: $id) {
                ...Metadata_ConnectionFragment
            }
        }
    }
    ${CONNECTION_FRAGMENT}
`;

const ACCOUNT_ENABLE_SSO_MUTATION = gql`
    mutation Metadata_Account__EnableSSO($id: ID!) {
        ssoV2 {
            enableSsoConnection(id: $id) {
                ...Metadata_ConnectionFragment
            }
        }
    }
    ${CONNECTION_FRAGMENT}
`;

const ACCOUNT_FINALIZE_SSO_MUTATION = gql`
    mutation Metadata_Account__FinalizeSSO($accountId: ID!) {
        account(id: $accountId) {
            finalizeSsoV2Migration(deleteExistingWebApiKeys: true, deleteNonSsoMemberships: true)
        }
    }
`;

const stateVariants: Map<SsoConnectionStateV2, string> = new Map();
stateVariants.set(SsoConnectionStateV2.ENABLED, 'success');
stateVariants.set(SsoConnectionStateV2.DISABLED, 'error');
stateVariants.set(SsoConnectionStateV2.STAGED, 'beta');
stateVariants.set(SsoConnectionStateV2.VALIDATED, 'blue');
stateVariants.set(SsoConnectionStateV2.INITIALIZED, 'subtle');

function generateGcpLogLink(connectionId: string): string {
    const logEndDate = new Date();
    const logBeginDate = new Date();
    logBeginDate.setHours(logBeginDate.getHours() - 12);

    const env = Config.backendURL?.indexOf('staging') !== -1 ? 'staging' : 'prod';

    return `https://console.cloud.google.com/logs/query;query=resource.type%3D%22k8s_container%22%0A%2528resource.labels.container_name%3D%22engine-identity%22%2529%0Aresource.labels.namespace_name%3D%22${env}%22%0Aseverity%3E%3D%22ERROR%22%0AjsonPayload.context.sso_connection_id%3D%22${connectionId}%22%0A-jsonPayload.message%3D%22GraphQL%20response%20has%20error:%20Exception%20while%20fetching%20data%20%2528%2F_entities%5B0%5D%2FroleOverrides%2529%20:%20NOT_ALLOWED_BY_USER_ROLE%22;summaryFields=:false:32:beginning;startTime=${logBeginDate.toISOString()};endTime=${logEndDate.toISOString()}?project=mdg-services`;
}

export const SSO = ({ accountId }: SSOProps) => {
    const toast = useToast();
    const [deleteSSOConfirmation, setDeleteSSOConfirmation] = useState(false);
    const [disableSSOConfirmation, setDisableSSOConfirmation] = useState(false);
    const [enableSSOConfirmation, setEnableSSOConfirmation] = useState(false);
    const [newCertificateModal, setNewCertificateModal] = useState(false);
    const [finalizeSSOConfirmation, setFinalizeSSOConfirmation] = useState(false);
    const [debugMuetadataModal, setDebugMetadataModal] = useState(false);

    const { data } = useFragment<Metadata_AccountMetadataCommonFragment>({
        fragment: ACCOUNT_METADATA_FRAGMENT,
        fragmentName: 'Metadata_AccountMetadataCommonFragment',
        from: {
            __typename: 'Account',
            id: accountId,
        },
    });
    const [updatePingOneSSOIDPID] = useMutation<
        Metadata_Account__UpdatePingOneSSOIDPID,
        Metadata_Account__UpdatePingOneSSOIDPIDVariables
    >(ACCOUNT_UPDATE_PINGONE_SSO_IDPID_MUTATION);

    const [deleteSSOConfiguration] = useMutation<
        Metadata_Account__DeleteSSOConfiguration,
        Metadata_Account__DeleteSSOConfigurationVariables
    >(ACOUNT_DELTTE_SSO_CONFIGURATION_MUTATION);

    const [disableSSO] = useMutation<Metadata_Account__DisableSSO, Metadata_Account__DisableSSOVariables>(
        ACCOUNT_DISABLE_SSO_MUTATION
    );

    const [enableSSO] = useMutation<Metadata_Account__EnableSSO, Metadata_Account__EnableSSOVariables>(
        ACCOUNT_ENABLE_SSO_MUTATION
    );

    const [finalizeSSO] = useMutation<Metadata_Account__FinalizeSSO, Metadata_Account__FinalizeSSOVariables>(
        ACCOUNT_FINALIZE_SSO_MUTATION
    );

    if (!data) {
        return <>No data.</>;
    }

    return (
        <div>
            {(!data.sso || data.sso?.provider === 'APOLLO') && data.ssoV2?.allConnections?.length === 0 && (
                <div className="flex flex-col space-y-2">
                    <BaseSSOWizard accountId={accountId} />
                    <FieldEdit
                        variables={{ accountId: accountId }}
                        overrideVariableWithInputValue={'idpid'}
                        mutation={ACCOUNT_UPDATE_PINGONE_SSO_IDPID_MUTATION}
                        editButtonText="Enable PingOne SSO (v1, Deprecated)"
                        placeholder={'PingOne IDPID'}
                        confirmMessage={
                            `Enable PingOne SSO login for ${accountId}? This will remove all ` +
                            `members from the organization and replace them with any users that have ` +
                            `already logged in with this IDPID.`
                        }
                    />
                </div>
            )}
            {data.sso && data.sso.provider === OrganizationSSOProvider.PINGONE && (
                <div className="flex flex-col space-y-1">
                    <div className="font-bold text-lg">SSO v1 enabled (PingOne)</div>
                    <BaseSSOWizard accountId={accountId} idpId={data.sso.idpid} />
                    <div className="flex flex-col">
                        <div className="font-semibold">Provider:</div>
                        <div>{data.sso.provider}</div>
                    </div>
                    <div className="flex flex-col">
                        <div className="font-semibold">IdP ID:</div>
                        <div>{data.sso.idpid}</div>
                    </div>
                    <div className="flex flex-col">
                        <div className="font-semibold">Default role:</div>
                        <div>{data.sso.defaultRole}</div>
                    </div>
                    <div className="pb-2">
                        <Button
                            className="disabled:cursor-not-allowed"
                            variant={'destructive'}
                            size="sm"
                            disabled={accountId === 'apollo'}
                            onClick={() => {
                                if (
                                    window.confirm(
                                        `Disable PingOne SSO login for ${accountId}? This will remove all members ` +
                                            `from the organization!`
                                    )
                                ) {
                                    updatePingOneSSOIDPID({
                                        variables: { accountId: accountId, idpid: null },
                                    });
                                }
                            }}
                        >
                            Disable SSO v1
                        </Button>
                    </div>
                </div>
            )}
            {data.ssoV2?.allConnections && data.ssoV2?.allConnections?.length > 0 && (
                <div className="flex flex-col space-y-1 pb-4">
                    <div className="font-bold text-lg">
                        SSO v2 ({data.ssoV2.allConnections.length} configuration
                        {data.ssoV2.allConnections.length > 1 ? 's' : ''})
                    </div>
                    <Accordion allowMultiple defaultIndex={[0]} className="pb-4">
                        {data.ssoV2.allConnections.map((connection) => {
                            if (connection === undefined) return null;

                            return (
                                <AccordionItem>
                                    <AccordionButton>
                                        <Badge
                                            variant={
                                                (connection.stateV2 ? stateVariants.get(connection.stateV2) : 'warn') ||
                                                'warn'
                                            }
                                        >
                                            {connection.stateV2}
                                        </Badge>
                                        <div className="text-heading">
                                            {connection.__typename === 'SamlConnection'
                                                ? 'SAML'
                                                : connection.__typename === 'OidcConnection'
                                                  ? 'OIDC'
                                                  : 'Base Connection'}
                                        </div>
                                        <AccordionIcon />
                                    </AccordionButton>
                                    <AccordionPanel>
                                        <div key={connection.id} className="space-y-2">
                                            {connection.__typename === 'SamlConnection' && connection.metadata && (
                                                <InformationalModal
                                                    show={debugMuetadataModal}
                                                    setShow={setDebugMetadataModal}
                                                    message={
                                                        <div className="flex flex-col space-y-4">
                                                            <div className="font-semibold">Metadata:</div>
                                                            <div className="flex flex-col space-y-4">
                                                                <FormLabel className="font-bold">Entity ID</FormLabel>
                                                                <Input
                                                                    key={connection.metadata.entityId}
                                                                    defaultValue={connection.metadata.entityId}
                                                                    isReadOnly
                                                                    className="hover:cursor-not-allowed"
                                                                />
                                                                <FormLabel className="font-bold">SSO URL</FormLabel>
                                                                <Input
                                                                    key={connection.metadata.ssoUrl}
                                                                    defaultValue={connection.metadata.ssoUrl}
                                                                    isReadOnly
                                                                    className="hover:cursor-not-allowed"
                                                                />
                                                                <div className="flex flex-row">
                                                                    <div className="font-bold pr-2 text-heading">
                                                                        Signed Requests?
                                                                    </div>
                                                                    {connection.metadata.wantsSignedRequests ? (
                                                                        <CheckCircleIcon className="h-6 w-auto stroke-icon-success" />
                                                                    ) : (
                                                                        <XCircleIcon className="h-6 w-auto stroke-icon-error" />
                                                                    )}
                                                                </div>

                                                                <FormLabel className="font-bold">
                                                                    Certificates
                                                                </FormLabel>
                                                                <Divider />
                                                                <Accordion allowMultiple className="divide-y">
                                                                    {connection.metadata.verificationCerts?.map((v) => {
                                                                        if (!v) {
                                                                            return null;
                                                                        }
                                                                        return (
                                                                            <div
                                                                                className="text-left flex-col space-y-2 py-2"
                                                                                key={v.id + 'parent'}
                                                                            >
                                                                                <FormLabel className="font-bold">
                                                                                    ID
                                                                                </FormLabel>
                                                                                <Input
                                                                                    key={v.id}
                                                                                    defaultValue={v.id}
                                                                                    isReadOnly
                                                                                    className="hover:cursor-not-allowed"
                                                                                />
                                                                                <FormLabel className="font-bold">
                                                                                    SubjectDN
                                                                                </FormLabel>
                                                                                <Input
                                                                                    key={v.subjectDN}
                                                                                    defaultValue={v.subjectDN}
                                                                                    isReadOnly
                                                                                    className="hover:cursor-not-allowed"
                                                                                />
                                                                                <div>
                                                                                    Start:{' '}
                                                                                    {moment(v.notBefore).format(
                                                                                        'M/D/YYYY'
                                                                                    )}
                                                                                </div>
                                                                                <div>
                                                                                    Expires:{' '}
                                                                                    <Tooltip
                                                                                        label={`Expires in ${moment(
                                                                                            v.notAfter
                                                                                        ).diff(moment(), 'days')} days`}
                                                                                    >
                                                                                        {moment(v.notAfter).format(
                                                                                            'M/D/YYYY'
                                                                                        )}
                                                                                    </Tooltip>
                                                                                </div>
                                                                                <AccordionItem className="pb-2">
                                                                                    <AccordionButton>
                                                                                        <>PEM</>
                                                                                        <AccordionIcon />
                                                                                    </AccordionButton>
                                                                                    <AccordionPanel>
                                                                                        <Textarea
                                                                                            key={v.subjectDN}
                                                                                            defaultValue={v.pem}
                                                                                            isReadOnly
                                                                                            className="hover:cursor-not-allowed"
                                                                                            size="lg"
                                                                                        />
                                                                                    </AccordionPanel>
                                                                                </AccordionItem>
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </Accordion>
                                                            </div>
                                                        </div>
                                                    }
                                                />
                                            )}

                                            <ConfirmationModal
                                                show={deleteSSOConfirmation}
                                                setShow={setDeleteSSOConfirmation}
                                                message={
                                                    `Are you sure you want to delete the SSO configuration for ${accountId}? ` +
                                                    `This will remove all members from the organization!`
                                                }
                                                callback={() => {
                                                    if (connection && connection.id) {
                                                        deleteSSOConfiguration({
                                                            variables: { id: connection.id },
                                                            update: (cache, result) => {
                                                                let id = cache.identify(
                                                                    result.data?.ssoV2.deleteSsoConnection ?? {}
                                                                );
                                                                if (!!id) {
                                                                    cache.evict({ id });
                                                                }
                                                            },
                                                        });
                                                    }
                                                }}
                                                variant="destructive"
                                            />
                                            <ConfirmationModal
                                                show={disableSSOConfirmation}
                                                setShow={setDisableSSOConfirmation}
                                                message={
                                                    `Are you sure you want to disable the SSO configuration for ${accountId}? ` +
                                                    `This will remove all members from the organization!`
                                                }
                                                callback={() => {
                                                    if (connection && connection.id) {
                                                        disableSSO({ variables: { id: connection.id } });
                                                    }
                                                }}
                                                variant="destructive"
                                            />
                                            <ConfirmationModal
                                                show={enableSSOConfirmation}
                                                setShow={setEnableSSOConfirmation}
                                                message={`Are you sure you want to enable the SSO configuration for ${accountId}? `}
                                                callback={() => {
                                                    if (connection && connection.id) {
                                                        enableSSO({ variables: { id: connection.id } });
                                                    }
                                                }}
                                                variant="primary"
                                            />
                                            <ConfirmationModal
                                                show={finalizeSSOConfirmation}
                                                setShow={setFinalizeSSOConfirmation}
                                                message={
                                                    <div className="flex flex-col space-y-2">
                                                        <p>
                                                            Are you sure you want to finalize the SSO migration for{' '}
                                                            {accountId}?{' '}
                                                        </p>
                                                        <p className="font-bold pb-2">
                                                            Please ensure you have fully verified with the customer that
                                                            they are able to authenticate via SSO prior to finalizing.
                                                        </p>
                                                        <p>
                                                            This will delete all existing web API (i.e. log out non-SSO
                                                            users and remove any keys used for Rover associated with
                                                            their account) keys and non-SSO memberships for first-party
                                                            accounts (which include email and GitHub users), and remove
                                                            the ability to sign in with email and GitHub accounts. For
                                                            legacy organizations, this will also disable PingOne and
                                                            Okta application login for legacy organizations.
                                                        </p>
                                                        <p className="font-bold">This action is irreversible.</p>
                                                    </div>
                                                }
                                                callback={async () => {
                                                    if (connection && connection.id) {
                                                        try {
                                                            await finalizeSSO({
                                                                variables: {
                                                                    accountId: accountId,
                                                                },
                                                                refetchQueries: [ACCOUNT_METADATA_QUERY],
                                                            });
                                                            toast({
                                                                title: 'SSO migration finalized',
                                                                status: 'success',
                                                            });
                                                        } catch (error) {
                                                            toast({
                                                                title: `Error finalizing SSO migration: ${(error as Error).message}`,
                                                                status: 'error',
                                                            });
                                                        }
                                                    }
                                                }}
                                                variant="destructive"
                                            />
                                            {connection.__typename === 'SamlConnection' && (
                                                <>
                                                    <NewCertificateModal
                                                        accountId={accountId}
                                                        existingCerts={
                                                            connection.metadata?.verificationCerts?.map(
                                                                (c) => c?.pem ?? ''
                                                            ) || []
                                                        }
                                                        isOpen={newCertificateModal}
                                                        onClose={() => setNewCertificateModal(false)}
                                                    />
                                                </>
                                            )}
                                            <div>
                                                <div>
                                                    {connection.id && (
                                                        <>
                                                            <div className="font-semibold">
                                                                Signin URL to Studio (Apollo-initiated)
                                                            </div>
                                                            <div
                                                                className="hover:underline hover:cursor-pointer"
                                                                onClick={() => {
                                                                    navigator.clipboard.writeText(
                                                                        `${Config.backendURL}auth/sso/${connection.id}/login`
                                                                    );
                                                                    toast({
                                                                        title: 'Copied sign in URL to clipboard',
                                                                        status: 'success',
                                                                    });
                                                                }}
                                                            >
                                                                {`${Config.backendURL}auth/sso/${connection.id}/login`}
                                                                <Icon className="pl-1" as={IconClipboard} />
                                                            </div>
                                                            {(connection.__typename === 'SamlConnection' ||
                                                                connection.__typename === 'BaseConnection') && (
                                                                <Accordion
                                                                    allowToggle
                                                                    defaultIndex={[0]}
                                                                    className="py-4"
                                                                >
                                                                    <AccordionItem>
                                                                        <AccordionButton>
                                                                            <IconInfo className="h-5 pr-2 fill-icon-info" />
                                                                            <div className="text-heading">
                                                                                SAML Info for Customer
                                                                            </div>
                                                                            <AccordionIcon />
                                                                        </AccordionButton>
                                                                        <AccordionPanel>
                                                                            <div>
                                                                                <a
                                                                                    href={`${Config.backendURL}auth/sso/saml/${connection.id}/metadata`}
                                                                                    download="metadata.xml"
                                                                                    className="hover:cursor-pointer underline"
                                                                                    target="_blank"
                                                                                    rel="noreferrer noopener"
                                                                                >
                                                                                    <Button
                                                                                        variant="primary"
                                                                                        size="sm"
                                                                                        className="mb-4 whitespace-normal break-words"
                                                                                        rightIcon={
                                                                                            <Icon as={IconOutlink} />
                                                                                        }
                                                                                    >
                                                                                        Download Apollo SAML metadata
                                                                                        XML
                                                                                    </Button>
                                                                                </a>
                                                                            </div>
                                                                            <div className="font-semibold">
                                                                                Entity ID:{' '}
                                                                            </div>
                                                                            <div
                                                                                className="hover:underline hover:cursor-pointer"
                                                                                onClick={() => {
                                                                                    navigator.clipboard.writeText(
                                                                                        `${Config.backendURL}auth/sso/saml/${connection.id}/metadata`
                                                                                    );
                                                                                    toast({
                                                                                        title: 'Copied Entity ID to clipboard',
                                                                                        status: 'success',
                                                                                    });
                                                                                }}
                                                                            >
                                                                                {`${Config.backendURL}auth/sso/saml/${connection.id}/metadata`}
                                                                                <Icon
                                                                                    className="pl-1"
                                                                                    as={IconClipboard}
                                                                                />
                                                                            </div>
                                                                            <div className="font-semibold">
                                                                                SAML ACS (Login URL, IdP-initiated):{' '}
                                                                            </div>
                                                                            <div
                                                                                className="hover:underline hover:cursor-pointer"
                                                                                onClick={() => {
                                                                                    navigator.clipboard.writeText(
                                                                                        `${Config.backendURL}auth/sso/saml/${connection.id}/acs`
                                                                                    );
                                                                                    toast({
                                                                                        title: 'Copied Entity ACS URL to clipboard',
                                                                                        status: 'success',
                                                                                    });
                                                                                }}
                                                                            >
                                                                                {`${Config.backendURL}auth/sso/saml/${connection.id}/acs`}
                                                                                <Icon
                                                                                    className="pl-1"
                                                                                    as={IconClipboard}
                                                                                />
                                                                            </div>
                                                                        </AccordionPanel>
                                                                    </AccordionItem>
                                                                </Accordion>
                                                            )}
                                                            {(connection.__typename === 'OidcConnection' ||
                                                                connection.__typename === 'BaseConnection') && (
                                                                <Accordion
                                                                    allowToggle
                                                                    defaultIndex={[0]}
                                                                    className="py-4"
                                                                >
                                                                    <AccordionItem>
                                                                        <AccordionButton>
                                                                            <IconInfo className="h-5 pr-2 fill-icon-info" />
                                                                            <div className="text-heading">
                                                                                OIDC Info for Customer
                                                                            </div>
                                                                            <AccordionIcon />
                                                                        </AccordionButton>
                                                                        <AccordionPanel>
                                                                            <div className="font-semibold">
                                                                                OIDC Redirect URL:
                                                                            </div>
                                                                            <div
                                                                                className="hover:underline hover:cursor-pointer"
                                                                                onClick={() => {
                                                                                    navigator.clipboard.writeText(
                                                                                        `${Config.backendURL}auth/sso/oidc/${connection.id}/callback`
                                                                                    );
                                                                                    toast({
                                                                                        title: 'Copied Redirect URL to clipboard',
                                                                                        status: 'success',
                                                                                    });
                                                                                }}
                                                                            >
                                                                                {`${Config.backendURL}auth/sso/oidc/${connection.id}/callback`}
                                                                                <Icon
                                                                                    className="pl-1"
                                                                                    as={IconClipboard}
                                                                                />
                                                                            </div>
                                                                        </AccordionPanel>
                                                                    </AccordionItem>
                                                                </Accordion>
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="flex flex-col">
                                                <div className="font-semibold">Configured domains:</div>
                                                <div>{connection.domains?.join(', ')}</div>
                                                {connection.id && connection.domains && (
                                                    <UpdateSSODomainsWizard
                                                        accountId={accountId}
                                                        connectionId={connection.id}
                                                        existingDomains={connection.domains.flatMap((f) =>
                                                            f ? [f] : []
                                                        )}
                                                    />
                                                )}
                                            </div>
                                            <div className="flex flex-col">
                                                <div className="font-semibold">Default role:</div>
                                                <div>{data.ssoV2?.defaultRole || 'NONE'}</div>
                                            </div>
                                            <Accordion allowToggle className="pb-4">
                                                <AccordionItem>
                                                    <AccordionButton>
                                                        <Icon className="pl-1" as={IconCode} />
                                                        <div className="text-heading">Debug Info</div>
                                                        <AccordionIcon />
                                                    </AccordionButton>
                                                    <AccordionPanel>
                                                        <div className="font-semibold">Connection ID:</div>
                                                        <div
                                                            className="hover:underline hover:cursor-pointer"
                                                            onClick={() => {
                                                                navigator.clipboard.writeText(`${connection.id}`);
                                                                toast({
                                                                    title: 'Copied connection ID to clipboard',
                                                                    status: 'success',
                                                                });
                                                            }}
                                                        >
                                                            {connection.id}
                                                            <Icon className="pl-1" as={IconClipboard} />
                                                        </div>
                                                        {connection.id && (
                                                            <div className="flex flex-col">
                                                                <div className="font-semibold">
                                                                    Engineering debug logs:
                                                                </div>
                                                                <div>
                                                                    <a
                                                                        href={generateGcpLogLink(connection.id)}
                                                                        target="_blank"
                                                                        rel="noreferrer"
                                                                        className="hover:underline"
                                                                    >
                                                                        GCP logs{' '}
                                                                        <Icon className="pl-1" as={IconOutlink} />
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        )}
                                                        <div className="flex flex-col">
                                                            <div className="font-semibold">IdP ID:</div>
                                                            <div>{connection.idpId}</div>
                                                        </div>
                                                    </AccordionPanel>
                                                </AccordionItem>
                                            </Accordion>
                                            <div className="flex flex-row space-x-2">
                                                <Button variant={'primary'} size="sm" isDisabled={true}>
                                                    Update IDP ID
                                                </Button>
                                                {connection.__typename === 'BaseConnection' && connection.id && (
                                                    <>
                                                        <UpdateBaseConfigSAMLWizard
                                                            accountId={accountId}
                                                            connectionId={connection.id}
                                                        />
                                                        <UpdateBaseConfigOIDCWizard
                                                            accountId={accountId}
                                                            connectionId={connection.id}
                                                        />
                                                    </>
                                                )}
                                                {connection.__typename === 'SamlConnection' && (
                                                    <>
                                                        <Button
                                                            variant="secondary"
                                                            size="sm"
                                                            onClick={() => {
                                                                setDebugMetadataModal(true);
                                                            }}
                                                        >
                                                            Customer Metadata
                                                        </Button>
                                                        <Button
                                                            variant={'primary'}
                                                            size="sm"
                                                            onClick={() => {
                                                                setNewCertificateModal(true);
                                                            }}
                                                        >
                                                            Add new certificates
                                                        </Button>
                                                    </>
                                                )}
                                                {connection.state === SsoConnectionState.ENABLED &&
                                                    connection.__typename !== 'BaseConnection' &&
                                                    data.sso?.provider !== OrganizationSSOProvider.APOLLO && (
                                                        <>
                                                            <Button
                                                                variant={'destructive'}
                                                                size="sm"
                                                                onClick={() => setFinalizeSSOConfirmation(true)}
                                                            >
                                                                Finalize SSO Migration
                                                            </Button>
                                                        </>
                                                    )}
                                                {connection.state === SsoConnectionState.ENABLED &&
                                                    connection.__typename !== 'BaseConnection' && (
                                                        <>
                                                            <Button
                                                                variant={'destructive'}
                                                                size="sm"
                                                                onClick={() => setDisableSSOConfirmation(true)}
                                                            >
                                                                Disable SSO
                                                            </Button>
                                                        </>
                                                    )}
                                                {connection.state === SsoConnectionState.DISABLED &&
                                                    connection.__typename !== 'BaseConnection' && (
                                                        <Button
                                                            variant={'primary'}
                                                            size="sm"
                                                            onClick={() => setEnableSSOConfirmation(true)}
                                                        >
                                                            Enable SSO
                                                        </Button>
                                                    )}

                                                <Button
                                                    variant={'destructive'}
                                                    size="sm"
                                                    onClick={() => setDeleteSSOConfirmation(true)}
                                                    isDisabled={
                                                        accountId === 'apollo' ||
                                                        connection.state === SsoConnectionState.ENABLED
                                                    }
                                                >
                                                    {connection.state === SsoConnectionState.ENABLED ? (
                                                        <Tooltip label="Disable SSO first">Delete SSO Config</Tooltip>
                                                    ) : (
                                                        'Delete SSO Config'
                                                    )}
                                                </Button>
                                            </div>
                                        </div>
                                    </AccordionPanel>
                                </AccordionItem>
                            );
                        })}
                    </Accordion>
                </div>
            )}
        </div>
    );
};
