import { withLDProvider, useLDClient } from 'launchdarkly-react-client-sdk';
import { ComponentType, useEffect } from 'react';
import config from './config';
import { useIsLoggedIn } from './hooks/useIsLoggedIn';

const LD_ANONYMOUS_KEY = 'ld_anon_v1';

type Props = {
    children?: React.ReactNode;
};

const LaunchDarklyProvider: ComponentType<Props> = withLDProvider({
    clientSideID: config.launchDarklyClientSideId,
    options: {
        bootstrap: 'localStorage',
    },
    reactOptions: {
        useCamelCaseFlagKeys: false,
    },
})(({ children }: Props) => {
    const { user: { email, id } = {} } = useIsLoggedIn();
    const LDClient = useLDClient();

    useEffect(() => {
        LDClient &&
            LDClient.identify({
                key: id || LD_ANONYMOUS_KEY,
                ...(email ? { email } : {}),
            });
    }, [LDClient, email, id]);

    return <>{children}</>;
});

export default LaunchDarklyProvider;
