import React from 'react';
import * as Types from '../types';
import { useQuery, gql } from '@apollo/client';
import { Search } from '../components/Search';

export const ME_QUERY = gql`
    query Home__Auth {
        me {
            id
            ... on User {
                internalAdminRole
            }
        }
    }
`;

const Home: React.FC = () => {
    const { data, loading, error } = useQuery<Types.Home__Auth>(ME_QUERY);

    return (
        <>
            {loading ? (
                <div className="d-flex justify-content-center my-5">
                    <div className="spinner-grow text-primary center" role="status">
                        <span className="sr-only" />
                    </div>
                </div>
            ) : error ? (
                error.message
            ) : !(data && data.me?.__typename === 'User' && data?.me.internalAdminRole) ? (
                'You are not allowed to use this app. Please contact an Apollo admin if you need access.'
            ) : (
                <Search />
            )}
        </>
    );
};

export default Home;
