import { gql, useQuery } from '@apollo/client';
import {
    SAFAssessmentListByAccount,
    SAFAssessmentListByAccountAssessmentFragment,
    SAFAssessmentListByAccountGraphFragment,
    SAFAssessmentListByAccountVariables,
} from '../../types';
import { Button, Spinner, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { ReactComponent as IconOutlink } from '@apollo/icons/default/IconOutlink.svg';

const SAF_LIST_BY_ACCOUNT_QUERY_GRAPH_ASSESSMENT = gql`
    fragment SAFAssessmentListByAccountAssessmentFragment on SafAssessment {
        id
        startedAt
        completedAt
    }
`;
const SAF_LIST_BY_ACCOUNT_QUERY_GRAPH = gql`
    fragment SAFAssessmentListByAccountGraphFragment on Service {
        id
        safAssessments {
            ...SAFAssessmentListByAccountAssessmentFragment
        }
    }
`;
const SAF_LIST_BY_ACCOUNT_QUERY = gql`
    query SAFAssessmentListByAccount($accountId: ID!) {
        account(id: $accountId) {
            id
            graphs {
                ...SAFAssessmentListByAccountGraphFragment
            }
        }
    }
    ${SAF_LIST_BY_ACCOUNT_QUERY_GRAPH}
    ${SAF_LIST_BY_ACCOUNT_QUERY_GRAPH_ASSESSMENT}
`;
interface SAFAssessmentListProps {
    accountId: string;
}

type FlattenedSAFAssessmentList = {
    graph: string;
} & SAFAssessmentListByAccountAssessmentFragment;

const flattenSAFAssessmentList = (graphs: SAFAssessmentListByAccountGraphFragment[]) => {
    const assessments: FlattenedSAFAssessmentList[] =
        graphs?.flatMap((graph) =>
            graph.safAssessments.map((a) => ({
                ...a,
                graph: graph.id,
            }))
        ) || [];
    return assessments.sort((a, b) => {
        return a.startedAt > b.startedAt ? -1 : 1;
    });
};

export const SAFAssessmentList = ({ accountId }: SAFAssessmentListProps) => {
    const { data, loading, error } = useQuery<SAFAssessmentListByAccount, SAFAssessmentListByAccountVariables>(
        SAF_LIST_BY_ACCOUNT_QUERY,
        {
            variables: { accountId },
        }
    );

    if (loading) {
        return <Spinner label="Loading..." />;
    }

    if (error) {
        return <div>Error! {error.message}</div>;
    }

    if (!data?.account?.graphs) {
        return <div>No data.</div>;
    }

    if (data.account.graphs.filter((graph) => graph.safAssessments.length > 0).length === 0) {
        return <div>No assessments.</div>;
    }

    return (
        <div>
            <h1>SAF Assessment List</h1>
            <div>
                {data?.account?.graphs && (
                    <Table variant="unstyled" size={'sm'}>
                        <Thead>
                            <Tr>
                                <Th>Graph</Th>
                                <Th>Started At</Th>
                                <Th>Completed At</Th>
                                <Th>View Details</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {flattenSAFAssessmentList(data.account.graphs).map((assessment) => (
                                <Tr key={assessment.id} className="even:bg-secondary border-b !border-primary border-1">
                                    <Td>
                                        <Link
                                            to={`${assessment.graph}/${assessment.id}`}
                                            className="hover:underline font-bold"
                                        >
                                            {assessment.graph}
                                        </Link>
                                    </Td>
                                    <Td>{assessment.startedAt}</Td>
                                    <Td>{assessment.completedAt}</Td>
                                    <Td>
                                        <Link to={`${assessment.graph}/${assessment.id}`}>
                                            <Button variant={'secondary'} size="xs">
                                                <IconOutlink className="h-4 stroke-icon-primary pr-2" />
                                                View details
                                            </Button>
                                        </Link>
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                )}
            </div>
        </div>
    );
};
