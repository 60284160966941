import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay } from '@chakra-ui/react';

type InformationalModalProps = {
    show: boolean;
    setShow: (key: boolean) => void;
    message?: string | JSX.Element;
    confirmButtonText?: string;
};

export const InformationalModal = ({ show, setShow, message }: InformationalModalProps) => {
    return (
        <Modal isOpen={show} onClose={() => setShow(!show)} size="lg">
            <ModalOverlay />
            <ModalCloseButton />
            <ModalContent>
                <ModalBody className="flex flex-col rounded-lg shadow-sm space-y-2 text-center">
                    <div>{message}</div>
                    <div className="flex flex-row space-x-2 ml-auto items-center">
                        <Button type="button" variant="secondary" size="sm" onClick={() => setShow(false)}>
                            Close
                        </Button>
                    </div>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
