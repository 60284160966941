import React, { FunctionComponent, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Button } from '@chakra-ui/react';

interface FieldSelectProps {
    valueMap: Map<string, string>;
    mutation: any;
    userid: string;
    userRole: string | null;
}

const FieldSelector: FunctionComponent<FieldSelectProps> = ({ valueMap, mutation, userid, userRole }) => {
    const [curRole, setRole] = useState<string | null>(userRole);
    const [mutate, { error, loading, called }] = useMutation(mutation);

    return (
        <div className="inline-flex items-center">
            <select
                className="bg-secondary mr-2 rounded border-primary"
                defaultValue={userRole || 'delete'}
                onChange={(event) => {
                    event.target.value === 'delete' ? setRole(null) : setRole(event.target.value);
                }}
            >
                {Array.from(valueMap).map(([dispName, value]) => (
                    <option value={value} key={value}>
                        {dispName}
                    </option>
                ))}
            </select>{' '}
            <Button
                onClick={() => {
                    mutate({ variables: { id: userid, newRole: curRole } });
                }}
                type="button"
                variant={'primary'}
                size={'sm'}
            >
                Submit
            </Button>
            {loading && <p className="italic"> Loading...</p>}
            {error && (
                <div>
                    <p className="error" style={{ wordBreak: 'normal' }}>
                        {/* This is brittle, but is a simple way to handle the error nicely for now */}
                        {/User is not a part of the Apollo Org/.test(error.message)
                            ? 'Only Apollo SSO users are allowed to be granted superadmin privileges'
                            : error.message}
                    </p>
                </div>
            )}
            {!loading && called && !error && <p className="italic"> Updated!</p>}
        </div>
    );
};
export default FieldSelector;
