import { gql, useMutation } from '@apollo/client';
import {
    useDisclosure,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    Input,
    useToast,
    Spinner,
} from '@chakra-ui/react';
import type { SSO_Wizard__CreateOIDCConfigURL, SSO_Wizard__CreateOIDCConfigURLVariables } from '../../../../types';
import { PencilIcon } from '@heroicons/react/24/outline';

const SSO_WIZARD_CREATE_OIDC_CONFIG_URL_MUTATION = gql`
    mutation SSO_Wizard__CreateOIDCConfigURL($accountId: ID!, $connectionId: ID!) {
        account(id: $accountId) {
            createOidcConfigurationUrl(id: $connectionId)
        }
    }
`;

type SSOWizardProps = {
    connectionId: string;
    idpId?: string;
    accountId: string;
};

export const UpdateBaseConfigOIDCWizard = ({ connectionId, accountId }: SSOWizardProps) => {
    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [getURL, { data, error, loading }] = useMutation<
        SSO_Wizard__CreateOIDCConfigURL,
        SSO_Wizard__CreateOIDCConfigURLVariables
    >(SSO_WIZARD_CREATE_OIDC_CONFIG_URL_MUTATION);

    if (error) {
        toast({
            title: 'Error fetching OIDC URL',
            description: error.message,
            status: 'error',
        });
        return <>{error}</>;
    }
    if (!('TextDecoder' in window)) {
        return (
            <>
                Sorry, your browser does not appear to support necessary features to make the wizard work. Please use
                Google Chrome or Firefox to proceed.
            </>
        );
    }

    return (
        <div>
            <Button
                onClick={() => {
                    onOpen();
                    getURL({ variables: { accountId, connectionId } });
                }}
                disabled={accountId === 'apollo'}
                className="disabled:cursor-not-allowed disabled:opacity-50 text-white bg-blue px-3 py-1 font-semibold rounded hover:bg-blue-darker items-center flex flex-row"
                size="sm"
                variant="primary"
            >
                <PencilIcon className="w-4 h-4 mr-1" />
                <div className="pl-1">Generate OIDC configuration link</div>
            </Button>

            <Modal isOpen={isOpen} onClose={onClose} size="lg">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>OIDC Configuration Link</ModalHeader>
                    <ModalBody>
                        {data?.account?.createOidcConfigurationUrl && (
                            <div>
                                <p>Provide the following link to the customer to complete configuration.</p>
                                <Input
                                    value={data.account.createOidcConfigurationUrl}
                                    readOnly
                                    className="cursor-pointer"
                                    onClick={() => {
                                        if (!data?.account?.createOidcConfigurationUrl) return;
                                        navigator.clipboard.writeText(data.account.createOidcConfigurationUrl);
                                        toast({
                                            description: 'Configuration URL copied to clipboard',
                                            status: 'success',
                                            duration: 1_000,
                                        });
                                    }}
                                />
                            </div>
                        )}
                        {loading && <Spinner />}
                        {error && <p>{(error as Error).message}</p>}
                        <div className="flex">
                            <Button variant="secondary" size="sm" onClick={onClose} className="ml-auto mt-4">
                                Close
                            </Button>
                        </div>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </div>
    );
};
