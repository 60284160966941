import _ from 'lodash';

export const customColorsRaw = {
    theme: {
        c1: 'var(--theme-color-1)',
        c2: 'var(--theme-color-2)',
        c3: 'var(--theme-color-3)',
        c4: 'var(--theme-color-4)',
        c5: 'var(--theme-color-5)',
        c7: 'var(--theme-color-7)',
    },
    border: {
        primary: 'var(--color-border-primary)',
        hover: 'var(--color-border-hover)',
        success: 'var(--color-border-success)',
        error: 'var(--color-border-error)',
        warning: 'var(--color-border-warning)',
        neutral: 'var(--color-border-neutral)',
        info: 'var(--color-border-info)',
        beta: 'var(--color-border-beta)',
        highlight: 'var(--color-border-highlight)',
        focused: 'var(--color-border-focused)',
        disabled: 'var(--color-border-disabled)',
        selected: 'var(--color-border-selected)',
        splitbutton: 'var(--color-border-splitbutton)',
        deselected: 'var(--color-border-deselected)',
        white: 'var(--color-border-white)',
        black: 'var(--color-border-black)',
    },
    text: {
        primary: 'var(--color-text-primary)',
        secondary: 'var(--color-text-secondary)',
        placeholder: 'var(--color-text-placeholder)',
        heading: 'var(--color-text-heading)',
        link: 'var(--color-text-link)',
        success: 'var(--color-text-success)',
        error: 'var(--color-text-error)',
        warning: 'var(--color-text-warning)',
        neutral: 'var(--color-text-neutral)',
        info: 'var(--color-text-info)',
        beta: 'var(--color-text-beta)',
        disabled: 'var(--color-text-disabled)',
        inverted: 'var(--color-text-inverted)',
        white: 'var(--color-text-white)',
        black: 'var(--color-text-black)',
    },
    bg: {
        // TODO - remove once Explorer theme is cleaned up
        'primary-inverted': 'var(--color-bg-primary-inverted)',
        // TODO - remove once Explorer theme is cleaned up
        'secondary-inverted': 'var(--color-bg-secondary-inverted)',
        primary: 'var(--color-bg-primary)',
        secondary: 'var(--color-bg-secondary)',
        success: 'var(--color-bg-success)',
        'success-selected': 'var(--color-bg-success-selected)',
        error: 'var(--color-bg-error)',
        'error-selected': 'var(--color-bg-error-selected)',
        warning: 'var(--color-bg-warning)',
        'warning-selected': 'var(--color-bg-warning-selected)',
        neutral: 'var(--color-bg-neutral)',
        info: 'var(--color-bg-info)',
        beta: 'var(--color-bg-beta)',
        highlight: 'var(--color-bg-highlight)',
        searchhighlight: 'var(--color-bg-searchhighlight)',
        tableheader: 'var(--color-bg-tableheader)',
        input: 'var(--color-bg-input)',
        white: 'var(--color-bg-white)',
        selected: 'var(--color-bg-selected)',
        deselected: 'var(--color-bg-deselected)',
        disabled: 'var(--color-bg-disabled)',
        black: 'var(--color-bg-black)',
        notice: 'var(--color-bg-notice)',
    },
    btn: {
        primary: 'var(--color-btn-primary)',
        'primary-hover': 'var(--color-btn-primary-hover)',
        destructive: 'var(--color-btn-destructive)',
        'destructive-hover': 'var(--color-btn-destructive-hover)',
        secondary: 'var(--color-btn-secondary)',
        'secondary-hover': 'var(--color-btn-secondary-hover)',
        'secondary-selected': 'var(--color-btn-secondary-selected)',
    },
    icon: {
        primary: 'var(--color-icon-primary)',
        secondary: 'var(--color-icon-secondary)',
        success: 'var(--color-icon-success)',
        error: 'var(--color-icon-error)',
        warning: 'var(--color-icon-warning)',
        change: 'var(--color-icon-change)',
        info: 'var(--color-icon-info)',
        beta: 'var(--color-icon-beta)',
        disabled: 'var(--color-icon-disabled)',
        inverted: 'var(--color-icon-inverted)',
        white: 'var(--color-icon-white)',
    },
    brand: {
        primary: 'var(--color-brand-primary)',
        secondary: 'var(--color-brand-secondary)',
        tertiary: 'var(--color-brand-tertiary)',
        accent: 'var(--color-brand-accent)',
    },
    code: {
        a: 'var(--color-code-a)',
        b: 'var(--color-code-b)',
        c: 'var(--color-code-c)',
        d: 'var(--color-code-d)',
        e: 'var(--color-code-e)',
        f: 'var(--color-code-f)',
        g: 'var(--color-code-g)',
    },
    flowchart: {
        text: 'var(--color-flowchart-text)',
        a: 'var(--color-flowchart-a)',
        b: 'var(--color-flowchart-b)',
        c: 'var(--color-flowchart-c)',
        d: 'var(--color-flowchart-d)',
    },
} satisfies Record<string, Record<string, `var(--color-${string}-${string})` | `var(--theme-color-${number})`>>;

export const customColors = _.mapValues(customColorsRaw, (v) =>
    _.mapValues(v, (rgb) => `rgb(${rgb})`)
) as typeof customColorsRaw;
