import React, { FunctionComponent, useRef, useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import Column from '../../components/Column';
import * as Types from '../../types';
import 'chart.js/auto';
import 'chartjs-adapter-moment';
import moment from 'moment';
import { LineChart } from '../../components/LineChart';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { Loading } from '../../components/LoadingSpinner';

const GRAPH_CHART_QUERY = gql`
    query Account__GraphChartQuery($id: ID!, $from: Timestamp!) {
        account(id: $id) {
            id
            services: graphs {
                id
                deletedAt
                statsWindow(from: $from, to: "-0", resolution: R1D) {
                    queryStats {
                        timestamp
                        metrics {
                            totalRequestCount
                        }
                    }
                }
            }
        }
    }
`;

interface PageProps {
    accountId: string;
}

const GraphChart: FunctionComponent<PageProps> = ({ accountId }) => {
    const doNotUseQueryStats = accountId === 'redbull';
    const [days, updateDays] = useState(90);
    const { data, loading, error } = useQuery<Types.Account__GraphChartQuery>(GRAPH_CHART_QUERY, {
        variables: { from: `-${days * 24 * 60 * 60}`, id: accountId },
        skip: doNotUseQueryStats,
    });
    const value = useRef<HTMLInputElement>(null);
    const submit = (value: number) => {
        updateDays(value);
    };

    if (doNotUseQueryStats) {
        return (
            <div style={{ marginBottom: 20 }}>
                We won't query for stats for this account because it places high load on our backend.
            </div>
        );
    }
    if (loading)
        return (
            <Column num={1}>
                <Loading />
            </Column>
        );
    if (error) return <div>{error.message}</div>;
    if (!data || !data.account)
        return (
            <div>
                Account <p>{accountId}</p>'s charts could not be found.
            </div>
        );

    const { account } = data;
    const services = account.services.filter((s) => !s.deletedAt);

    const cache: { [key: string]: any } = {};
    services.forEach((service) => {
        service.statsWindow?.queryStats.forEach((stat) => {
            const seen = cache[stat.timestamp] || {};
            seen[service.id] = stat.metrics.totalRequestCount;
            cache[stat.timestamp] = seen;
        });
    });

    const transformed: { [key: string]: { [key: string]: number } } = {};
    Object.keys(cache).forEach((timestamp) => {
        let fixedTimestamp = moment.utc(new Date(timestamp)).startOf('day').toISOString();
        services.forEach((service) => {
            const seen = transformed[service.id] || {};
            seen[fixedTimestamp] = cache[timestamp][service.id] || 0;
            transformed[service.id] = seen;
        });
    });

    let colorArray = [
        '#2563eb',
        '#a855f7',
        '#d946ef',
        '#fbbf24',
        '#be185d',
        '#4d7c0f',
        '#84cc16',
        '#22d3ee',
        '#4f46e5',
        '#f472b6',
        '#ea580c',
        '#b91c1c',
        '#84cc16',
        '#831843',
        '#059669',
        '#fde68a',
    ];

    const multiseries = Object.keys(transformed).map((serviceId, index) => {
        const series = transformed[serviceId];

        // ensure data is captured up until today - so if the graph takes 0 traffic, we fill for today, which will ensure the graph shows 0 at the end.
        let today = moment.utc().startOf('day').toISOString();
        if (!Object.keys(series).includes(today)) {
            series[today] = 0;
        }

        return {
            label: serviceId,
            data: Object.keys(series)
                .map((timestamp) => {
                    // okay yes this looks bad but dates suck
                    // convert the stringified timestamp from the API, which is in GMT
                    let startDate = new Date(timestamp);
                    // now get the timezone offset, which is expressed in minutes. we convert to milliseconds
                    let offset = startDate.getTimezoneOffset() * 60 * 1000;
                    return {
                        // and add it to the start time. what this does is effectively treat it as GMT 0 while still being in the user's timezone
                        x: new Date(startDate.getTime() + offset),
                        y: series[timestamp],
                    };
                })
                .sort((a, b) => b.x.valueOf() - a.x.valueOf()), // finally, sort it from soonest to latest
            borderColor: colorArray[index % colorArray.length],
            backgroundColor: colorArray[index % colorArray.length],
        };
    });

    return (
        <Column num={1}>
            {multiseries.length && !loading ? (
                <div className="flex flex-col pb-10">
                    <div className="flex flex-col text-left">
                        <h3 className="text-lg font-extrabold text-heading">
                            Total operations per specified day(s) per graph
                        </h3>
                        <p className="text-sm italic text-primary">
                            The chart uses the query operations data for visualization.
                        </p>
                    </div>
                    <LineChart data={{ datasets: multiseries }} />
                    <p className="font-bold pr-2">Data range: </p>{' '}
                    <div className="mt-1 flex">
                        <div className="relative flex flex-shrink items-stretch focus-within:z-10 rounded shadow">
                            <input
                                type="number"
                                name="number"
                                id="number"
                                className="bg-input block w-full rounded-none rounded-l border-1 border-primary pl-6 sm:text-sm"
                                placeholder="Number of days to query"
                                defaultValue={days}
                                ref={value}
                            />
                        </div>
                        <button
                            type="button"
                            onClick={() => submit(value.current?.valueAsNumber ?? 90)}
                            className="text-white bg-btn-primary hover:bg-btn-primary-hover relative -ml-px inline-flex items-center space-x-2 rounded-r border border-1 border-primary px-4 py-2 text-sm font-bold focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 shadow"
                        >
                            <CheckCircleIcon className="h-5 w-5 stroke-white text-lg" aria-hidden="true" />
                            <span>Submit</span>
                        </button>
                    </div>
                </div>
            ) : (
                <>No usage data to display.</>
            )}
        </Column>
    );
};

export default GraphChart;
