import { Bar } from 'react-chartjs-2';

export type BarChartProps = {
    stacked?: boolean;
    data: BarChartData;
    title?: string;
};

export type BarChartData = {
    [key: string]: any;
    datasets: any;
};
export const BarChart = ({ stacked = true, data, title }: BarChartProps) => {
    const fontColor = window.matchMedia('(prefers-color-scheme: dark)').matches ? '#fff' : '#212529';
    return (
        <>
            <Bar
                className="mb-2"
                data={data}
                options={{
                    plugins: {
                        title: {
                            text: title,
                            display: !!title,
                            color: fontColor,
                        },
                        legend: {
                            display: data.datasets.length < 50, // avoids weird display issues
                            onClick(e, legendItem) {
                                // Sourced from: https://stackoverflow.com/a/42565029 with minor edits to support TS
                                var index = legendItem.datasetIndex ?? 0;
                                var ci = this.chart;
                                var alreadyHidden =
                                    ci.getDatasetMeta(index).hidden === null ? false : ci.getDatasetMeta(index).hidden;
                                var anyOthersAlreadyHidden = false;
                                var allOthersHidden = true;

                                // figure out the current state of the labels
                                ci.data.datasets.forEach(function (e, i) {
                                    var meta = ci.getDatasetMeta(i);

                                    if (i !== index) {
                                        if (meta.hidden) {
                                            anyOthersAlreadyHidden = true;
                                        } else {
                                            allOthersHidden = false;
                                        }
                                    }
                                });

                                // if the label we clicked is already hidden
                                // then we now want to unhide (with any others already unhidden)
                                if (alreadyHidden) {
                                    ci.getDatasetMeta(index).hidden = !ci.getDatasetMeta(index).hidden;
                                } else {
                                    // otherwise, lets figure out how to toggle visibility based upon the current state
                                    ci.data.datasets.forEach(function (e, i) {
                                        var meta = ci.getDatasetMeta(i);

                                        if (i !== index) {
                                            // handles logic when we click on visible hidden label and there is currently at least
                                            // one other label that is visible and at least one other label already hidden
                                            // (we want to keep those already hidden still hidden)
                                            if (anyOthersAlreadyHidden && !allOthersHidden) {
                                                meta.hidden = true;
                                            } else {
                                                // toggle visibility
                                                meta.hidden = !meta.hidden;
                                            }
                                        } else {
                                            meta.hidden = false;
                                        }
                                    });
                                }

                                ci.update();
                            },
                            // set the pointer and unset appropriately to help with UX
                            onHover() {
                                document.body.style.cursor = 'pointer';
                            },
                            onLeave() {
                                document.body.style.cursor = 'unset';
                            },
                        },
                        tooltip: {
                            mode: 'x',
                            intersect: false,
                            itemSort(a, b) {
                                return b.parsed.y - a.parsed.y;
                            },
                            filter: (e, _, array) => {
                                if (array.length < 50) {
                                    return true;
                                }
                                return e.parsed.y !== 0;
                            },
                            callbacks: {
                                footer: (tooltipItems) => {
                                    let sum = 0;

                                    tooltipItems.forEach((tooltipItem) => {
                                        sum += tooltipItem.parsed.y;
                                    });
                                    return 'Total: ' + sum.toLocaleString();
                                },
                            },
                        },
                    },
                    color: fontColor,
                    scales: {
                        x: {
                            type: 'time',
                            grid: {
                                color: `rgba(209, 213, 219, ${
                                    window.matchMedia('(prefers-color-scheme: dark)').matches ? '.25' : '1'
                                })`,
                            },
                            ticks: {
                                color: fontColor,
                            },
                            time: {
                                unit: 'day',
                                tooltipFormat: 'LL',
                                displayFormats: {
                                    day: 'MMM D YY',
                                },
                            },
                            stacked: true,
                        },
                        y: {
                            grid: {
                                color: `rgba(209, 213, 219, ${
                                    window.matchMedia('(prefers-color-scheme: dark)').matches ? '.03' : '.1'
                                })`,
                            },
                            ticks: {
                                color: fontColor,
                            },
                            beginAtZero: true,
                            stacked: true,
                        },
                    },
                }}
            />
        </>
    );
};
