import { gql, useLazyQuery, useMutation } from '@apollo/client';
import * as Types from '../types';
import { AuthRoleCheck } from './AuthRoleCheck';
import { useEffect, useMemo, useState } from 'react';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { ConfirmationModal } from './ConfirmationModal';
import { Button } from '@chakra-ui/react';
import { USER_QUERY } from '../pages/User';

const USER_ODYSSEY_TRANSFER_MUTATION = gql`
    mutation User__OdysseyTransferMutation($from: ID!, $to: ID!) {
        transferOdysseyProgress(from: $from, to: $to)
    }
`;

const USER_ODYSSEY_TRANSFER_VERIFY_USER = gql`
    query User__OdysseyTransferVerifyUser($id: ID!) {
        user(id: $id) {
            id
            fullName
            email
            odysseyCourses {
                id
            }
            odysseyCertifications {
                id
            }
        }
    }
`;

export const TransferOdysseyProgress: React.FC<{
    to: string;
}> = ({ to }) => {
    const [userId, setUserId] = useState('');
    const [confirmModalState, setConfirmModalState] = useState<boolean>(false);

    const [verifyUser, verifyUserQuery] = useLazyQuery<
        Types.User__OdysseyTransferVerifyUser,
        Types.User__OdysseyTransferVerifyUserVariables
    >(USER_ODYSSEY_TRANSFER_VERIFY_USER);

    useEffect(() => {
        if (userId === '') {
            return;
        }
        const timeout = setTimeout(() => {
            verifyUser({ variables: { id: userId } });
        }, 500);
        return () => clearTimeout(timeout);
    }, [userId, verifyUser]);

    const [transferOdysseyProgress, { loading }] = useMutation<
        Types.User__OdysseyTransferMutation,
        Types.User__OdysseyTransferMutationVariables
    >(USER_ODYSSEY_TRANSFER_MUTATION, {
        // bust the local cache to avoid double transfer, and refresh the local state to avoid requiring refresh
        refetchQueries: [
            { query: USER_ODYSSEY_TRANSFER_VERIFY_USER, variables: { id: userId } },
            { query: USER_QUERY, variables: { id: to } },
        ],
        // once finished, reset the userId and show a success message
        onCompleted: () => {
            setUserId('');
            alert('Odyssey progress transferred successfully');
        },
    });

    // Calculate verification message to show to the user
    const verifiedUser = useMemo(() => {
        if (!verifyUserQuery.called || userId === '') {
            return 'Enter user id to transfer progress from';
        }
        if (verifyUserQuery.loading) {
            return 'Verifying user...';
        }
        if (verifyUserQuery.error) {
            return 'Error verifying user';
        }
        if (verifyUserQuery.data?.user) {
            if (verifyUserQuery.data.user.id === to) {
                return `Cannot transfer from the same user`;
            }
            if (
                verifyUserQuery.data?.user.odysseyCertifications?.length === 0 &&
                verifyUserQuery.data?.user.odysseyCourses?.length === 0
            ) {
                return 'User has no progress to transfer';
            }
            return `From: ${verifyUserQuery.data.user.fullName} (${verifyUserQuery.data.user.email})`;
        }
        return 'Unknown user';
    }, [verifyUserQuery, to, userId]);

    // Determine if a transfer is possible
    const canTransfer = useMemo(() => {
        return (
            Boolean(
                verifyUserQuery.data?.user &&
                    verifyUserQuery.data?.user.id !== to &&
                    (verifyUserQuery.data?.user?.odysseyCertifications?.length !== 0 ||
                        verifyUserQuery.data?.user?.odysseyCourses?.length !== 0)
            ) && !loading
        );
    }, [verifyUserQuery.data?.user, to, loading]);

    return (
        <AuthRoleCheck role={Types.InternalMdgAdminRole.INTERNAL_MDG_SUPPORT}>
            <ConfirmationModal
                show={confirmModalState}
                setShow={setConfirmModalState}
                callback={() => transferOdysseyProgress({ variables: { from: userId, to } })}
                message={
                    <div className="flex flex-col">
                        <div className="pb-2">Are you sure you want to transfer Odyssey progress?</div>
                        <div>From: {userId}</div>
                        <div className="pb-2">To: {to}</div>
                        <div>This action will delete the from account's progress and cannot be undone.</div>
                    </div>
                }
            />
            <div className="my-2 pt-4 pb-2 rounded-lg w-128">
                <div className="text-md font-bold mb-2">Import Odyssey Progress</div>
                <div className="flex flex-row">
                    <div>
                        <input
                            type="text"
                            className="bg-input text-black dark:text-white disabled:text-grey-light disabled:dark:text-midnight rounded border border-1 border-primary w-96 h-8 px-2"
                            value={userId}
                            onChange={(e) => setUserId(e.target.value)}
                            placeholder="Transfer from..."
                        />
                        <div className="flex flex-row items-center mt-1">
                            {canTransfer && <CheckCircleIcon className="w-4 h-4 mr-1" />}
                            {verifiedUser}
                        </div>
                    </div>
                    <div>
                        <Button
                            isDisabled={!canTransfer || loading}
                            variant={'primary'}
                            size={'sm'}
                            onClick={() => setConfirmModalState(true)}
                        >
                            {loading ? 'Transferring...' : 'Transfer'}
                        </Button>
                    </div>
                </div>
            </div>
        </AuthRoleCheck>
    );
};
