import React, { createContext } from 'react';
import { BrowserRouter as Router, Navigate, Route, Routes, useLocation, useParams } from 'react-router-dom';
import Login from './pages/Login';
import Home from './pages/Home';
import Account from './pages/Account';
import DeletedAccount from './pages/DeletedAccount';
import Plan from './pages/Plan';
import User from './pages/User';
import Service from './pages/Service';
import { Subscriptions } from './pages/EnterpriseRenewals';
import Cron from './pages/Cron';
import { useIsLoggedIn } from './hooks/useIsLoggedIn';
import RawUsage from './pages/RawUsage';
import Sidebar from './Layout';
import { SAFRedirect } from './pages/SAFRedirect';

export const UserContext = createContext<{
    user:
        | {
              id?: string | undefined;
              email?: string | null | undefined;
          }
        | undefined
        | null;
}>({ user: null });

const Layout: React.FC = () => {
    const { isLoggedIn, loading, user } = useIsLoggedIn();

    const location = useLocation();
    const params = useParams();

    if (loading) return <div>Loading...</div>;
    if (!isLoggedIn) return <Navigate to="/login" />;

    return (
        <Sidebar path={location.pathname} params={params} fullName={user?.fullName}>
            <UserContext.Provider value={{ user }}>
                <Routes>
                    <Route path="/a/:accountId/*" element={<Account />} />
                    <Route path="/d/:internalAccountId/*" element={<DeletedAccount />} />
                    <Route path="/p/:planId" element={<Plan />} />
                    <Route path="/u/:userId" element={<User />} />
                    <Route path="/g/:serviceId" element={<Service />} />
                    <Route path="/saf/:graphId/:safId" element={<SAFRedirect />} />
                    <Route path="/renewals/*" element={<Subscriptions />} />
                    <Route path="/subscriptions/*" element={<Subscriptions />} />
                    <Route path="/cron" element={<Cron />} />
                    <Route path="/raw" element={<RawUsage />} />
                    <Route path="/" element={<Home />} />
                </Routes>
            </UserContext.Provider>
        </Sidebar>
    );
};

const App = () => {
    return (
        <Router>
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/*" element={<Layout />} />
            </Routes>
        </Router>
    );
};

export default App;
