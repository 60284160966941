import { useEffect } from 'react';
import { TabContentProps } from './TabNav';
import Users from './Users';

interface UserTabProps extends TabContentProps {}

export default function UserTab({ accountId, setActiveTab }: UserTabProps) {
    useEffect(() => {
        setActiveTab('users');
    });
    return (
        <>
            <div className="w-full">
                <Users accountId={accountId} />
            </div>
        </>
    );
}
