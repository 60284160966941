import React from 'react';
import { createRoot } from 'react-dom/client';
import { ApolloProvider, ApolloClient, InMemoryCache, split, HttpLink } from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import App from './App';
import Config from './config';
import LaunchDarklyProvider from './LaunchDarklyProvider';
import possibleTypes from './types/apollo/possibleTypes.json';
import { StylelessChakraProvider } from './theme/theme';
import '@apollo/space-kit/reset.css';
import './index.css';
import { hasDirectives } from '@apollo/client/utilities';

const config = {
    name: 'Admin App',
    uri: `${Config.backendURL}api/graphql?requireAuth=1`,
    credentials: 'include',
    headers: {
        // This app is driven by using fields like Query.allAccounts that only work in sudo mode.
        'apollo-sudo': 'true',
    },
};

const links = split(
    // If the query has @defer directives or if the backend is prod, use the HttpLink
    ({ query }) =>
        Config.backendURL !== 'https://graphql-staging.api.apollographql.com/' || hasDirectives(['defer'], query),
    new HttpLink(config),
    // Otherwise, use the BatchHttpLink to group requests together (poor man's feature flag)
    new BatchHttpLink({ ...config, batchInterval: 25 })
);

const client = new ApolloClient({
    name: 'Admin App',
    uri: `${Config.backendURL}api/graphql?requireAuth=1`,
    credentials: 'include',
    headers: {
        // This app is driven by using fields like Query.allAccounts that only work in sudo mode.
        'apollo-sudo': 'true',
    },
    cache: new InMemoryCache({
        possibleTypes: possibleTypes.possibleTypes,
    }),
    defaultOptions: {
        watchQuery: {
            nextFetchPolicy: 'cache-first',
        },
    },
    link: links,
});

const container = document.getElementById('root');
const root = createRoot(container!);

const themedClasses = [
    'theme-container-new-system-light',
    'theme-container-new-system-dark',
    'theme-container-light',
    'theme-container-dark',
];
const getThemeContainerClass = (themeName: string, useNewTheme: boolean) => {
    if (useNewTheme) {
        return themeName === 'light' ? 'theme-container-new-system-light' : 'theme-container-new-system-dark';
    }

    return themeName === 'light' ? 'theme-container-light' : 'theme-container-dark';
};

document.body.classList.remove(...themedClasses);
document.body.classList.add(
    ...[
        getThemeContainerClass(window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light', true),
        // this needs to be here and not just on <body> in index.html
        // so that our storybook stories are treated the same as our app itself
        'font-body',
        'text-base',
        'text-primary',
        'bg-secondary',
    ]
);

window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (e) => {
    document.body.classList.remove(...themedClasses);
    document.body.classList.add(getThemeContainerClass(e.matches ? 'dark' : 'light', true));
});

root.render(
    <ApolloProvider client={client}>
        <LaunchDarklyProvider>
            <StylelessChakraProvider>
                <App />
            </StylelessChakraProvider>
        </LaunchDarklyProvider>
    </ApolloProvider>
);
