import React from 'react';
import { useMutation, useQuery, gql } from '@apollo/client';
import { Link, useParams } from 'react-router-dom';
import moment from 'moment';
import FieldEdit from '../components/FieldEdit';
import FieldSelector from '../components/FieldSelector';
import Column from '../components/Column';
import pluralize from '../helpers/pluralize';
import * as Types from '../types';
import { ResendVerificationButton } from '../components/UserPage/UserVerificationButton';
import { DeleteUserButton } from '../components/UserPage/UserDeletionAlert';
import { TransferOdysseyProgress } from '../components/TransferOdysseyProgress';
import Page from '../components/Page';
import { ClipboardDocumentIcon } from '@heroicons/react/24/outline';
import { ReactComponent as IconOutlink } from '@apollo/icons/default/IconOutlink.svg';
import { Box, Button, Flex, Tooltip, useToast } from '@chakra-ui/react';
import { OdysseyCertificateBadges } from '../components/OdysseyCertificateBadges';

export const ODYSSEY_CERTIFICATE_FRAGMENT = gql`
    fragment OdysseyCertificateFragment on OdysseyCertification {
        id
        certificationId
        earnedAt
    }
`;
const USER_FRAGMENT = gql`
    fragment FieldSelect on User {
        id
        githubUsername
        avatarUrl
        fullName
        lastAuthenticatedAt
        email
        emailVerified
        internalAdminRole
        # Removing query for tickets until we migrate to JSM
        # tickets {
        #     createdAt
        #     description
        #     id
        #     graph {
        #         id
        #         title
        #     }
        #     organization {
        #         id
        #         name
        #     }
        #     priority
        #     subject
        # }
        memberships {
            account {
                id
                name
                avatarUrl
                services: graphs {
                    id
                    deletedAt
                }
            }
        }
        createdAt
        odysseyCourses {
            id
            enrolledAt
            completedAt
        }
        odysseyCertifications {
            ...OdysseyCertificateFragment
        }
    }
    ${ODYSSEY_CERTIFICATE_FRAGMENT}
`;

export const USER_QUERY = gql`
    query User__FullPageQuery($id: ID!) {
        user(id: $id) {
            ...FieldSelect
        }
        me {
            id
            ... on User {
                internalAdminRole
            }
        }
    }
    ${USER_FRAGMENT}
    ${ODYSSEY_CERTIFICATE_FRAGMENT}
`;

const USER_REFRESH_MUTATION = gql`
    mutation User__RefreshGithubMutation($id: ID!) {
        user(id: $id) {
            refresh {
                ...FieldSelect
            }
        }
    }
    ${USER_FRAGMENT}
`;

const USER_CHANGE_ADMIN_ROLE = gql`
    mutation User__ChangeAdminRoleMutation($id: ID!, $newRole: InternalMdgAdminRole) {
        user(id: $id) {
            updateRole(newRole: $newRole) {
                ...FieldSelect
            }
        }
    }
    ${USER_FRAGMENT}
`;

type UserProps = {
    children?: React.ReactNode;
    userId?: string;
};
const User = (props: UserProps) => {
    let { userId } = useParams();
    let toast = useToast();

    if (!userId && props.userId !== '') {
        userId = props.userId;
    }
    const { data, loading, error } = useQuery<Types.User__FullPageQuery>(USER_QUERY, {
        variables: { id: userId },
        skip: !userId,
    });

    const [refreshUser] = useMutation(USER_REFRESH_MUTATION, {
        variables: { id: userId },
    });

    const roleNameToEnum: Map<string, string> = new Map();
    const roleEnumToName: Map<string, string> = new Map();
    [
        ['None', 'delete'],
        ['Read Only', 'INTERNAL_MDG_READ_ONLY'],
        ['Sales', 'INTERNAL_MDG_SALES'],
        ['Support', 'INTERNAL_MDG_SUPPORT'],
        ['Super Admin', 'INTERNAL_MDG_SUPER_ADMIN'],
    ].forEach(([name, enumName]) => {
        roleNameToEnum.set(name, enumName);
        roleEnumToName.set(enumName, name);
    });

    if (loading) {
        return (
            <Flex justify="center" my={12}>
                <div className="spinner-grow text-primary center" role="status">
                    <span className="sr-only" />
                </div>
            </Flex>
        );
    }

    if (error) {
        return <>{error.message}</>;
    }

    if (!data || !data.user || !data.me) {
        return (
            <>
                User <p>{userId}</p> does not exist.
            </>
        );
    }

    return (
        <Page>
            <div className="flex flex-col">
                <div className="font-bold text-2xl pb-2 flex flex-row space-x-2 items-center">
                    <div>
                        {data.user.avatarUrl && (
                            <img src={data.user.avatarUrl} alt={data.user.id} className="h-6 w-6 rounded-full mr-3" />
                        )}
                        {data.user.fullName}
                    </div>
                    <OdysseyCertificateBadges certificates={data.user.odysseyCertifications} className="" />
                </div>
                <div className="flex flex-row">
                    <div className="flex flex-col pr-10">
                        <Box flex="1">
                            <Column num={4} style={{ paddingRight: '25px' }}>
                                <div className="space-y-3 pb-3">
                                    {data.user.githubUsername && (
                                        <div>
                                            <a
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href={`https://github.com/${data.user.githubUsername}`}
                                            >
                                                <p>Github account: </p>
                                                <span>{data.user.githubUsername}</span>
                                            </a>
                                        </div>
                                    )}
                                    <div>
                                        <p className="text-lg">Created:</p>{' '}
                                        {moment(data.user.createdAt).format('M/D/YY h:mma')}
                                    </div>
                                    <div>
                                        <p className="text-lg">Last seen:</p>{' '}
                                        {moment(data.user.lastAuthenticatedAt).format('M/D/YY h:mma')}
                                    </div>
                                </div>
                                <div className="space-y-3">
                                    <div>
                                        <p className="text-lg">User ID:</p>{' '}
                                        <div className="inline-flex items-center">
                                            {data.user.id}
                                            <ClipboardDocumentIcon
                                                className="ml-1 h-4 hover:cursor-pointer stroke-icon-primary hover:stroke-icon-secondary"
                                                onClick={() => {
                                                    navigator.clipboard.writeText(data?.user?.id ?? '');
                                                    toast({
                                                        description: 'User ID copied to clipboard',
                                                        status: 'info',
                                                        duration: 1_000,
                                                    });
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="">
                                        <p className="text-lg">
                                            <a
                                                href="https://docs.google.com/document/d/1PGJt5jkwHADPafKMOE8moSPRjiRET9ED/edit"
                                                style={{ display: 'inline' }}
                                                target="_blank"
                                                rel="noreferrer"
                                                className="inline-flex items-center hover:underline"
                                            >
                                                Sudo role
                                                <IconOutlink className="pl-1 h-4 w-4 stroke-icon-primary" />
                                            </a>
                                            :
                                        </p>{' '}
                                        {data.user.internalAdminRole === null
                                            ? 'None'
                                            : (roleEnumToName.get(data.user.internalAdminRole ?? '') ?? 'Unknown')}{' '}
                                    </div>
                                    {data.me.__typename === 'User' &&
                                    data.me.internalAdminRole === 'INTERNAL_MDG_SUPER_ADMIN' ? (
                                        <div>
                                            <div className="text-lg">Set user role:</div>
                                            <FieldSelector
                                                valueMap={roleNameToEnum}
                                                mutation={USER_CHANGE_ADMIN_ROLE}
                                                userid={data.user.id}
                                                userRole={data.user.internalAdminRole?.toString() ?? ''}
                                            />{' '}
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                    <div>
                                        <p className="text-lg">Email Verified:</p> {data.user.emailVerified.toString()}
                                    </div>
                                    <div>
                                        <FieldEdit
                                            overrideVariableWithInputValue="email"
                                            labelClassName="text-lg"
                                            label="Email"
                                            variables={{ id: data.user.id }}
                                            mutation={gql`
                                                mutation User__SetEmailMutation($id: ID!, $email: String!) {
                                                    user(id: $id) {
                                                        update(email: $email) {
                                                            id
                                                        }
                                                    }
                                                }
                                            `}
                                            // @ts-ignore
                                            refetch={gql`
                                                query User__EmailQuery($id: ID!) {
                                                    user(id: $id) {
                                                        id
                                                        email
                                                    }
                                                }
                                            `}
                                        >
                                            {data.user.email}
                                        </FieldEdit>
                                    </div>
                                    <Flex className="items-center">
                                        <div className="pr-2">
                                            <Tooltip label="Refresh information about the user from its upstream service (e.g. list of organizations from GitHub).">
                                                <Button
                                                    onClick={() => {
                                                        toast.promise(refreshUser(), {
                                                            success: () => {
                                                                return {
                                                                    title: 'User Refreshed',
                                                                    description: 'User information has been refreshed',
                                                                    duration: 3_000,
                                                                };
                                                            },
                                                            loading: {
                                                                description: 'Refreshing...',
                                                                duration: 3000,
                                                            },
                                                            error({ message }) {
                                                                return {
                                                                    title: 'Error refreshing user',
                                                                    description: message,
                                                                    status: 'error',
                                                                    duration: 3_000,
                                                                };
                                                            },
                                                        });
                                                    }}
                                                    variant={'primary'}
                                                    size={'sm'}
                                                >
                                                    Refresh User
                                                </Button>
                                            </Tooltip>
                                        </div>

                                        <ResendVerificationButton
                                            userId={data.user.id}
                                            emailVerified={data.user.emailVerified}
                                        />
                                        <DeleteUserButton userId={data.user.id} />
                                    </Flex>
                                </div>
                            </Column>
                        </Box>
                    </div>
                    <div>
                        <Box flex="sm">
                            <Column num={4}>
                                <div>
                                    <div>
                                        <p className="text-lg">
                                            {pluralize('organization membership', data.user.memberships.length)}
                                        </p>
                                    </div>
                                    <div>
                                        {data.user.memberships.map(({ account: { id, name, avatarUrl, services } }) => (
                                            <div key={id}>
                                                <div>
                                                    <Link to={`/a/${id}`} className="hover:underline" target="_blank">
                                                        {avatarUrl ? (
                                                            <img
                                                                className="img small"
                                                                src={avatarUrl}
                                                                alt="User avatar"
                                                            />
                                                        ) : (
                                                            <div
                                                                className="img small"
                                                                style={{ backgroundColor: '#eee' }}
                                                            />
                                                        )}{' '}
                                                        <span>{name}</span>{' '}
                                                        <p className="italic">
                                                            {pluralize(
                                                                'service',
                                                                services.filter((s) => !s.deletedAt).length
                                                            )}
                                                        </p>
                                                    </Link>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </Column>
                            {/* <div className="py-2">
                                <div className="text-lg">Support</div>
                                <div className="mt-2 pb-3">
                                    <p>{pluralize('Support Ticket', (data.user.tickets || []).length)}</p>
                                </div>
                                {data.user?.tickets && data.user.tickets.length > 0 && (
                                    <table>
                                        <thead>
                                            <tr>
                                                <th style={{ whiteSpace: 'nowrap' }}>Priority</th>
                                                <th style={{ whiteSpace: 'nowrap' }}>Submitted</th>
                                                <th>Target</th>
                                                <th>Subject</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(data.user.tickets || []).map(
                                                ({
                                                    id,
                                                    subject,
                                                    createdAt,
                                                    description,
                                                    graph,
                                                    organization,
                                                    priority,
                                                }) => (
                                                    <tr key={id}>
                                                        <td>{priority}</td>
                                                        <td>{moment(createdAt).format('M/D/YY')}</td>
                                                        <td>
                                                            {organization ? (
                                                                <>
                                                                    <Link
                                                                        to={`/a/${organization?.id}`}
                                                                        className="hover:underline inline"
                                                                        target="_blank"
                                                                    >
                                                                        {organization.name}
                                                                    </Link>
                                                                    {graph && (
                                                                        <>
                                                                            {' / '}
                                                                            <Link
                                                                                to={`/g/${graph?.id}`}
                                                                                style={{ display: 'inline' }}
                                                                            >
                                                                                {graph.title}
                                                                            </Link>
                                                                        </>
                                                                    )}
                                                                </>
                                                            ) : (
                                                                'User Issue'
                                                            )}
                                                        </td>
                                                        <td>
                                                            <a
                                                                href={`https://apollograph.zendesk.com/agent/tickets/${id}`}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                title={description}
                                                            >
                                                                <p>{subject}</p>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                )
                                            )}
                                        </tbody>
                                    </table>
                                )}
                            </div> */}
                            <div className="py-2">
                                <div className="text-lg">Odyssey</div>
                                <div className="flex flex-col">
                                    <div>
                                        <div>
                                            {pluralize(
                                                'course',
                                                data?.user?.odysseyCourses ? data.user.odysseyCourses.length : 0
                                            )}{' '}
                                            taken:
                                        </div>
                                        <div>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Course ID</th>
                                                        <th>Enrolled At</th>
                                                        <th>Completed At</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {data?.user?.odysseyCourses?.map((course) => (
                                                        <tr key={course.id}>
                                                            <td>{course.id}</td>
                                                            <td>{moment(course.enrolledAt).format('M/D/YY h:mma')}</td>
                                                            <td>
                                                                {course.completedAt
                                                                    ? moment(course.completedAt).format('M/D/YY h:mma')
                                                                    : 'In Progress'}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <TransferOdysseyProgress to={data.user.id} />
                                </div>
                            </div>
                        </Box>
                    </div>
                </div>
            </div>
        </Page>
    );
};

export default User;
