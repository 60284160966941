import { gql, useLazyQuery, useMutation } from '@apollo/client';
import {
    useDisclosure,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    Input,
    FormControl,
    useToast,
    Spinner,
    FormLabel,
    Stepper,
    Step,
    StepIndicator,
    StepStatus,
    StepNumber,
    StepIcon,
    useSteps,
    Box,
    StepTitle,
    Textarea,
    Accordion,
    AccordionItem,
    AccordionPanel,
    AccordionButton,
    AccordionIcon,
    Divider,
    Flex,
    Tooltip,
} from '@chakra-ui/react';
import { useState } from 'react';
import type {
    SSO_Wizard__ParseMetadata,
    SSO_Wizard__SamIdpMetadataFragment,
    SSO_Wizard__SubmitConfig,
    SSO_Wizard__SubmitConfigVariables,
} from '../../../../types';
import { PencilIcon } from '@heroicons/react/24/outline';
import validator from 'validator';
import FileUpload from '../../../FileUpload/FileUpload';

const SSO_WIZARD_PARSE_METADATA_METADATA_FRAGMENT = gql`
    fragment SSO_Wizard__SamIdpMetadataFragment on ParsedSamlIdpMetadata {
        entityId
        ssoUrl
        verificationCerts {
            pem
            subjectDN
            notAfter
            notBefore
        }
    }
`;
const SSO_WIZARD_PARSE_METADATA_QUERY = gql`
    query SSO_Wizard__ParseMetadata($metadata: String!) {
        sso {
            parseSamlIdpMetadata(metadata: $metadata) {
                ...SSO_Wizard__SamIdpMetadataFragment
            }
        }
    }
    ${SSO_WIZARD_PARSE_METADATA_METADATA_FRAGMENT}
`;

const SSO_WIZARD_SUBMIT_CONFIG_QUERY = gql`
    mutation SSO_Wizard__SubmitConfig($id: ID!, $connectionId: ID!, $metadata: SamlConfigurationInput!) {
        account(id: $id) {
            addSamlMetadataToBaseConnection(connectionId: $connectionId, metadata: $metadata) {
                id
            }
        }
    }
`;

const STEPS = [
    {
        step: 0,
        title: 'Upload',
        header: 'Upload metadata',
        description: 'Upload your SAML metadata file to get started.',
    },
    {
        step: 1,
        title: 'Verify',
        header: 'Verify metadata',
        description: 'Verify the metadata details and submit to complete the setup.',
    },
];

type SSOWizardProps = {
    connectionId: string;
    idpId?: string;
    accountId: string;
};

export const UpdateBaseConfigSAMLWizard = ({ connectionId, idpId: argIdpId, accountId }: SSOWizardProps) => {
    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { activeStep, goToNext, goToPrevious, setActiveStep } = useSteps({
        index: 0,
        count: STEPS.length - 1,
    });
    const [metadata, setMetadata] = useState<SSO_Wizard__ParseMetadata['sso']>();
    const [metadataURL, setMetadataURL] = useState<string>('');
    const [showDownloadAnchor, setShowDownloadAnchor] = useState<boolean>(false);

    const [parse, { data: parseData, loading: parseLoading }] = useLazyQuery<SSO_Wizard__ParseMetadata>(
        SSO_WIZARD_PARSE_METADATA_QUERY
    );

    const [submitConfig] = useMutation<SSO_Wizard__SubmitConfig, SSO_Wizard__SubmitConfigVariables>(
        SSO_WIZARD_SUBMIT_CONFIG_QUERY
    );

    if (!('TextDecoder' in window)) {
        return (
            <>
                Sorry, your browser does not appear to support necessary features to make the wizard work. Please use
                Google Chrome or Firefox to proceed.
            </>
        );
    }

    return (
        <div>
            <Button
                onClick={onOpen}
                disabled={accountId === 'apollo'}
                className="disabled:cursor-not-allowed disabled:opacity-50 text-white bg-blue px-3 py-1 font-semibold rounded hover:bg-blue-darker items-center flex flex-row"
                size="sm"
                variant="primary"
            >
                <PencilIcon className="w-4 h-4 mr-1" />
                <div className="pl-1">Update with SAML Metadata</div>
            </Button>

            <Modal isOpen={isOpen} onClose={onClose} size="lg">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{STEPS[activeStep].header}</ModalHeader>
                    <ModalBody>
                        <Stepper index={activeStep} size="sm" className="mb-4">
                            {STEPS.map((step) => (
                                <Step key={step.step}>
                                    <StepIndicator>
                                        <StepStatus
                                            complete={<StepIcon />}
                                            incomplete={<StepNumber />}
                                            active={<StepNumber />}
                                        />
                                    </StepIndicator>
                                    <Box>
                                        <StepTitle>{step.title}</StepTitle>
                                    </Box>
                                </Step>
                            ))}
                        </Stepper>
                        {activeStep === 0 && (
                            <>
                                {parseLoading && <Spinner />}
                                {(parseData || parseData === null || !parseLoading) && (
                                    <>
                                        <div>
                                            <div className="font-semibold text-lg pb-2">Upload metadata from URL</div>
                                            <div className="flex flex-row items-center pb-2">
                                                <Input
                                                    type="text"
                                                    placeholder="Paste metadata address here"
                                                    className="mr-2"
                                                    value={metadataURL}
                                                    onChange={(e) => {
                                                        setMetadataURL(e.target.value);
                                                    }}
                                                />
                                                <Button
                                                    variant="primary"
                                                    isDisabled={metadataURL === ''}
                                                    onClick={async (e) => {
                                                        e.preventDefault();
                                                        if (!validator.isURL(metadataURL)) {
                                                            toast({
                                                                title: 'Invalid URL',
                                                                description: 'Please enter a valid URL',
                                                                status: 'error',
                                                            });
                                                            return;
                                                        }
                                                        try {
                                                            let res = await fetch(metadataURL);
                                                            let blob = await res.blob();
                                                            let string = new TextDecoder().decode(
                                                                await blob.arrayBuffer()
                                                            );
                                                            let { data } = await parse({
                                                                variables: {
                                                                    metadata: string,
                                                                },
                                                            });
                                                            if (data && data.sso?.parseSamlIdpMetadata) {
                                                                setMetadata(data.sso);
                                                                goToNext();
                                                            } else {
                                                                toast({
                                                                    title: 'Invalid metadata',
                                                                    description: 'Please upload valid metadata',
                                                                    status: 'error',
                                                                });
                                                            }
                                                        } catch (error) {
                                                            setShowDownloadAnchor(true);
                                                            toast({
                                                                title: 'Error fetching metadata',
                                                                description: `An error occurred while fetching metadata from provided URL.\n\nPlease download the file manually and upload in the wizard.`,
                                                                status: 'error',
                                                            });
                                                        }
                                                    }}
                                                >
                                                    Submit
                                                </Button>
                                            </div>
                                            {showDownloadAnchor && (
                                                <a
                                                    href={metadataURL}
                                                    download="metadata.xml"
                                                    className="hover:cursor-pointer underline"
                                                    target="_blank"
                                                    rel="noreferrer noopener"
                                                >
                                                    <Button
                                                        variant="primary"
                                                        className="mb-2 whitespace-normal break-words"
                                                    >
                                                        <Tooltip
                                                            label=" If this takes you to a new tab, right-click and save as on
                                                            the button."
                                                            aria-label=" If this takes you to a new tab, right-click and save as on
                                                            the button."
                                                        >
                                                            Download metadata
                                                        </Tooltip>
                                                    </Button>
                                                </a>
                                            )}
                                        </div>
                                        <Flex align="center">
                                            <Divider />
                                            <div className="p-2">or</div>
                                            <Divider />
                                        </Flex>
                                        <div className="font-semibold text-lg pb-2">Upload metadata from file</div>
                                        <FileUpload
                                            onFileAccepted={async (file) => {
                                                if (!file.name.endsWith('.xml')) {
                                                    toast({
                                                        title: 'Invalid file type',
                                                        description: 'Please upload a valid XML file',
                                                        status: 'error',
                                                    });
                                                    return;
                                                }
                                                let string = new TextDecoder().decode(await file.arrayBuffer());

                                                let { data } = await parse({
                                                    variables: {
                                                        metadata: string,
                                                    },
                                                });
                                                if (data && data.sso?.parseSamlIdpMetadata) {
                                                    setMetadata(data.sso);
                                                    goToNext();
                                                } else {
                                                    toast({
                                                        title: 'Invalid metadata',
                                                        description: 'Please upload valid metadata',
                                                        status: 'error',
                                                    });
                                                }
                                            }}
                                        />
                                    </>
                                )}
                                <div className="flex">
                                    <Button variant="secondary" size="sm" onClick={onClose} className="ml-auto mt-4">
                                        Close
                                    </Button>
                                </div>
                            </>
                        )}
                        {activeStep === 1 && (
                            <>
                                <FormControl>
                                    {Object.keys(metadata?.parseSamlIdpMetadata ?? {}).map((key) => {
                                        if (key === '__typename') {
                                            return <></>;
                                        }
                                        let value =
                                            metadata?.parseSamlIdpMetadata?.[
                                                key as keyof SSO_Wizard__SamIdpMetadataFragment
                                            ];

                                        if (value instanceof Array) {
                                            return (
                                                <>
                                                    <FormLabel className="font-bold">{key}</FormLabel>
                                                    <Textarea
                                                        key={key}
                                                        mb={4}
                                                        defaultValue={value
                                                            .map((v) => {
                                                                return `Subject DN: ${v.subjectDN}\n\nNot Before: ${v.notBefore}\nNot After: ${v.notAfter}\n`;
                                                            })
                                                            .join('\n')}
                                                        isReadOnly
                                                        className="hover:cursor-not-allowed"
                                                    />
                                                    <Accordion allowMultiple>
                                                        {value.map((v) => {
                                                            return (
                                                                <>
                                                                    <AccordionItem>
                                                                        <AccordionButton>
                                                                            <>PEM</>
                                                                            <AccordionIcon />
                                                                        </AccordionButton>
                                                                        <AccordionPanel>
                                                                            <Textarea
                                                                                key={v.subjectDN}
                                                                                defaultValue={v.pem}
                                                                                isReadOnly
                                                                                className="hover:cursor-not-allowed"
                                                                            />
                                                                        </AccordionPanel>
                                                                    </AccordionItem>
                                                                </>
                                                            );
                                                        })}
                                                    </Accordion>
                                                </>
                                            );
                                        }
                                        return (
                                            <>
                                                <FormLabel className="font-bold">{key}</FormLabel>
                                                <Input
                                                    key={key}
                                                    defaultValue={
                                                        // this is bad, but we know the keys are strings and the values are strings
                                                        metadata?.parseSamlIdpMetadata?.[
                                                            key as keyof SSO_Wizard__SamIdpMetadataFragment
                                                        ] as unknown as string
                                                    }
                                                    isReadOnly
                                                    className="hover:cursor-not-allowed"
                                                />
                                            </>
                                        );
                                    })}
                                    <div className="flex flex-row space-x-2 ml-auto items-center">
                                        <Button
                                            mt={4}
                                            variant="primary"
                                            size="sm"
                                            type="submit"
                                            className="ml-auto"
                                            onClick={async (e) => {
                                                // make extra sure we don't submit invalid metadata
                                                let parsed = metadata?.parseSamlIdpMetadata;
                                                if (!parsed) {
                                                    toast({
                                                        title: 'Invalid metadata',
                                                        description: 'Please upload valid metadata',
                                                        status: 'error',
                                                    });
                                                    setActiveStep(0);
                                                    return;
                                                }
                                                let { data, errors } = await submitConfig({
                                                    variables: {
                                                        id: accountId,
                                                        metadata: {
                                                            entityId: parsed.entityId,
                                                            ssoUrl: parsed.ssoUrl,
                                                            verificationCerts: parsed.verificationCerts.map(
                                                                (v) => v.pem
                                                            ),
                                                            wantsSignedRequests: null,
                                                        },
                                                        connectionId: connectionId,
                                                    },
                                                    errorPolicy: 'all',
                                                    refetchQueries: ['Metadata_Account__MetadataQuery'],
                                                });

                                                if (errors) {
                                                    toast({
                                                        title: 'Error submitting config',
                                                        description: errors.map((e) => e.message).join('\n'),
                                                        status: 'error',
                                                    });
                                                }
                                                if (data?.account?.addSamlMetadataToBaseConnection?.id) {
                                                    toast({
                                                        title: 'Success',
                                                        description: 'SSO configuration updated successfully',
                                                        status: 'success',
                                                    });
                                                    onClose();
                                                }
                                            }}
                                        >
                                            Submit
                                        </Button>
                                        <Button mt={4} variant="secondary" size="sm" onClick={() => goToPrevious()}>
                                            Back
                                        </Button>
                                        <Button variant="secondary" size="sm" onClick={onClose} className="mt-4">
                                            Close
                                        </Button>
                                    </div>
                                </FormControl>
                            </>
                        )}
                    </ModalBody>
                </ModalContent>
            </Modal>
        </div>
    );
};
