import { TypedDocumentNode, gql, useQuery } from '@apollo/client';
import {
    AccountSubscriptionInfo,
    BillingPlanKind,
    SubscriptionsPage__AllEnterpriseTab,
    SubscriptionsPage__AllEnterpriseTabVariables,
} from '../../types';
import { SubscriptionResult, SubscriptionResultBox } from './SubscriptionResultBox';
import { Tags } from './SubscriptionTags';
import pluralize from '../../helpers/pluralize';

const ENTERPRISE_RENEWALS_QUERY: TypedDocumentNode<SubscriptionsPage__AllEnterpriseTab> = gql`
    query SubscriptionsPage__AllEnterpriseTab {
        allAccounts(tier: ENTERPRISE) {
            ...AccountSubscriptionInfo
        }
    }

    fragment AccountSubscriptionInfo on Account {
        id
        name
        currentPlan {
            id
            kind
            name
        }
        currentSubscription {
            autoRenew
            canceledAt
            expiresAt
            currentPeriodStartedAt
            currentPeriodEndsAt
        }
        isLocked
    }
`;

const AllEnterpriseTab = () => {
    const { data, loading, error } = useQuery<
        SubscriptionsPage__AllEnterpriseTab,
        SubscriptionsPage__AllEnterpriseTabVariables
    >(ENTERPRISE_RENEWALS_QUERY);

    if (loading) return <p className="italic">Loading...</p>;
    if (error) return <div className="error">{error.message}</div>;

    const allAccounts = data?.allAccounts
        ?.filter((a: AccountSubscriptionInfo) => a.currentPlan.kind !== BillingPlanKind.ENTERPRISE_INTERNAL)
        .sort((a, b) => (a.name.trim() > b.name.trim() ? 1 : -1))
        .map((a): SubscriptionResult => {
            return {
                id: `${a.name} [${a.id}]`,
                linkUrl: `/a/${a.id}`,
                description: (
                    <>
                        Expires on{' '}
                        {new Intl.DateTimeFormat().format(new Date(a.currentSubscription?.currentPeriodEndsAt ?? 0))}
                    </>
                ),
                tags: <Tags account={a} />,
            };
        });

    return (
        <>
            <p className="px-6 font-bold text-xl">{pluralize('account', allAccounts?.length ?? 0)}</p>
            <SubscriptionResultBox results={allAccounts} />
        </>
    );
};

export { AllEnterpriseTab };
