import React, { useContext, useEffect } from 'react';
import Page from '../components/Page';
import { useQuery, useMutation, gql } from '@apollo/client';
import * as Types from '../types';
import Column from '../components/Column';
import moment from 'moment';
import 'moment-duration-format';
import { HeaderContext } from '../helpers/HeaderContext';

const CRON_JOBS_QUERY = gql`
    query CronJob__FullPageQuery {
        internalUnresolvedCronExecutionFailures {
            id
            job {
                group
                name
            }
            startedAt
            completedAt
            failure
            resolvedAt
        }
        internalActiveCronJobs {
            group
            name
            recentExecutions(n: 3) {
                id
                startedAt
                completedAt
                failure
                resolvedAt
                resolvedBy {
                    actorId
                }
            }
        }
    }
`;

const CRON_RESOLVE_FAILURE_MUTATION = gql`
    mutation CronJob__ResolveFailure($id: ID!) {
        resolveInternalCronExecution(id: $id) {
            id
            resolvedAt
            resolvedBy {
                actorId
            }
        }
    }
`;

const CRON_RESOLVE_ALL_FAILURES_MUTATION = gql`
    mutation CronJob__ResolveAllFailures {
        resolveAllInternalCronExecutions
    }
`;

const CRON_RESOLVE_JOB_FAILURES_MUTATION = gql`
    mutation CronJob__ResolveJobFailures($group: String!, $name: String!) {
        resolveAllInternalCronExecutions(group: $group, name: $name)
    }
`;

function timestamp(s: string | number): string {
    return moment(s).format('MMM D HH:mm:ss');
}

function Cron() {
    const { setContent } = useContext(HeaderContext);
    useEffect(() => {
        setContent(<></>);
    }, [setContent]);

    const { data, loading, error } = useQuery<Types.CronJob__FullPageQuery>(CRON_JOBS_QUERY, { pollInterval: 5000 });
    const [resolveFailure] = useMutation(CRON_RESOLVE_FAILURE_MUTATION);
    const [resolveAllFailures] = useMutation(CRON_RESOLVE_ALL_FAILURES_MUTATION, {
        refetchQueries: [{ query: CRON_JOBS_QUERY }],
    });
    const [resolveJobFailures] = useMutation(CRON_RESOLVE_JOB_FAILURES_MUTATION, {
        refetchQueries: [{ query: CRON_JOBS_QUERY }],
    });

    if (loading) return <div>Loading...</div>;
    if (error) return <div>{error.message}</div>;
    if (!data) return <div>Unexpected lack of data.</div>;

    const hasFailures = data.internalUnresolvedCronExecutionFailures.length > 0;
    return (
        <Page>
            <Column num={hasFailures ? 2 : 1}>
                <h2>Active jobs</h2>
                {data.internalActiveCronJobs.map((job) => {
                    const unresolvedFailureCount = data.internalUnresolvedCronExecutionFailures.filter(
                        (e) => !e.resolvedAt && e.job.group === job.group && e.job.name === job.name
                    ).length;
                    return (
                        <React.Fragment key={`${job.group}.${job.name}`}>
                            <h5>
                                {job.group}.{job.name}{' '}
                                {unresolvedFailureCount > 0 ? (
                                    <button
                                        onClick={() =>
                                            resolveJobFailures({
                                                variables: { group: job.group, name: job.name },
                                            })
                                        }
                                        className="ml-2 mt-4 h-7 px-3 font-semibold rounded border border-slate-200 bg-gray-100 text-slate-900 dark:bg-gray-800 dark:text-gray-100"
                                    >
                                        Resolve {unresolvedFailureCount}
                                    </button>
                                ) : null}
                            </h5>
                            <ul>
                                {job.recentExecutions.map((execution) => (
                                    <li key={execution.id}>
                                        {execution.completedAt
                                            ? (execution.failure
                                                  ? execution.resolvedAt
                                                      ? `FAILED (resolved by ${
                                                            execution.resolvedBy!.actorId
                                                        }) at ${timestamp(execution.completedAt)}`
                                                      : `FAILED (unresolved) at ${timestamp(execution.completedAt)}`
                                                  : `Succeeded at ${timestamp(execution.completedAt)}`) +
                                              ' (in ' +
                                              moment
                                                  .duration(moment(execution.completedAt).diff(execution.startedAt))
                                                  .format() +
                                              ')'
                                            : 'Running'}
                                        , started at {timestamp(execution.startedAt)}
                                    </li>
                                ))}
                            </ul>
                        </React.Fragment>
                    );
                })}
            </Column>
            {hasFailures ? (
                <Column num={2}>
                    <h2>
                        Unresolved failures{' '}
                        <button
                            onClick={() => resolveAllFailures()}
                            className="mt-4 h-7 px-3 font-semibold rounded border border-slate-200 bg-gray-100 text-slate-900 dark:bg-gray-800 dark:text-gray-100"
                        >
                            Resolve all
                        </button>
                    </h2>
                    <ul>
                        {data.internalUnresolvedCronExecutionFailures
                            // Filter out resolved ones, so that when we click "resolve" and update their
                            // entry in the cache we don't have to manually tweak the internalUnresolvedCronExecutionFailures
                            // return value
                            .filter((failed) => !failed.resolvedAt)
                            .map((execution) => (
                                <li key={execution.id}>
                                    {execution.job.group}.{execution.job.name}: started at{' '}
                                    {timestamp(execution.startedAt.toString())}
                                    {execution.completedAt &&
                                        `, failed at ${timestamp(execution.completedAt.toString())}`}
                                    <button
                                        onClick={() => resolveFailure({ variables: { id: execution.id } })}
                                        className="ml-2 mt-4 h-7 px-3 font-semibold rounded border border-slate-200 bg-gray-100 text-slate-900 dark:bg-gray-800 dark:text-gray-100"
                                    >
                                        Resolve
                                    </button>
                                    <pre>{execution.failure}</pre>
                                </li>
                            ))}
                    </ul>
                </Column>
            ) : null}
        </Page>
    );
}

export default Cron;
