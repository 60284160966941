import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { Fragment, useEffect, useState } from 'react';
import { classNames } from '../helpers/classnames';
import { BillingPlanTier } from '../types';
import { Input, InputGroup, InputLeftElement } from '@chakra-ui/react';
import { useSearchParams } from 'react-router-dom';
import * as _ from 'lodash';

export enum SearchType {
    ENTERPRISE,
    TEAMS,
    SERVERLESS,
    ALL,
    PLANS,
    USERS,
    GRAPHS,
    BILLING_HISTORY,
}

export type SearchOptions = {
    label: string;
    description: string;
    type: SearchType;
    accountFilter: BillingPlanTier | null;
    allowEmptySearch: boolean;
};

const searchOptions: Array<SearchOptions> = [
    {
        label: 'Enterprise accounts',
        description: 'Search against all enterprise accounts, including trial and paid plans.',
        type: SearchType.ENTERPRISE,
        accountFilter: BillingPlanTier.ENTERPRISE,
        allowEmptySearch: true,
    },
    {
        label: 'Teams accounts',
        description: 'Search against all legacy Teams accounts, including trial and paid plans.',
        type: SearchType.TEAMS,
        accountFilter: BillingPlanTier.TEAM,
        allowEmptySearch: true,
    },
    {
        label: 'Serverless accounts',
        description: 'Search against all serverless accounts.',
        type: SearchType.SERVERLESS,
        accountFilter: BillingPlanTier.USAGE_BASED,
        allowEmptySearch: false,
    },
    {
        label: 'All accounts',
        description:
            'Search against all accounts on GraphOS. Note that this search can take a long time to resolve, so please be patient.',
        type: SearchType.ALL,
        accountFilter: null,
        allowEmptySearch: false,
    },
    {
        label: 'Users',
        description: 'Search against all user accounts on GraphOS.',
        type: SearchType.USERS,
        accountFilter: null,
        allowEmptySearch: false,
    },
    {
        label: 'Graphs/Services',
        description: 'Search against all graphs on GraphOS.',
        type: SearchType.GRAPHS,
        accountFilter: null,
        allowEmptySearch: false,
    },
    {
        label: 'Billing Plans',
        description: 'Search all billing plans for a specific value. NOTE: All search input is ignored.',
        type: SearchType.PLANS,
        accountFilter: null,
        allowEmptySearch: true,
    },
    {
        label: 'Deleted Account Billing History',
        description:
            'Search for accounts with a billing history. This will include deleted accounts. NOTE: The search must match the internal account ID exactly.',
        type: SearchType.BILLING_HISTORY,
        accountFilter: null,
        allowEmptySearch: false,
    },
];

type SearchBoxProps = {
    onSearchUpdate: (term: string, options: SearchOptions) => void;
};

export const SearchBox = ({ onSearchUpdate }: SearchBoxProps) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [selected, setSelected] = useState(searchOptions[0]);
    const [searchText, setSearchText] = useState('');
    useEffect(() => {
        if (searchParams.get('t')) {
            let tier = searchParams.get('t');
            if (tier && SearchType[tier as keyof typeof SearchType] != null) {
                let t = SearchType[tier as keyof typeof SearchType];
                setSelected(
                    _.find(searchOptions, (v: SearchOptions) => {
                        return v.type === (SearchType[t] as unknown as SearchType);
                    }) ?? searchOptions[0]
                );
            }
        }
        if (searchParams.get('q')) {
            let query = searchParams.get('q');
            setSearchText(query ?? '');
        }
    }, [setSelected, setSearchText, searchParams]);

    useEffect(() => {
        // check for length of the text, or if the filter is not null (in other words, there is a filter on the account AND that filter is not USAGE_BASED (SERVERLESS))
        // We check for USAGE_BASED as it's also for the new Free accounts, resulting in long-running queries- no current way to filter for paid only.
        if (searchText.length > 3 || selected.allowEmptySearch) {
            onSearchUpdate(searchText, selected);
        }
    }, [searchText, onSearchUpdate, selected]);

    return (
        <div className="flex flex-col px-0">
            <div className="flex items-center p-6">
                <div className="flex p-3 pl-0 w-72 space-x-3">
                    <InputGroup>
                        <InputLeftElement
                            pointerEvents={'none'}
                            children={<MagnifyingGlassIcon className="h-6 w-6 opacity-30" />}
                        />
                        <Input
                            placeholder="Search..."
                            value={searchText}
                            className="bg-secondary text-primary disabled:text-disabled rounded border-1 border-primary focus:border-focused placeholder:text-placeholder"
                            onChange={(e) => {
                                setSearchText(e.target.value);
                                setSearchParams(
                                    (p) => {
                                        return { ...Object.fromEntries(p), q: e.target.value };
                                    },
                                    { replace: true }
                                );
                            }}
                        />
                    </InputGroup>
                </div>
                <Listbox
                    value={selected}
                    onChange={(v) => {
                        setSelected(v);
                        let params = searchParams;
                        params.set('t', v.type.toString());
                        setSearchParams((p) => {
                            return { ...Object.fromEntries(p), t: v.type.toString() };
                        });
                    }}
                >
                    {({ open }) => (
                        <>
                            <Listbox.Label className="sr-only"> Change search filter </Listbox.Label>
                            <div className="relative">
                                <div className="inline-flex divide-x divide-brand-tertiary rounded shadow h-10">
                                    <div className="inline-flex divide-x divide-brand-primary shadow-sm">
                                        <div className="inline-flex items-center rounded-l bg-btn-primary text-white font-semibold py-3 pl-3 pr-4 shadow-sm ">
                                            <p className="ml-2.5 font-medium">{selected.label}</p>
                                        </div>
                                        <Listbox.Button className="inline-flex items-center rounded-l-none rounded-r bg-btn-primary p-2 text-sm font-medium hover:bg-btn-primary-hover focus:outline-none focus:ring-2 focus:ring-focused focus:ring-offset-2 rounded">
                                            <span className="sr-only">Change search filter</span>
                                            <ChevronDownIcon className="h-5 w-5 text-white" aria-hidden="true" />
                                        </Listbox.Button>
                                    </div>
                                </div>

                                <Transition
                                    show={open}
                                    as={Fragment}
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <Listbox.Options className="absolute right-0 z-10 mt-2 w-72 origin-top-right divide-y divide-brand-secondary overflow-hidden rounded bg-primary shadow ring-1 ring-info ring-opacity-5 focus:outline-none">
                                        {searchOptions.map((option) => (
                                            <Listbox.Option
                                                key={option.label}
                                                className={({ active }) =>
                                                    classNames(
                                                        active ? 'bg-secondary' : 'text-secondary',
                                                        'cursor-default select-none p-4 text-sm rounded'
                                                    )
                                                }
                                                value={option}
                                            >
                                                {({ selected, active }) => (
                                                    <div className="flex flex-col">
                                                        <div className="flex justify-between">
                                                            <p
                                                                className={
                                                                    selected
                                                                        ? 'font-semibold'
                                                                        : active
                                                                          ? 'font-semibold'
                                                                          : 'font-normal'
                                                                }
                                                            >
                                                                {option.label}
                                                            </p>
                                                            {selected ? (
                                                                <span
                                                                    className={
                                                                        active ? 'text-secondary' : 'text-primary'
                                                                    }
                                                                >
                                                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                                </span>
                                                            ) : null}
                                                        </div>
                                                        <p
                                                            className={'mt-2 break-keep text-brand-secondary'}
                                                            style={{ hyphens: 'unset' }}
                                                        >
                                                            {option.description}
                                                        </p>
                                                    </div>
                                                )}
                                            </Listbox.Option>
                                        ))}
                                    </Listbox.Options>
                                </Transition>
                            </div>
                        </>
                    )}
                </Listbox>
            </div>
            {/* End Search bar*/}
        </div>
    );
};
