import React from 'react';
import Page from '../components/Page';
import Config from '../config';
import { useIsLoggedIn } from '../hooks/useIsLoggedIn';
import { Navigate } from 'react-router-dom';
import { ReactComponent as IconLock } from '@apollo/icons/default/IconLock.svg';
import { Button } from '@chakra-ui/react';

const Login: React.FC = () => {
    const { loading, isLoggedIn, user } = useIsLoggedIn();

    if (loading) return <div>Loading...</div>;
    if (isLoggedIn) return <Navigate to="/" />;

    return (
        <div className="bg-primary flex grow flex-col items-center content-center">
            <Page back={false} logout={false}>
                <div className="flex flex-col items-center mt-6">
                    {user && (
                        <div className="font-semibold bg-error rounded border border-error p-2 mb-3 text-center">
                            Error: You do not have permissions to access this application
                        </div>
                    )}
                    <div className="flex flex-row space-x-4 mb-6">
                        <>
                            <a
                                className="login"
                                href={`${Config.frontendURL}login?type=sso&from=${window.location.href}`}
                            >
                                <Button variant={'primary'} size={'sm'}>
                                    <IconLock className="h-4 w-4 fill-black" />
                                    Sign in with SSO
                                </Button>
                            </a>
                        </>
                    </div>
                    <div>
                        <>
                            Don't see what you're looking for? You can also log in on{' '}
                            <a style={{ display: 'inline' }} href={Config.frontendURL}>
                                Studio website
                            </a>{' '}
                            and then come back here.
                        </>
                    </div>
                </div>
            </Page>
        </div>
    );
};

export default Login;
