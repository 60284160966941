import moment from 'moment';
import { AccountSubscriptionInfo } from '../../types';
import { LockClosedIcon } from '@heroicons/react/24/outline';
import { Badge } from '@chakra-ui/react';

const Tags = ({
    account,
    includeAutoRenew = true,
    isLocked = false,
    includeBilling = true,
}: {
    account: AccountSubscriptionInfo;
    includeAutoRenew?: boolean;
    isLocked?: boolean;
    includeBilling?: boolean;
}) => {
    let tags: Array<JSX.Element> = [];
    if (isLocked) {
        tags.push(
            <Badge key={`${account.id}-14-exp`} variant="error">
                <LockClosedIcon className="h-3 w-3" />
                LOCKED
            </Badge>
        );
    }
    if (includeBilling) {
        if (moment(account.currentSubscription?.currentPeriodEndsAt).add(14, 'days') < moment()) {
            tags.push(
                <Badge key={`${account.id}-14-exp`} variant="error">
                    EXPIRED 14 DAYS
                </Badge>
            );
        } else if (moment(account.currentSubscription?.currentPeriodEndsAt).add(7, 'days') < moment()) {
            tags.push(
                <Badge key={`${account.id}-14-exp`} variant="beta">
                    EXPIRED 7 DAYS
                </Badge>
            );
        } else if (
            moment(account.currentSubscription?.currentPeriodEndsAt) < moment() ||
            !account.currentSubscription
        ) {
            tags.push(
                <Badge key={`${account.id}-14-exp`} variant="warn">
                    EXPIRED
                </Badge>
            );
        } else if (moment(account.currentSubscription?.currentPeriodEndsAt).subtract(7, 'days') < moment()) {
            tags.push(
                <Badge key={`${account.id}-7`} variant="error">
                    LESS THAN 7 DAYS
                </Badge>
            );
        } else if (moment(account.currentSubscription?.currentPeriodEndsAt).subtract(30, 'days') < moment()) {
            tags.push(
                <Badge key={`${account.id}-30`} variant="warn">
                    LESS THAN 30 DAYS
                </Badge>
            );
        } else if (moment(account.currentSubscription?.currentPeriodEndsAt).subtract(3, 'months') < moment()) {
            tags.push(
                <Badge key={`${account.id}-90`} variant="info">
                    LESS THAN 90 DAYS
                </Badge>
            );
        }

        if (account.currentSubscription?.autoRenew === false && includeAutoRenew) {
            tags.push(
                <Badge key={`${account.id}-auto-renew`} variant="error">
                    AUTO RENEW OFF
                </Badge>
            );
        }
    }

    return <div className="flex flex-row space-x-1 items-center">{tags}</div>;
};

export { Tags };
