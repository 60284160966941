import { useQuery, gql } from '@apollo/client';
import {
    AccountSubscriptionInfo,
    BillingPlanKind,
    SubscriptionsPage__RecentlyExpiredTab,
    SubscriptionsPage__RecentlyExpiredTabVariables,
} from '../../types';
import { SubscriptionResultBox } from './SubscriptionResultBox';
import { Tags } from './SubscriptionTags';
import pluralize from '../../helpers/pluralize';

const RECENTLY_EXPIRED_QUERY = gql`
    query SubscriptionsPage__RecentlyExpiredTab {
        recentlyExpiredEnterpriseAccounts {
            id
            name
            currentPlan {
                id
                kind
                name
            }
            currentSubscription {
                autoRenew
                canceledAt
                expiresAt
                currentPeriodStartedAt
                currentPeriodEndsAt
            }
            isLocked
        }
    }
`;
const RecentlyExpiredTab = () => {
    const { data, loading, error } = useQuery<
        SubscriptionsPage__RecentlyExpiredTab,
        SubscriptionsPage__RecentlyExpiredTabVariables
    >(RECENTLY_EXPIRED_QUERY);

    if (loading) return <p className="italic">Loading...</p>;
    if (error) return <div className="error">{error.message}</div>;

    const allAccounts = data?.recentlyExpiredEnterpriseAccounts
        ?.filter((a: AccountSubscriptionInfo) => a.currentPlan.kind !== BillingPlanKind.ENTERPRISE_INTERNAL)
        .sort((a, b) => (a.name.trim() > b.name.trim() ? 1 : -1))
        .map((a) => {
            return {
                id: `${a.name} [${a.id}]`,
                linkUrl: `/a/${a.id}`,
                description: <>{a.currentSubscription ? `Current plan: ${a.currentPlan.name}` : ''}</>,
                tags: <Tags account={a} />,
            };
        });

    return (
        <>
            <p className="px-6 font-bold text-xl">{pluralize('account', allAccounts?.length ?? 0)}</p>
            <SubscriptionResultBox results={allAccounts} />
        </>
    );
};

export { RecentlyExpiredTab };
