import { useEffect } from 'react';
import { TabContentProps } from './TabNav';
import Page from '../../components/Page';
import { SAFAssessmentList } from './SAFAsssessmentList';
import { Route, Routes } from 'react-router-dom';
import { SAFAssessment } from './SAFAssessment';

interface SAFTabProps extends TabContentProps {}

export const SAFTab = ({ setActiveTab, accountId }: SAFTabProps) => {
    useEffect(() => {
        setActiveTab('saf');
    });
    return (
        <Page>
            <Routes>
                <Route path="/:graphId/:assessment" element={<SAFAssessment />} />
                <Route path="/" element={<SAFAssessmentList accountId={accountId} />} />
            </Routes>
        </Page>
    );
};
