import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Button, Center, Icon } from '@chakra-ui/react';
import { ReactComponent as UploadIcon } from '@apollo/icons/default/IconUpload.svg';

type FileUploadProps = {
    onFileAccepted: (file: File) => void;
};
export default function FileUpload({ onFileAccepted }: FileUploadProps) {
    const onDrop = useCallback(
        (acceptedFiles: File[]) => {
            onFileAccepted(acceptedFiles[0]);
        },
        [onFileAccepted]
    );

    const { getRootProps, getInputProps, open } = useDropzone({
        onDrop,
        accept: {
            'application/xml': ['.xml'],
            'text/xml': ['.xml'],
        },
        maxFiles: 1,
        multiple: false,
        noClick: true,
    });

    return (
        <Center
            p={10}
            cursor="pointer"
            transition="background-color 0.2s ease"
            {...getRootProps()}
            className="bg-secondary border border-primary border-1 rounded flex flex-col !p-3 align-center"
        >
            <input {...getInputProps()} />
            <div className="inline-flex flex-col space-y-2 items-center">
                <Icon as={UploadIcon} className="stroke-icon-primary fill-icon-primary" boxSize="8" />
                <Button size="sm" variant="secondary" onClick={open}>
                    Choose file
                </Button>
            </div>
        </Center>
    );
}
