import React from 'react';
import { useQuery, gql } from '@apollo/client';

import Column from '../components/Column';
import Page from '../components/Page';
import Config from '../config';

import * as Types from '../types';
import { useParams } from 'react-router-dom';
import { BillingPlanTier } from '../types';

const PLANS_QUERY = gql`
    query Plan__FullPageQuery($planId: ID) {
        plan(id: $planId) {
            id
            name
            description
            billingModel
            billingPeriod
            pricePerUnitInUsdCents
            pricePerSeatInUsdCents
            addons {
                id
                pricePerUnitInUsdCents
            }
            clients
            datadog
            errors
            federation
            maxRangeInDays
            maxRequestsPerMonth
            metrics
            notifications
            operationRegistry
            persistedQueries
            ranges
            schemaValidation
            traces
            userRoles
            public
            kind
            tier
            isTrial
        }
    }
`;

const Plan: React.FC = () => {
    const { planId } = useParams();

    const { data, loading, error } = useQuery<Types.Plan__FullPageQuery, Types.Plan__FullPageQueryVariables>(
        PLANS_QUERY,
        {
            variables: { planId },
            skip: !planId,
        }
    );

    const plan = data?.plan;

    return (
        <Page>
            {loading ? (
                <div className="d-flex justify-content-center my-5">
                    <div className="spinner-grow text-primary center" role="status">
                        <span className="sr-only" />
                    </div>
                </div>
            ) : error ? (
                error.message
            ) : !planId ? (
                'We could not find a plan in your URL to use for lookup.'
            ) : !data || !plan ? (
                'Plan information could not be fetched.'
            ) : (
                <>
                    <Column num={2}>
                        <ul>
                            <h5>{plan.name}</h5>
                        </ul>

                        <ul>
                            {plan.tier !== BillingPlanTier.USAGE_BASED && (
                                <li>
                                    <p>Recurly link: </p>
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={`${Config.recurlyURL}plans/${planId}`}
                                    >
                                        {planId}
                                    </a>
                                </li>
                            )}
                            {plan.tier === BillingPlanTier.USAGE_BASED && (
                                <li>
                                    <p>Metronome link: </p>
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={`${Config.metronomeURL}${planId}`}
                                    >
                                        {planId}
                                    </a>
                                </li>
                            )}
                        </ul>

                        <div>{plan.description}</div>
                    </Column>
                    <Column num={2}>
                        <ul>
                            <li>
                                <p>Billing model:</p> {plan.billingModel}
                            </li>
                            <li>
                                <p>Billing period:</p> {plan.billingPeriod}
                            </li>
                            <li>
                                <p>Base price:</p> $
                                {(plan.pricePerUnitInUsdCents / 100).toLocaleString('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                    currencyDisplay: 'code',
                                })}
                            </li>
                            {plan.pricePerSeatInUsdCents && (
                                <li>
                                    <p>Price per seat:</p> $
                                    {(plan.pricePerSeatInUsdCents / 100).toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                        currencyDisplay: 'code',
                                    })}
                                </li>
                            )}
                            <li>
                                <p>Addons:</p>{' '}
                                <ul style={{ marginLeft: 16 }}>
                                    {plan.addons.map(({ id, pricePerUnitInUsdCents }) => (
                                        <li key={id}>
                                            {id}: $
                                            {(pricePerUnitInUsdCents / 100).toLocaleString('en-US', {
                                                style: 'currency',
                                                currency: 'USD',
                                                currencyDisplay: 'code',
                                            })}
                                        </li>
                                    ))}
                                </ul>
                            </li>
                        </ul>

                        <ul>
                            <li>
                                <p>Kind:</p> {plan.kind}
                            </li>
                            <li>
                                <p>Tier:</p> {plan.tier}
                            </li>
                            <li>
                                <p>Publicly listed to all users?</p> {JSON.stringify(plan.public)}
                            </li>
                            <li>
                                <p>Trial?</p> {JSON.stringify(plan.isTrial)}
                            </li>
                        </ul>

                        <ul>
                            <li>
                                <p>Capabilities:</p>
                                <ul style={{ marginLeft: 16 }}>
                                    <li>clients: {JSON.stringify(plan.clients)}</li>
                                    <li>datadog: {JSON.stringify(plan.datadog)}</li>
                                    <li>errors: {JSON.stringify(plan.errors)}</li>
                                    <li>federation: {JSON.stringify(plan.federation)}</li>
                                    <li>maxRangeInDays: {plan.maxRangeInDays}</li>
                                    <li>
                                        maxRequestsPerMonth:{' '}
                                        {plan.maxRequestsPerMonth ? plan.maxRequestsPerMonth.toLocaleString() : 'n/a'}
                                    </li>
                                    <li>metrics: {JSON.stringify(plan.metrics)}</li>
                                    <li>notifications: {JSON.stringify(plan.notifications)}</li>
                                    <li>operationRegistry: {JSON.stringify(plan.operationRegistry)}</li>
                                    <li>persistedQueries: {JSON.stringify(plan.persistedQueries)}</li>
                                    <li>ranges: {plan.ranges ? plan.ranges.join(', ') : 'n/a'}</li>
                                    <li>schemaValidation: {JSON.stringify(plan.schemaValidation)}</li>
                                    <li>traces: {JSON.stringify(plan.traces)}</li>
                                    <li>userRoles: {JSON.stringify(plan.userRoles)}</li>
                                </ul>
                            </li>
                        </ul>
                    </Column>
                </>
            )}
        </Page>
    );
};

export default Plan;
