import { useEffect } from 'react';
import Chart from './Chart';
import { TabContentProps } from './TabNav';
import UsageTable from './Usage';
import Page from '../../components/Page';
interface MetricsTabProps extends TabContentProps {}

export default function MetricsTab({ accountId, setActiveTab }: MetricsTabProps) {
    useEffect(() => {
        setActiveTab('metrics');
    }, [setActiveTab]);

    return (
        <Page>
            <div>
                <Chart accountId={accountId} />
            </div>

            <UsageTable accountId={accountId} />
        </Page>
    );
}
