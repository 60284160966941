import { useEffect } from 'react';
import { Metadata } from './Metadata';
import { TabContentProps } from './TabNav';

interface BillingTabProps extends TabContentProps {}

export default function BillingTab({ accountId, setActiveTab }: BillingTabProps) {
    useEffect(() => {
        setActiveTab('billing');
    });
    return <Metadata accountId={accountId} />;
}
