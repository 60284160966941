import React, { FunctionComponent, useState } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as IconInfo } from '@apollo/icons/default/IconInfoSolid.svg';
import { ReactComponent as IconClose } from '@apollo/icons/default/IconClose.svg';

import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Tooltip,
} from '@chakra-ui/react';
import { useQuery, useMutation, gql } from '@apollo/client';
import moment from 'moment';
import { groupBy } from 'lodash';
import * as Types from '../../types';
import Page from '../../components/Page';
import { Spinner } from '@chakra-ui/react';
import pluralize from '../../helpers/pluralize';
import { ConfirmationModal } from '../../components/ConfirmationModal';
import { ODYSSEY_CERTIFICATE_FRAGMENT } from '../User';
import { OdysseyCertificateBadges } from '../../components/OdysseyCertificateBadges';
import capitalize from '../../helpers/capitalize';

type UserMembership = Types.AccountUsersMemberships;
export const ACCOUNT_USERS_FRAGMENT = gql`
    fragment AccountUsersMemberships on AccountMembership {
        permission
        user {
            id
            fullName
            lastAuthenticatedAt
            avatarUrl
            odysseyCertifications {
                ...OdysseyCertificateFragment
            }
        }
    }
    fragment AccountUsers on Account {
        id
        memberships {
            ...AccountUsersMemberships
        }
    }
    ${ODYSSEY_CERTIFICATE_FRAGMENT}
`;

const ACCOUNT_USERS_QUERY = gql`
    query Account__UsersQuery($id: ID!) {
        account(id: $id) {
            ...AccountUsers
        }
    }
    ${ACCOUNT_USERS_FRAGMENT}
    ${ODYSSEY_CERTIFICATE_FRAGMENT}
`;

const REMOVE_USER_FROM_ACCOUNT_MUTATION = gql`
    mutation Account__RemoveUser($accountId: ID!, $userId: ID!) {
        account(id: $accountId) {
            removeMember(id: $userId) {
                memberships {
                    user {
                        id
                    }
                }
            }
        }
    }
`;

interface UserListProps {
    groupName: string;
    users: Types.AccountUsersMemberships[];
    accountId: string;
    filter: string;
}

const UserList: FunctionComponent<UserListProps> = ({ groupName, users, accountId, filter }) => {
    const [confirmDeleteModalState, setDeleteConfirmModalState] = useState<{ show: boolean; userId: string }>({
        show: false,
        userId: '',
    });
    const [removeMember] = useMutation(REMOVE_USER_FROM_ACCOUNT_MUTATION, {
        refetchQueries: [{ query: ACCOUNT_USERS_QUERY, variables: { id: accountId } }],
    });

    const defaultExpand = users.length <= 20;

    users = users.sort(function (a, b) {
        const c = new Date(a.user.lastAuthenticatedAt || '8/20/2018');
        const d = new Date(b.user.lastAuthenticatedAt || '8/20/2018');
        if (c > d) return -1;
        if (c < d) return 1;
        return 0;
    });

    const activeUsers = users.filter((user) => {
        return moment(user.user.lastAuthenticatedAt) > moment().subtract(30, 'days');
    });

    const inactiveUsers = users.length - activeUsers.length;

    function userFilter({ user }: UserMembership): boolean {
        if (filter) {
            // compare fullname against filter.
            return user.fullName.toLowerCase().includes(filter.toLowerCase());
        }

        // if filter is null, return true so that we see full list.
        return true;
    }
    const label = `${capitalize(groupName)}: ${activeUsers.length} Active Users`;
    return (
        <Box>
            <Accordion allowMultiple defaultIndex={defaultExpand ? [0] : undefined} className="pb-4">
                <AccordionItem>
                    <AccordionButton>
                        <InactiveUsersTooltip
                            label={
                                <div>
                                    <div>{inactiveUsers} inactive users</div> since 30 days ago.
                                </div>
                            }
                            aria-label={label?.toString()}
                        >
                            <IconInfo className="h-5 pr-2 fill-icon-info" />
                            <div className="text-heading">{label}</div>
                        </InactiveUsersTooltip>
                        <AccordionIcon />
                    </AccordionButton>

                    <AccordionPanel>
                        {(users as UserMembership[])
                            .filter(userFilter) // apply filter function.
                            .map(({ permission, user: u }) => {
                                return (
                                    <li
                                        key={u.id}
                                        className="flex items-center px-2 py-1 even:bg-secondary text-sm border-1 border-primary border-y"
                                    >
                                        <Link to={`/u/${encodeURIComponent(u.id)}`} className="grow ">
                                            <div className="inline-flex items-center">
                                                {u.avatarUrl ? (
                                                    <img
                                                        className="h-6 w-6 rounded-full mr-3"
                                                        src={u.avatarUrl}
                                                        alt="user avatar"
                                                    />
                                                ) : (
                                                    <div className="img small bg-inverted" />
                                                )}

                                                <span className="font-bold">{u.fullName}</span>
                                            </div>
                                            <div>
                                                <p style={{ marginLeft: 4 }}>
                                                    {permission} last seen{' '}
                                                    {u.lastAuthenticatedAt
                                                        ? `${moment(u.lastAuthenticatedAt).format('M/D/YY h:mma')}`
                                                        : 'before 8/20/18'}
                                                </p>
                                            </div>
                                        </Link>
                                        <OdysseyCertificateBadges certificates={u.odysseyCertifications} />
                                        <IconClose
                                            className="h-4 fill-icon-primary opacity-75 hover:cursor-pointer hover:opacity-60"
                                            onClick={() => setDeleteConfirmModalState({ show: true, userId: u.id })}
                                        />
                                    </li>
                                );
                            })}
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
            <ConfirmationModal
                callback={() => {
                    removeMember({
                        variables: { accountId, userId: confirmDeleteModalState.userId },
                    });
                    setDeleteConfirmModalState({ userId: '', show: false });
                }}
                show={confirmDeleteModalState.show}
                setShow={(state) => setDeleteConfirmModalState({ ...confirmDeleteModalState, show: state })}
                message={
                    <>
                        Are you sure you'd like to remove <b>{confirmDeleteModalState.userId}</b> from the{' '}
                        <b>{accountId}</b> organization?
                    </>
                }
            />
        </Box>
    );
};

const Users: React.FC<{ accountId: string }> = ({ accountId }) => {
    const [filter, setFilter] = useState('');

    const { data, loading, error } = useQuery<Types.Account__UsersQuery>(ACCOUNT_USERS_QUERY, {
        variables: { id: accountId },
    });

    if (loading)
        return (
            <Page>
                <Spinner size={'lg'} />
            </Page>
        );
    if (error) return <Page>{error.message}</Page>;
    if (!data || !data.account)
        return (
            <Page>
                Account <p>{accountId}</p>'s users couldn't be found.
            </Page>
        );

    const { account } = data;

    const permissionOrder = [
        Types.UserPermission.ORG_ADMIN.valueOf(),
        Types.UserPermission.GRAPH_ADMIN.valueOf(),
        Types.UserPermission.CONTRIBUTOR.valueOf(),
        Types.UserPermission.OBSERVER.valueOf(),
        Types.UserPermission.DOCUMENTER.valueOf(),
        Types.UserPermission.PERSISTED_QUERY_PUBLISHER.valueOf(),
        Types.UserPermission.CONSUMER.valueOf(),
        Types.UserPermission.BILLING_MANAGER.valueOf(),
    ];
    const groupUsers = groupBy(account.memberships, (obj) => {
        return obj.permission;
    });

    const numUsers = account.memberships ? account.memberships.length : 0;
    return (
        <Page>
            <p className="font-bold text-base pb-2">{pluralize('user', numUsers)}</p>
            <ul>
                {numUsers ? (
                    <div>
                        <input
                            className="bg-input text-secondary border border-1 border-primary rounded p-2 mb-4 placeholder:text-primary"
                            placeholder="Filter users"
                            onChange={(e) => setFilter(e.currentTarget.value)}
                        />

                        {Object.entries(groupUsers)
                            .sort(([groupNameA, _], [groupNameB, __]) =>
                                permissionOrder.indexOf(groupNameA) > permissionOrder.indexOf(groupNameB) ? 1 : -1
                            )
                            .map(([groupName, users]) => {
                                return (
                                    <UserList
                                        key={groupName}
                                        accountId={accountId}
                                        groupName={groupName}
                                        users={users}
                                        filter={filter}
                                    />
                                );
                            })}
                    </div>
                ) : (
                    <li>No users.</li>
                )}
            </ul>
        </Page>
    );
};

type InactiveUsersTooltipProps = {
    label: JSX.Element | string;
    children: React.ReactNode;
};

const InactiveUsersTooltip: React.FC<InactiveUsersTooltipProps> = ({ label, children }) => {
    return (
        <div className="pr-2">
            <Tooltip label={label} aria-label={typeof label === 'string' ? label : undefined}>
                <Box className="flex flex-row items-center">{children}</Box>
            </Tooltip>
        </div>
    );
};

export default Users;
