import { TypedDocumentNode, gql, useQuery } from '@apollo/client';
import {
    AccountSubscriptionInfo,
    BillingPlanKind,
    SubscriptionsPage__AutoRenewOffTab,
    SubscriptionsPage__AutoRenewOffTabVariables,
} from '../../types';
import { SubscriptionResultBox } from './SubscriptionResultBox';
import { Tags } from './SubscriptionTags';
import pluralize from '../../helpers/pluralize';

const ENTERPRISE_RENEWALS_QUERY: TypedDocumentNode<SubscriptionsPage__AutoRenewOffTab> = gql`
    query SubscriptionsPage__AutoRenewOffTab {
        allAccounts(tier: ENTERPRISE) {
            ...AccountSubscriptionInfo
        }
    }

    fragment AccountSubscriptionInfo on Account {
        id
        name
        currentPlan {
            id
            kind
            name
        }
        currentSubscription {
            autoRenew
            canceledAt
            expiresAt
            currentPeriodStartedAt
            currentPeriodEndsAt
        }
        isLocked
    }
`;

const AutoRenewOffTab = () => {
    const { data, loading, error } = useQuery<
        SubscriptionsPage__AutoRenewOffTab,
        SubscriptionsPage__AutoRenewOffTabVariables
    >(ENTERPRISE_RENEWALS_QUERY);

    if (loading) return <p className="italic">Loading...</p>;
    if (error) return <div className="error">{error.message}</div>;

    const nonRenewingAccounts = data?.allAccounts
        ?.filter((a: AccountSubscriptionInfo) => a.currentPlan.kind !== BillingPlanKind.ENTERPRISE_INTERNAL)
        .filter((a: any) => !a.currentSubscription?.autoRenew)
        .sort(
            (a, b) =>
                new Date(a.currentSubscription?.currentPeriodEndsAt ?? '').getTime() -
                new Date(b.currentSubscription?.currentPeriodEndsAt ?? '').getTime()
        )
        .map((a) => {
            return {
                id: `${a.name} [${a.id}]`,
                linkUrl: `/a/${a.id}`,
                description: (
                    <div>
                        <div>
                            Started on:{' '}
                            {new Intl.DateTimeFormat().format(
                                new Date(a.currentSubscription?.currentPeriodStartedAt ?? 0)
                            )}
                        </div>
                        <div>
                            Expires on{' '}
                            {new Intl.DateTimeFormat().format(
                                new Date(a.currentSubscription?.currentPeriodEndsAt ?? 0)
                            )}
                        </div>
                    </div>
                ),
                tags: <Tags account={a} includeAutoRenew={false} />,
            };
        });
    return (
        <>
            <p className="px-6 font-bold text-xl">{pluralize('account', nonRenewingAccounts?.length ?? 0)}</p>
            <SubscriptionResultBox results={nonRenewingAccounts} />
        </>
    );
};

export { AutoRenewOffTab };
