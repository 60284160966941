import { DocumentNode, gql, NetworkStatus, useQuery } from '@apollo/client';
import { ReactComponent as IconOutlink } from '@apollo/icons/default/IconOutlink.svg';
import { CalendarIcon } from '@heroicons/react/24/outline';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import Config from '../config';
import {
    BillingPlanTier,
    Search__Account,
    Search__Account__Plan,
    Search__AllAccountsQuery,
    Search__AllAccountsQueryVariables,
    Search__BillingHistorySearchQuery,
    Search__BillingHistorySearchQueryVariables,
    Search__GraphsQuery,
    Search__GraphsQuery__Plan__Service,
    Search__GraphsQueryVariables,
    Search__PlansQuery,
    Search__TieredAccountsQuery,
    Search__TieredAccountsQueryVariables,
    Search__UsersQuery,
    Search__UsersQueryVariables,
} from '../types';
import { Button, Spinner } from '@chakra-ui/react';
import { ResultBoxPropsResults, ResultsBox } from './ResultsBox';
import { SearchBox, SearchOptions, SearchType } from './SearchBox';
import { HeaderContext } from '../helpers/HeaderContext';
import Page from './Page';
import { Badge } from '@chakra-ui/react';

const SEARCH_ACCOUNT_FRAGMENT = gql`
    fragment Search__Account__Plan on BillingPlan {
        id
        kind
    }
    fragment Search__Account on Account {
        id
        name
        internalID
        avatarUrl
        secondaryIDs
        currentPlan {
            ...Search__Account__Plan
        }
    }
`;
const ALL_ACCOUNT_SEARCH = gql`
    ${SEARCH_ACCOUNT_FRAGMENT}

    query Search__AllAccountsQuery($search: String) {
        allAccounts: searchAccounts(search: $search) {
            ...Search__Account
        }
    }
`;
const TIERED_ACCOUNT_SEARCH = gql`
    ${SEARCH_ACCOUNT_FRAGMENT}
    query Search__TieredAccountsQuery($tier: BillingPlanTier!, $search: String) {
        billingTier(tier: $tier) {
            searchAccounts(search: $search) {
                ...Search__Account
            }
        }
    }
`;
const ALL_PLANS = gql`
    query Search__PlansQuery {
        allPlans: allBillingPlans {
            id
        }
    }
`;

const USER_SEARCH = gql`
    query Search__UsersQuery($search: String) {
        allUsers(search: $search) {
            id
            fullName
            email
            memberships {
                account {
                    id
                    name
                }
            }
            lastAuthenticatedAt
            avatarUrl
        }
    }
`;

const GRAPH_SEARCH = gql`
    fragment Search__GraphsQuery__Plan__Service on Service {
        id
        accountId
        name
        lastReportedAt
        deletedAt
    }
    query Search__GraphsQuery($search: String) {
        allServices(search: $search) {
            ...Search__GraphsQuery__Plan__Service
        }
    }
`;

const BILLING_HISTORY_SEARCH = gql`
    query Search__BillingHistorySearchQuery($search: ID!) {
        billingSubscriptionHistory(id: $search) {
            uuid
        }
    }
`;

type SearchState = {
    query: DocumentNode;
    variables: SearchVariables;
};

type SearchQueries =
    | Search__AllAccountsQuery
    | Search__TieredAccountsQuery
    | Search__PlansQuery
    | Search__UsersQuery
    | Search__GraphsQuery
    | Search__BillingHistorySearchQuery;
type SearchVariables =
    | Search__AllAccountsQueryVariables
    | Search__TieredAccountsQueryVariables
    | Search__UsersQueryVariables
    | Search__GraphsQueryVariables
    | Search__BillingHistorySearchQueryVariables;

export const Search = () => {
    const [parameters, setParameters] = useState<SearchState>({
        variables: { tier: BillingPlanTier.ENTERPRISE, search: '' },
        query: TIERED_ACCOUNT_SEARCH,
    });
    const { setContent } = useContext(HeaderContext);

    const { data, loading, error, networkStatus } = useQuery<SearchQueries, SearchVariables>(parameters.query, {
        variables: parameters.variables,
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-first',
        nextFetchPolicy: 'cache-first',
    });

    useEffect(() => {
        setContent(<></>);
    }, [setContent]);
    let ref = useRef<NodeJS.Timeout>();

    const onSearchUpdate = useCallback((term: string, options: SearchOptions) => {
        const timeout = ref.current;
        // avoid empty searches
        if (!term && !options.allowEmptySearch) {
            return;
        }

        // if a timeout exists (aka a pause before actually searching), clear it to avoid it triggering
        if (timeout) {
            clearTimeout(timeout);
        }

        // create a new timeout and execute the query
        ref.current = setTimeout(function () {
            let query = TIERED_ACCOUNT_SEARCH;
            switch (options.type) {
                case SearchType.USERS:
                    query = USER_SEARCH;
                    break;
                case SearchType.PLANS:
                    query = ALL_PLANS;
                    break;
                case SearchType.GRAPHS:
                    query = GRAPH_SEARCH;
                    break;
                case SearchType.BILLING_HISTORY:
                    query = BILLING_HISTORY_SEARCH;
                    break;
                case SearchType.ALL:
                    query = ALL_ACCOUNT_SEARCH;
                    break;
                default:
                    query = TIERED_ACCOUNT_SEARCH;
                    break;
            }

            setParameters({
                query,
                variables: { tier: options.accountFilter ?? BillingPlanTier.ENTERPRISE, search: term },
            });
        }, 333);
    }, []);

    // typechecking the response using root field presence
    const isTieredAccountSearch = (data: SearchQueries): data is Search__TieredAccountsQuery =>
        (data as Search__TieredAccountsQuery).billingTier !== undefined;
    const isAllAccountSearch = (data: SearchQueries): data is Search__AllAccountsQuery =>
        (data as Search__AllAccountsQuery).allAccounts !== undefined;
    const isUserSearch = (data: SearchQueries): data is Search__UsersQuery =>
        (data as Search__UsersQuery).allUsers !== undefined;
    const isPlanSearch = (data: SearchQueries | Search__UsersQuery): data is Search__PlansQuery =>
        (data as Search__PlansQuery).allPlans !== undefined;
    const isGraphSearch = (data: SearchQueries): data is Search__GraphsQuery =>
        (data as Search__GraphsQuery).allServices !== undefined;
    const isBillingHistorySearch = (data: SearchQueries): data is Search__BillingHistorySearchQuery =>
        (data as Search__BillingHistorySearchQuery).billingSubscriptionHistory !== undefined;

    const resultMapping = (data: SearchQueries): Array<ResultBoxPropsResults> => {
        if (isAllAccountSearch(data) || isTieredAccountSearch(data)) {
            let users: Array<Search__Account> = [];

            if (isAllAccountSearch(data)) {
                users = data.allAccounts;
            } else if (isTieredAccountSearch(data)) {
                users = data.billingTier?.searchAccounts ?? [];
            }
            return users.map((v) => {
                let description = [v.id, ...v.secondaryIDs];
                let pills = PlanPill({ plan: v.currentPlan });
                return {
                    id: v.id,
                    name: v.name,
                    title: (
                        <div>
                            {v.avatarUrl ? (
                                <img
                                    className="h-6 w-6 rounded-full mr-3"
                                    src={v.avatarUrl ?? ''}
                                    alt={`${v.name} icon`}
                                />
                            ) : (
                                <></>
                            )}
                            {v.name}
                        </div>
                    ),
                    linkUrl: `a/${v.id}`,
                    description: <>{description.join(', ')}</>,
                    context: pills.element,
                    info: (
                        <Button
                            variant={'primary'}
                            size={'sm'}
                            onClick={(e) => {
                                e.preventDefault();
                                window.open(`${Config.frontendURL}account/${v.id}`, '_blank');
                            }}
                            type="button"
                        >
                            <div>View in Studio</div>
                        </Button>
                    ),
                    tags: [
                        {
                            label: 'Account Type',
                            tags: pills.Badges,
                        },
                    ],
                };
            });
        } else if (isUserSearch(data) && data.allUsers) {
            return data.allUsers.map((v) => {
                let description = v.memberships.map((m) => (
                    <li key={m.account.id}>
                        <div
                            onClick={(e) => {
                                e.preventDefault();
                                window.open(`/a/${m.account.id}`, '_blank');
                            }}
                            className="pr-2 inline-flex items-center hover:underline"
                        >
                            <div className="pr-1">{`${m.account.name} (${m.account.id})`}</div>{' '}
                            <IconOutlink className="h-3 w-3 stroke-icon-primary" />
                        </div>
                    </li>
                ));
                let domains: Array<string> = [];
                let metadata: Array<string> = [];
                // naively get domains from either the ID (which may come across there) or in email, if it exists
                if (v.id.indexOf('@') >= 0) {
                    domains.push(v.id.split('@')[1]);
                }

                if (v.email && v.email.indexOf('@') >= 0) {
                    metadata.push(v.email);
                    domains.push(v.email.split('@')[1]);
                }
                v.memberships.forEach((v) => metadata.push(`${v.account.id} (${v.account.name})`));
                metadata = metadata.concat(...domains);

                return {
                    id: v.id,
                    name: v.fullName,
                    title: (
                        <>
                            {v.fullName} {v.email ? `(${v.email})` : ''}
                        </>
                    ),
                    linkUrl: `u/${v.id}`,
                    context: (
                        <div className="inline-flex items-center">
                            <CalendarIcon className="h-4 w-4 mr-2 fill-icon-primary" />
                            Last authenticated:{' '}
                            {v.lastAuthenticatedAt ? <>{new Date(v.lastAuthenticatedAt).toDateString()}</> : 'Never'}
                        </div>
                    ),
                    description:
                        v.memberships.length > 0 ? (
                            <div>
                                <div className="font-bold font-md not-italic pb-1">ID: {v.id}</div>
                                Member of:
                                <ul className="list-disc list-inside">{description}</ul>
                            </div>
                        ) : (
                            <div>No organization memberships.</div>
                        ),
                    tags: [
                        {
                            label: 'Email domain',
                            tags: domains,
                        },
                    ],
                    metadata,
                };
            });
        } else if (isPlanSearch(data) && data.allPlans) {
            return data.allPlans.map((v) => {
                return {
                    id: v.id,
                    name: v.id,
                    title: <>{v.id}</>,
                    linkUrl: `p/${v.id}`,
                    description: <></>,
                };
            });
        } else if (isGraphSearch(data) && data.allServices) {
            return data.allServices.map((v) => {
                return {
                    id: v.id,
                    name: v.name,
                    title: (
                        <>
                            {v.name} ({v.id})
                        </>
                    ),
                    linkUrl: `a/${v.accountId}/graphs/g/${v.id}`,
                    description: <div className="italic">Account: {v.accountId ?? 'None'}</div>,
                    info: (
                        <div className="inline-flex items-center">
                            <CalendarIcon className="h-4 w-4" />
                            Last reported on: {v.lastReportedAt ? new Date(v.lastReportedAt).toDateString() : 'Never'}
                        </div>
                    ),
                    context: <GraphPill graph={v} />,
                };
            });
        } else if (
            isBillingHistorySearch(data) &&
            data.billingSubscriptionHistory &&
            data.billingSubscriptionHistory.length > 0 &&
            parameters.variables.search
        ) {
            return [
                {
                    id: parameters.variables.search,
                    name: parameters.variables.search,
                    title: <>{parameters.variables.search}</>,
                    linkUrl: `d/${parameters.variables.search}`,
                    description: <></>,
                },
            ];
        } else {
            return [];
        }
    };
    return (
        <div className="rounded">
            <SearchBox onSearchUpdate={onSearchUpdate} />
            {loading || networkStatus === NetworkStatus.refetch ? (
                <Page>
                    <Spinner speed="0.75s" />
                </Page>
            ) : error ? (
                <Page>{error.message}</Page>
            ) : data ? (
                <ResultsBox results={resultMapping(data)} searchTerm={parameters.variables.search ?? ''} />
            ) : (
                ''
            )}
        </div>
    );
};

type PlanPillProps = {
    plan: Search__Account__Plan;
};
const PlanPill = ({ plan }: PlanPillProps) => {
    let badgeElements: Array<JSX.Element> = [];
    let Badges: Array<string> = [];
    let internal = (
        <Badge key={`${plan.id}_internal`} variant={'error'}>
            INTERNAL
        </Badge>
    );

    let trial = (
        <Badge key={`${plan.id}_trial`} variant={'beta'}>
            TRIAL
        </Badge>
    );

    let paid = (
        <Badge key={`${plan.id}_paid`} variant={'success'}>
            PAID
        </Badge>
    );

    let free = (
        <Badge key={`${plan.id}_free`} variant={'warn'}>
            FREE
        </Badge>
    );

    let base = (
        <Badge key={`${plan.id}_kind`} variant={'blue'}>
            {plan.kind.includes('_') ? plan.kind.split('_')[0] : plan.kind}
        </Badge>
    );

    if (plan.kind.includes('INTERNAL')) badgeElements.push(internal) && Badges.push('INTERNAL');
    if (plan.kind.includes('PAID')) badgeElements.push(paid) && Badges.push('PAID');
    if (plan.kind.includes('PILOT') || plan.kind.includes('TRIAL')) badgeElements.push(trial) && Badges.push('TRIAL');
    if (plan.kind.includes('FREE')) badgeElements.push(free) && Badges.push('FREE');
    badgeElements.push(base);
    Badges.push(plan.kind.includes('_') ? plan.kind.split('_')[0] : plan.kind);

    return {
        element: (
            <div key={plan.id} className="ml-2 flex flex-shrink-0 space-x-2 px-2 h-5 items-center">
                {badgeElements}
            </div>
        ),
        Badges,
    };
};
type GraphPillProps = {
    graph: Search__GraphsQuery__Plan__Service;
    showUsage?: boolean;
};

export const GraphPill = ({ graph, showUsage = true }: GraphPillProps): JSX.Element => {
    let { accountId, deletedAt, lastReportedAt } = graph;
    let Badges: Array<JSX.Element> = [];
    let now = new Date();

    let deleted = (
        <Badge key={`${accountId}_deleted`} variant={'error'}>
            DELETED
        </Badge>
    );

    let unowned = (
        <Badge key={`${accountId}_unowned`} variant={'error'}>
            NO OWNER
        </Badge>
    );

    let last30 = (
        <Badge key={`${accountId}_30`} variant={'success'}>
            USAGE LAST 30
        </Badge>
    );

    let last60 = (
        <Badge key={`${accountId}_60`} variant={'info'}>
            USAGE LAST 60
        </Badge>
    );

    let last90 = (
        <Badge key={`${accountId}_90`} variant="warn">
            USAGE LAST 90
        </Badge>
    );

    let greaterThan90 = (
        <Badge key={`${accountId}_no_90`} variant="error">
            NO USAGE LAST 90
        </Badge>
    );

    if (deletedAt) Badges.push(deleted);
    if (!accountId) Badges.push(unowned);
    if (lastReportedAt !== undefined && showUsage && lastReportedAt === null) {
        Badges.push(greaterThan90);
    } else if (lastReportedAt && showUsage) {
        let reportDate = new Date(lastReportedAt).getTime();
        let diff = Math.floor((now.getTime() - reportDate) / (1000 * 3600 * 24));

        if (diff < 30) {
            Badges.push(last30);
        } else if (diff < 60) {
            Badges.push(last60);
        } else if (diff < 90) {
            Badges.push(last90);
        } else {
            Badges.push(greaterThan90);
        }
    }

    return (
        <div className="ml-2 flex flex-shrink-0 space-x-2 px-2" key={`${accountId}`}>
            {Badges}
        </div>
    );
};
