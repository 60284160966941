import { useQuery } from '@apollo/client';
import { Home__Auth, InternalMdgAdminRole } from '../types';
import { ME_QUERY } from '../pages/Home';
import React from 'react';

// Declare role hierarchy
const roles: InternalMdgAdminRole[] = [
    InternalMdgAdminRole.INTERNAL_MDG_READ_ONLY,
    InternalMdgAdminRole.INTERNAL_MDG_SALES,
    InternalMdgAdminRole.INTERNAL_MDG_SUPPORT,
    InternalMdgAdminRole.INTERNAL_MDG_SUPER_ADMIN,
];

export const AuthRoleCheck: React.FC<
    React.PropsWithChildren<{
        role: InternalMdgAdminRole;
    }>
> = ({ role, children }) => {
    const { data, loading } = useQuery<Home__Auth>(ME_QUERY, {
        fetchPolicy: 'cache-first',
    });

    if (loading || !data) {
        return <div>Loading...</div>;
    }

    // If `me` isn't a user (for whatever reason) or doesn't have an internal admin role, don't render anything
    const { me } = data;
    if (!me || me.__typename !== 'User' || !me.internalAdminRole) {
        return null;
    }

    const myRoleIdx = roles.indexOf(me.internalAdminRole);
    const requiredRoleIdx = roles.indexOf(role);

    // If the user's role is lower than the required role, don't render anything
    // If the user has an unknown role, `myRoleIdx` will be -1, so this check fails closed
    if (myRoleIdx < requiredRoleIdx) {
        return null;
    }

    return <>{children}</>;
};
