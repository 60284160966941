import { gql, useMutation } from '@apollo/client';
import { LockClosedIcon } from '@heroicons/react/24/outline';
import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay } from '@chakra-ui/react';
import { Metadata__AccountLock, Metadata__AccountLockVariables } from '../../types';
import { useState } from 'react';
import { classNames } from '../../helpers/classnames';

const METADATA_ACCOUNT_LOCK = gql`
    mutation Metadata__AccountLock($accountId: ID!, $reason: String) {
        account(id: $accountId) {
            lock(reason: $reason, type: MANUAL) {
                id
            }
        }
    }
`;

type LockAccountConfirmationModalProps = {
    show: boolean;
    setShow: (key: boolean) => void;
    accountId: string;
};
const LockAccountConfirmationModal = ({ show, setShow, accountId }: LockAccountConfirmationModalProps) => {
    let [lockAccount] = useMutation<Metadata__AccountLock, Metadata__AccountLockVariables>(METADATA_ACCOUNT_LOCK, {
        refetchQueries: ['Metadata_Account__MetadataQuery', 'Header_Account__MetadataQuery'],
    });
    let [reason, setReason] = useState<string>('');

    return (
        <Modal isOpen={show} onClose={() => setShow(!show)}>
            <ModalOverlay />
            <ModalContent>
                <ModalCloseButton />
                <ModalBody className="flex flex-col items-center rounded-lg shadow-sm space-y-2 text-center">
                    <div>
                        <LockClosedIcon className="bg-red rounded-full h-12 w-12 p-2 mx-auto stroke-white" />
                    </div>
                    <div className="font-bold text-xl">Are you sure you want to lock this account?</div>
                    <div>
                        Locking this account will cause the customer to lose access to their account and deactivate
                        their API keys which could affect production traffic. Please make sure to verify you should be
                        doing this before proceeding.
                    </div>
                    <div>
                        <div className="inline-flex font-bold">
                            Reason for locking account <div className="text-red-600">*</div>
                        </div>
                        <textarea
                            rows={4}
                            name="comment"
                            id="comment"
                            className="inline-flex w-full rounded border-0 py-1.5 text-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 p-3"
                            onChange={(e) => setReason(e.target.value)}
                            value={reason}
                        />
                        <div className={classNames('text-error', !reason.trim() ? 'font-bold' : 'hidden')}>
                            Reason is required
                        </div>
                    </div>
                    <div className="inline-flex space-x-4">
                        <Button
                            type="button"
                            className="inline-flex items-center gap-x-1.5 rounded bg-grey-dark px-2.5 py-1.5 text-sm font-bold text-white shadow-sm hover:bg-grey-darker focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-800"
                            onClick={() => setShow(false)}
                            variant={'primary'}
                            size={'sm'}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="button"
                            isDisabled={!reason.trim()}
                            onClick={() => {
                                if (!reason.trim()) {
                                    return;
                                }
                                lockAccount({ variables: { accountId, reason } });
                                setShow(false);
                            }}
                            variant={'destructive'}
                            size={'sm'}
                        >
                            <LockClosedIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                            Lock Account
                        </Button>
                    </div>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export { LockAccountConfirmationModal };
