import { Fragment } from 'react';
import {
    Modal,
    ModalBody,
    ModalHeader,
    ModalOverlay,
    ModalCloseButton,
    ModalContent,
    Table,
    Th,
    Tr,
    Td,
    TableContainer,
    Thead,
} from '@chakra-ui/react';
import { calcVariantTotal, UsageCache } from '../helpers/UsageCalc';
import UsageTableRow from './UsageTableRow';

interface UsageDetailModalProps {
    cache: UsageCache;
    groupText: string;
    state: boolean;
    setState: (key: boolean) => void;
    setTitle: string;
    type: string;
    shouldShowDetailedCounts: boolean;
}

// TODO override max-width: 592px breakpoint
export default function UsageDetailModal({
    cache,
    groupText,
    state,
    setState,
    setTitle,
    type,
    shouldShowDetailedCounts,
}: UsageDetailModalProps) {
    const showThirdRow = type === 'billing';
    return (
        <Modal isOpen={state} size="4xl" onClose={() => setState(!state)}>
            <ModalOverlay />
            <ModalContent className="bg-primary">
                <ModalHeader>{setTitle}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <TableContainer
                        overflowX="scroll"
                        style={{
                            transform: 'rotateX(180deg)',
                        }}
                    >
                        <Table
                            size="sm"
                            whiteSpace="normal"
                            style={{
                                transform: 'rotateX(180deg)',
                            }}
                            variant="unstyled"
                        >
                            <Thead>
                                <Tr>
                                    {showThirdRow && <Th>{groupText}</Th>}
                                    <Th>Month</Th>
                                    <Th>Total Operations</Th>
                                    {shouldShowDetailedCounts ? (
                                        <>
                                            <Th>Queries</Th>
                                            <Th>Mutations</Th>
                                            <Th>Subscription Registrations</Th>
                                            <Th>Subscription Notifications</Th>
                                        </>
                                    ) : null}
                                </Tr>
                            </Thead>
                            {Object.entries(cache)
                                .sort()
                                .map((v) => {
                                    return v;
                                })
                                .map(([tagKey, agents]) => (
                                    <Fragment key={tagKey}>
                                        <Tr className="border-t-2 bg-primary">
                                            {showThirdRow && <Td className="font-bold">🪐 {tagKey}</Td>}
                                            <Td>Total</Td>
                                            <Td className="font-bold">{calcVariantTotal(agents)}</Td>
                                            {shouldShowDetailedCounts ? (
                                                <>
                                                    <Td className="font-bold">{calcVariantTotal(agents, 'queries')}</Td>
                                                    <Td className="font-bold">
                                                        {calcVariantTotal(agents, 'mutations')}
                                                    </Td>
                                                    <Td className="font-bold">
                                                        {calcVariantTotal(agents, 'subscriptionRequests')}
                                                    </Td>
                                                    <Td className="font-bold">
                                                        {calcVariantTotal(agents, 'subscriptionEvents')}
                                                    </Td>
                                                </>
                                            ) : null}
                                        </Tr>
                                        {Object.entries(agents).map(([agentKey, metrics]) => (
                                            <Fragment key={agentKey}>
                                                <UsageTableRow
                                                    metrics={metrics}
                                                    agentKey={`🔭 ${agentKey}`}
                                                    includeThirdRow={showThirdRow}
                                                    shouldShowDetailedCounts={shouldShowDetailedCounts}
                                                />
                                            </Fragment>
                                        ))}
                                        {/* Adding a <br> to the end of the <tbody> causes an HTML styling error, but ignoring it */}
                                        <br />
                                    </Fragment>
                                ))}
                        </Table>
                    </TableContainer>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}
