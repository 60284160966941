import { gql, useQuery } from '@apollo/client';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { SAFRedirect_GetSAFById, SAFRedirect_GetSAFByIdVariables } from '../types';

const SAF_GET_SAF_BY_ID = gql`
    query SAFRedirect_GetSAFById($graphId: ID!, $safAssessmentId: ID!) {
        graph(id: $graphId) {
            id
            account {
                id
            }
            safAssessment(id: $safAssessmentId) {
                id
            }
        }
    }
`;

export const SAFRedirect: React.FC = () => {
    const navigate = useNavigate();
    const { safId, graphId } = useParams<{ safId: string; graphId: string }>();
    const { data } = useQuery<SAFRedirect_GetSAFById, SAFRedirect_GetSAFByIdVariables>(SAF_GET_SAF_BY_ID, {
        variables: {
            graphId: graphId ?? '',
            safAssessmentId: safId ?? '',
        },
    });

    if (!data?.graph?.safAssessment) {
        return (
            <div>
                <h1>SAF not found</h1>
                <p>Sorry, we couldn't find the SAF you're looking for.</p>
            </div>
        );
    }

    const url = `/a/${data.graph.account?.id}/saf/${data.graph.id}/${data.graph.safAssessment.id}`;
    navigate(url);

    return (
        <div>
            You should be redirected to{' '}
            <Link to={url} className="hover:underline hover:cursor-pointer">
                {window.location.protocol}
                {'//'}
                {window.location.host}
                {url} now. If you're having trouble, click on the link here.
            </Link>
            .
        </div>
    );
};
