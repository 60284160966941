import React, { FunctionComponent, useState } from 'react';
import { classNames } from '../helpers/classnames';
import { Button } from '@chakra-ui/react';

interface Props {
    label?: JSX.Element | string;
    defaultOpen?: boolean;
    inactiveUsers?: number;
    callback?: (opened: boolean) => void;
    children: React.ReactNode;
    tooltip?: JSX.Element;
}

const Drawer: FunctionComponent<Props> = ({ defaultOpen, label, inactiveUsers, children, callback, tooltip }) => {
    const [open, toggle] = useState(defaultOpen || false);
    if (!tooltip) {
        tooltip = <div className="pr-2 grow">{label}</div>;
    }
    return (
        <React.Fragment>
            <div
                style={{ cursor: 'pointer' }}
                className={`${classNames(['toggle', { open }])} flex flex-row font-bold my-2 text-l`}
                onClick={() => {
                    if (callback) callback(open);
                    toggle(!open);
                }}
            >
                {React.cloneElement(tooltip, { children: <>{label}</> })}

                {open ? <CollapseButton /> : <ExpandButton />}
            </div>
            {open ? children : null}
        </React.Fragment>
    );
};

const ExpandButton = () => {
    return (
        <Button variant={'primary'} size="sm">
            Expand
        </Button>
    );
};

const CollapseButton = () => {
    return (
        <Button variant={'primary'} size="sm">
            Collapse
        </Button>
    );
};

export default Drawer;
