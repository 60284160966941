import React, { useState, useEffect, useRef, useMemo, useContext } from 'react';
import { useQuery, gql } from '@apollo/client';
import { Link, useParams } from 'react-router-dom';
import moment from 'moment';
import { Mutation } from '@apollo/react-components';
import PunchCard from './Account/PunchCard';
import FieldEdit from '../components/FieldEdit';
import Column from '../components/Column';
import Config from '../config';
import * as Types from '../types';
import { ReactComponent as IconOutlink } from '@apollo/icons/default/IconOutlink.svg';
import { HeaderContext } from '../helpers/HeaderContext';
import { Button } from '@chakra-ui/react';
import Page from '../components/Page';

const SERVICE_QUERY = gql`
    fragment Service__FullPageQuery__Variant on GraphVariant {
        name
        isPublic
        isPubliclyListed
        activeSchemaPublish {
            id
            historyLength
        }
        hasManagedSubgraphs
        buildConfig {
            buildPipelineTrack
        }
        registryStatsWindow(from: $from, resolution: R1D) {
            schemaPublishStats {
                timestamp
                metrics {
                    totalPublishes
                }
            }
        }
    }
    query Service__FullPageQuery($id: ID!, $from: Timestamp!) {
        service(id: $id) {
            id
            title
            account {
                id
            }
            createdAt
            lastReportedAt
            deletedAt
            graphType
            variants {
                ...Service__FullPageQuery__Variant
            }
        }
    }
`;

const UNDELETE_MUTATION = gql`
    mutation Service__UnDeleteServiceMutation($id: ID!) {
        service(id: $id) {
            undelete {
                deletedAt
            }
        }
    }
`;

const TRANSFER_SERVICE_MUTATION = gql`
    mutation Service__TransferServiceMutation($id: ID!, $account: String!) {
        service(id: $id) {
            transfer(to: $account) {
                id
                account {
                    id
                }
            }
        }
    }
`;

const TOGGLE_IS_PUBLICLY_LISTED = gql`
    mutation Variant__TogglePubliclyListed($serviceId: ID!, $variantName: String!, $isPubliclyListed: Boolean!) {
        service(id: $serviceId) {
            variant(name: $variantName) {
                updateVariantIsPubliclyListed(isPubliclyListed: $isPubliclyListed) {
                    id
                }
            }
        }
    }
`;

const Service: React.FC = () => {
    const { serviceId } = useParams();
    const inputRef = useRef(null);
    const [value, update] = useState(365);
    const [days, updateDays] = useState(value);
    const [open, toggle] = useState(false);
    const [selectedPublicVariantToPubliclyList, setSelectedPublicVariantToPubliclyList] = useState<
        Types.Service__FullPageQuery__Variant | undefined
    >(undefined);
    const { setContent } = useContext(HeaderContext);
    useEffect(() => {
        setContent(<></>);
    }, [setContent]);

    const { data, loading, error } = useQuery<Types.Service__FullPageQuery>(SERVICE_QUERY, {
        variables: { id: serviceId, from: moment.utc().startOf('day').subtract(days, 'days').toISOString() },
        skip: !serviceId,
    });
    const publicVariants = useMemo(() => data?.service?.variants?.filter((variant) => variant.isPublic), [data]);

    useEffect(() => {
        // @ts-ignore
        if (open && inputRef && inputRef.current) inputRef.current.focus();
    }, [open]);

    useEffect(() => {
        if (!selectedPublicVariantToPubliclyList && publicVariants?.length) {
            setSelectedPublicVariantToPubliclyList(publicVariants[0]);
        }
    }, [publicVariants, selectedPublicVariantToPubliclyList]);

    const submit = (value: number) => {
        updateDays(value);
        toggle(false);
    };

    return (
        <Page>
            {loading ? (
                <div className="d-flex justify-content-center my-5">
                    <div className="spinner-grow text-primary center" role="status">
                        <span className="sr-only" />
                    </div>
                </div>
            ) : error ? (
                error.message
            ) : !serviceId ? (
                'We could not find a graph in your URL to use for lookup.'
            ) : !data || !data.service ? (
                <>
                    Service <p>{serviceId}</p> does not exist.
                </>
            ) : !data.service.account ? (
                <>
                    Service <p>{serviceId}</p> does not have an account, so we cannot load the page.
                </>
            ) : (
                <>
                    <Column>
                        <React.Fragment>
                            <ul>
                                <li>
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={`${Config.frontendURL}graph/${data.service.id}`}
                                    >
                                        <div className="flex">
                                            <div>
                                                <strong>Graph id:</strong>

                                                <div className="flex flex-row">
                                                    <p>{data.service.id}</p>
                                                    <IconOutlink className="stroke-icon-primary h-3 w-3 ml-1" />
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <strong>Graph title:</strong>
                                    <p>{data.service.title}</p>
                                </li>
                                <li>
                                    <strong>Account id:</strong>
                                    <p>{data.service.account.id}</p>
                                </li>
                                <li>
                                    <strong>Graph type:</strong>
                                    <p>{data.service.graphType.toLowerCase()}</p>
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <p>Created:</p> {moment(data.service.createdAt).format('MMMM DD, YYYY')}
                                </li>
                                <li>
                                    <p>Last reported:</p> {moment(data.service.lastReportedAt).format('MMMM DD, YYYY')}
                                </li>
                                <li>
                                    <p>Deleted:</p>{' '}
                                    {data.service.deletedAt
                                        ? moment(data.service.deletedAt).format('MMMM DD, YYYY')
                                        : 'Not deleted.'}
                                </li>
                                {data.service.deletedAt ? (
                                    <li>
                                        <Mutation<Types.Service__UnDeleteServiceMutation>
                                            variables={{ id: serviceId }}
                                            mutation={UNDELETE_MUTATION}
                                        >
                                            {(mutate, { loading, error, called }) => {
                                                if (loading) <p className="italic">Undeleting...</p>;
                                                if (error) <>error?.message</>;
                                                if (!loading && called) {
                                                    window.location.reload();
                                                    return <></>;
                                                }
                                                return (
                                                    <div>
                                                        <Button
                                                            onClick={() => {
                                                                mutate();
                                                            }}
                                                            variant={'primary'}
                                                            size={'sm'}
                                                        >
                                                            Undelete
                                                        </Button>
                                                    </div>
                                                );
                                            }}
                                        </Mutation>
                                    </li>
                                ) : null}
                            </ul>
                            <ul>
                                <FieldEdit
                                    overrideVariableWithInputValue="account"
                                    label="Owner"
                                    variables={{ id: serviceId }}
                                    placeholder={data.service.account.id}
                                    mutation={TRANSFER_SERVICE_MUTATION}
                                >
                                    <Link to={`/a/${data.service.account.id}`} className="hover:underline">
                                        {data.service.account.id}
                                    </Link>
                                </FieldEdit>
                            </ul>
                            {selectedPublicVariantToPubliclyList && publicVariants && (
                                <ul>
                                    <label className="pr-1">Choose a variant to publicly list:</label>
                                    <select
                                        name="variants"
                                        id="variants"
                                        onChange={(e) =>
                                            setSelectedPublicVariantToPubliclyList(
                                                publicVariants.find((variant) => variant.name === e.target.value)
                                            )
                                        }
                                        className="bg-input rounded p-1 border border-1"
                                    >
                                        {publicVariants.map((variant) => (
                                            <option key={variant.name} value={variant.name}>
                                                {variant.name}
                                            </option>
                                        ))}
                                    </select>
                                    <Mutation<Types.Variant__TogglePubliclyListed>
                                        variables={{
                                            serviceId,
                                            variantName: selectedPublicVariantToPubliclyList.name,
                                            isPubliclyListed: !selectedPublicVariantToPubliclyList.isPubliclyListed,
                                        }}
                                        mutation={TOGGLE_IS_PUBLICLY_LISTED}
                                    >
                                        {(mutate, { loading, error, called }) => {
                                            if (loading) <p className="italic">Setting...</p>;
                                            if (error) <>error?.message</>;
                                            if (!loading && called) {
                                                window.location.reload();
                                                return <></>;
                                            }
                                            return (
                                                <div className="pb-2">
                                                    <Button
                                                        variant={'primary'}
                                                        size={'sm'}
                                                        onClick={() => {
                                                            mutate();
                                                        }}
                                                    >
                                                        {selectedPublicVariantToPubliclyList.isPubliclyListed
                                                            ? 'Do not publicly list this variant'
                                                            : 'Publicly list this variant'}
                                                    </Button>
                                                </div>
                                            );
                                        }}
                                    </Mutation>
                                </ul>
                            )}
                        </React.Fragment>
                    </Column>
                    <Column>
                        <ul>
                            <p>Data range:</p>{' '}
                            {open ? (
                                <input
                                    className="bg-input"
                                    ref={inputRef}
                                    type="number"
                                    value={value}
                                    onChange={(e) => {
                                        // @ts-ignore
                                        update(e.target.value);
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.keyCode === 27) {
                                            toggle(false);
                                        } else if (e.keyCode === 13) {
                                            // @ts-ignore
                                            submit(e.target.value);
                                        }
                                    }}
                                />
                            ) : (
                                <span>{value} days</span>
                            )}{' '}
                            <Button
                                onClick={() => {
                                    if (open) {
                                        submit(value);
                                    } else {
                                        toggle(!open);
                                    }
                                }}
                                variant={'primary'}
                                size={'sm'}
                            >
                                {open ? 'Submit' : 'Edit'}
                            </Button>
                        </ul>
                        <ul>
                            {data.service.variants.map(({ name, registryStatsWindow }) => (
                                <li key={name}>
                                    <div>
                                        Schema publishes on <p>{name}</p>{' '}
                                        <p className="italic">
                                            {registryStatsWindow?.schemaPublishStats.reduce(
                                                (a, v) => a + v.metrics.totalPublishes,
                                                0
                                            )}{' '}
                                            schema publishes in selected date range
                                        </p>
                                    </div>
                                    {registryStatsWindow && (
                                        <PunchCard
                                            data={registryStatsWindow.schemaPublishStats}
                                            tag={name}
                                            daysBack={days}
                                        />
                                    )}
                                </li>
                            ))}
                        </ul>
                    </Column>
                </>
            )}
        </Page>
    );
};

export default Service;
