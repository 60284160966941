import { useEffect } from 'react';
import { TabContentProps } from './TabNav';
// import CreateSupportTicket from './CreateSupportTicket';
// import SupportTicketTable from './SupportTickets';
import Page from '../../components/Page';

interface SupportTabProps extends TabContentProps {}

export default function SupportTab({ accountId, setActiveTab }: SupportTabProps) {
    useEffect(() => {
        setActiveTab('support');
    });
    return (
        <Page className="divide-y divide-silver-darker dark:divide-midnight">
            {/* <SupportTicketTable accountId={accountId} />
            <CreateSupportTicket accountId={accountId} /> */}
            <p>
                Due to the cutover to the new system, it is not possible to create new support tickets or view existing
                tickets at this time.
            </p>
        </Page>
    );
}
