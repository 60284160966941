import { useEffect } from 'react';
import Graphs from './Graphs';

type GraphsTabProps = {
    daysBack: number;
    accountId: string;
    setActiveTab: Function;
};

export default function GraphsTab({ daysBack, accountId, setActiveTab }: GraphsTabProps) {
    const doNotUseQueryStats = accountId === 'redbull';
    useEffect(() => {
        setActiveTab('graphs');
    });
    return (
        <div className="row">{doNotUseQueryStats ? null : <Graphs accountId={accountId} daysBack={daysBack} />}</div>
    );
}
