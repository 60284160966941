import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as IconOutlink } from '@apollo/icons/default/IconOutlink.svg';
import { useQuery, gql } from '@apollo/client';
import moment from 'moment';
import Config from '../../config';
import { groupBy } from 'lodash';
import Drawer from '../../components/Drawer';
import pluralize from '../../helpers/pluralize';
import capitalize from '../../helpers/capitalize';
import * as Types from '../../types';

const ACCOUNT_SUBSCRIPTION_HISTORY = gql`
    query Metadata_Account__SubscriptionHistoryQuery($internalAccountId: ID!) {
        billingSubscriptionHistory(id: $internalAccountId) {
            state
            activatedAt
            canceledAt
            plan {
                id
                name
            }
        }
    }
`;

function formatDate(timestamp: string | number) {
    return moment(timestamp).format('M/D/YY h:mma');
}

const DeletedMetadata: FunctionComponent<{ internalAccountId: string }> = ({ internalAccountId }) => {
    const { data, loading, error } = useQuery<
        Types.Metadata_Account__SubscriptionHistoryQuery,
        Types.Metadata_Account__SubscriptionHistoryQueryVariables
    >(ACCOUNT_SUBSCRIPTION_HISTORY, {
        variables: { internalAccountId },
    });

    if (loading) return <div>Loading...</div>;
    if (error) return <div>{error?.message}</div>;
    if (!data?.billingSubscriptionHistory)
        return (
            <div>
                Account <p>{internalAccountId}</p>'s subscription history could not be found.
            </div>
        );

    return (
        <React.Fragment>
            <div className="flex flex-col space-y-2 pb-2">
                <div>
                    <div>
                        <p className="font-bold">Internal ID:</p> {internalAccountId}
                    </div>
                </div>
            </div>
            <hr className="pb-2" />
            {data?.billingSubscriptionHistory?.length ? (
                <ul>
                    <>
                        {internalAccountId && (
                            <li>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="flex-initial flex-row items-center hover:no-underline inline-block text-white bg-btn-primary hover:bg-btn-primary-hover mt-4 px-3 py-1 font-semibold rounded border border-primary"
                                    href={`${Config.recurlyURL}accounts/${
                                        internalAccountId.includes('.') ? '' : 'apollo.'
                                    }${internalAccountId}`}
                                >
                                    <div className="flex flex-row">
                                        Visit account in Recurly
                                        <IconOutlink className="stroke-icon-primary h-3 w-3 ml-1" />
                                    </div>
                                </a>
                            </li>
                        )}
                        {Object.entries(
                            groupBy(
                                data?.billingSubscriptionHistory as Array<Types.BillingSubscription>,
                                (s) => s.state
                            )
                        ).map(([subState, subs]) => (
                            <ul key={subState} style={{ marginTop: 20 }}>
                                <Drawer
                                    defaultOpen={subState === Types.SubscriptionState.ACTIVE}
                                    label={<p>{pluralize(`${capitalize(subState)} Subscription`, subs.length)}</p>}
                                >
                                    {subs.map((s) => (
                                        <React.Fragment key={s.activatedAt}>
                                            <li>
                                                <Link to={`/p/${s.plan.id}`}>
                                                    <span>{s.plan.id}</span> <p className="italic">{s.state}</p>
                                                </Link>
                                                <div>
                                                    <p className="italic">{s.plan.name}</p>
                                                </div>
                                                <div>
                                                    <div className="italic">
                                                        <p>Activated:</p> {formatDate(s.activatedAt)}
                                                    </div>
                                                </div>
                                                {s.canceledAt ? (
                                                    <div>
                                                        <p className="italic">
                                                            <p>Canceled:</p> {formatDate(s.canceledAt)}
                                                        </p>
                                                    </div>
                                                ) : null}
                                            </li>
                                            <br />
                                        </React.Fragment>
                                    ))}
                                </Drawer>
                            </ul>
                        ))}
                    </>
                </ul>
            ) : null}
        </React.Fragment>
    );
};

export { DeletedMetadata };
