import { classNames } from '../../helpers/classnames';

interface TabProps {
    id: string;
    text: string;
    active: string;
    setActive: (key: string) => void;
}

export interface TabContentProps {
    accountId: string;
    setActiveTab: Function;
}

export default function Tab({ active, id, setActive, text }: TabProps) {
    const isCurrent = active === id;
    return (
        <div className="mb-px flex px-4 space-x-1" id={id} onClick={(event) => setActive(event.currentTarget.id)}>
            <button
                className={classNames(
                    isCurrent
                        ? 'border-blue-light dark:text-white hover:border-blilet-lighter'
                        : 'border-midnight-dark text-grey-lighter dark:text-gray-300 dark:hover:text-grey hover:text-grey hover:border-gray-300',
                    'whitespace-nowrap py-3 px-1 border-b-4 font-medium text-l just'
                )}
            >
                {text}
            </button>
        </div>
    );
}
