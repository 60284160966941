import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Tab from './Account/TabNav';
import {
    AllEnterpriseTab,
    AutoRenewOffTab,
    EnterpriseTrialsTab,
    RecentlyExpiredTab,
    UpcomingExpirationsTab,
} from './Subscriptions';
import { HeaderContext } from '../helpers/HeaderContext';
import Page from '../components/Page';

function Subscriptions() {
    const navigate = useNavigate();
    const location = useLocation();
    const [activeTab, setActiveTab] = useState<string>('auto-renew');
    const { setContent } = useContext(HeaderContext);
    const handleOnClick = useCallback(
        (path: string) => {
            setActiveTab(path);
            navigate(path, { replace: false });
        },
        [navigate]
    );

    /**
     * Tabs for navigation
     */
    const tabs = useMemo(
        () => [
            {
                id: 'auto-renew',
                text: 'Auto Renew Off',
            },
            {
                id: 'all',
                text: 'All Enterprise Accounts',
            },
            {
                id: 'trial',
                text: 'All Enterprise Trial Accounts',
            },
            {
                id: 'recent',
                text: 'Recently Expired Enterprise Accounts (last 45 days)',
            },
            {
                id: 'upcoming',
                text: 'Upcoming Enterprise Expirations (next 30 days)',
            },
        ],
        []
    );

    useEffect(() => {
        setContent(
            <div className="inline-flex flex-row text-sm items-center pl-10 md:pl-0 overflow-auto max-w-auto md:max-w-full snap-x">
                <div className="flex flex-row items-center">
                    <div className="flex flex-row shrink items-center">
                        {tabs.map((tab) => (
                            <Tab
                                key={tab.id}
                                id={tab.id}
                                text={tab.text}
                                active={activeTab}
                                setActive={(key: string) => handleOnClick(key)}
                            />
                        ))}
                    </div>
                </div>
            </div>
        );
    }, [activeTab, setActiveTab, handleOnClick, setContent, tabs]);

    useEffect(() => {
        if (location.pathname.split('/subscriptions/')[1] in tabs) {
            setActiveTab(location.pathname.split('/subscriptions/')[1]);
        }
    }, [location, tabs]);

    return (
        <Page>
            <div className="flex flex-col">
                <div>
                    <Routes>
                        <Route path="/" element={<AutoRenewOffTab />} />
                        <Route path="auto-renew" element={<AutoRenewOffTab />} />
                        <Route path="all" element={<AllEnterpriseTab />} />
                        <Route path="trial" element={<EnterpriseTrialsTab />} />
                        <Route path="recent" element={<RecentlyExpiredTab />} />
                        <Route path="upcoming" element={<UpcomingExpirationsTab />} />
                    </Routes>
                </div>
            </div>
        </Page>
    );
}

export { Subscriptions };
