import React, { FunctionComponent } from 'react';
import { range } from 'lodash';
import moment from 'moment';
import { classNames } from '../../helpers/classnames';
import { Service__FullPageQuery_MetricDetails__SchemaPublishStats } from '../../types';

interface PageProps {
    data: Array<Service__FullPageQuery_MetricDetails__SchemaPublishStats>;
    daysBack: number;
    tag: string;
}

const PunchCard: FunctionComponent<PageProps> = ({ data, daysBack, tag }) => {
    if (!data || data.length <= 0) return null;

    // map is the data cache for the PunchCard
    const map: { [s: string]: number } = {};

    data.forEach(({ metrics, timestamp }) => {
        const d = moment.utc(timestamp).startOf('day').toISOString();
        const i = map[d] || 0;
        map[d] = i + metrics.totalPublishes;
    });

    const tiles = range(daysBack)
        .reverse()
        .map((minus) => moment.utc().subtract(minus, 'days').startOf('day'))
        .map((m) => {
            return {
                m,
                v: map[m.toISOString()] || 0, // true tile value
            };
        });

    const max = Math.max(...tiles.map((t) => t.v));
    return (
        <div style={{ lineHeight: 0, marginTop: 5 }}>
            {tiles.map(({ v, m }) => (
                <div
                    key={m.toISOString()}
                    className={classNames(
                        'inline-block h-2 w-2 m-px rounded-sm',
                        v >= 0
                            ? v === 0
                                ? 'bg-silver border-silver-darker dark:bg-midnight-darker dark:border-midnight-darkest'
                                : 'bg-green-dark border-green'
                            : 'bg-silver-darker border-slate-300 dark:bg-midnight-darker dark:border-midnight-darkest'
                    )}
                    style={{
                        filter: `brightness(${v > 0 ? v / max + 1 : 1})`,
                    }}
                    title={
                        v < 0
                            ? 'missing data, limited to fetching 100 publishes'
                            : `${tag}
${v.toLocaleString()} publish${v === 1 ? '' : 'es'} on ${m.format('MMM Do, YYYY')}`
                    }
                />
            ))}
        </div>
    );
};

export default PunchCard;
