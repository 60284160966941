import React, { useState, useEffect, useRef, useMemo, FunctionComponent } from 'react';
import { useQuery, gql } from '@apollo/client';
import { Link, useParams } from 'react-router-dom';
import moment, { Moment } from 'moment';
import { Mutation } from '@apollo/react-components';
import FieldEdit from '../components/FieldEdit';
import Config from '../config';
import * as Types from '../types';
import { ReactComponent as IconOutlink } from '@apollo/icons/default/IconOutlink.svg';
import {
    Badge,
    Button,
    Spinner,
    Stat,
    StatDownArrow,
    StatHelpText,
    StatLabel,
    StatNumber,
    StatUpArrow,
} from '@chakra-ui/react';
import { UsageCache, AgentMap, UsageQueryData, buildUsageCache } from '../helpers/UsageCalc';
import { usageSelector } from '../pages/Account/Usage';
import { BarChart } from './BarChart';
import Page from './Page';

interface PageProps {
    fastMode: boolean;
}
const SERVICE_QUERY = gql`
    fragment Service__FullPageQuery_MetricDetails_Variant on GraphVariant {
        id
        name
        isPublic
        isPubliclyListed
        activeSchemaPublish {
            id
            historyLength
        }
        isContract
        hasManagedSubgraphs
        buildConfig {
            buildPipelineTrack
        }
        sourceVariant {
            id
            name
        }
        derivedVariants {
            id
            name
        }
        registryStatsWindow(from: $from, resolution: R1D) {
            schemaPublishStats {
                ...Service__FullPageQuery_MetricDetails__SchemaPublishStats
            }
        }
    }
    fragment Service__FullPageQuery_MetricDetails__SchemaPublishStats on AccountPublishesStatsRecord {
        timestamp
        metrics {
            totalPublishes
        }
    }
    query Service__FullPageQuery_MetricDetails($id: ID!, $from: Timestamp!, $to: Timestamp) {
        service(id: $id) {
            id
            title
            graphType
            account {
                id
            }
            createdAt
            ... on Service @defer {
                lastReportedAt
            }
            deletedAt
            variants {
                ...Service__FullPageQuery_MetricDetails_Variant
            }
            registryStatsWindow(from: $from, to: $to, resolution: R1D) {
                schemaCheckStats {
                    timestamp
                    metrics {
                        totalFailedChecks
                        totalSuccessfulChecks
                    }
                }
                schemaPublishStats {
                    metrics {
                        totalPublishes
                    }
                    timestamp
                }
            }
            statsWindow(from: $from, to: $to, resolution: R1D) {
                billingUsageStats {
                    groupBy {
                        agentVersion
                        schemaTag
                    }
                    timestamp
                    metrics {
                        operationCount
                    }
                }
                queryStats {
                    groupBy {
                        schemaTag
                    }
                    metrics {
                        totalRequestCount
                    }
                    timestamp
                }
            }
        }
    }
`;

const UNDELETE_MUTATION = gql`
    mutation Metrics__UnDeleteServiceMutation($id: ID!) {
        service(id: $id) {
            undelete {
                deletedAt
            }
        }
    }
`;

const TRANSFER_SERVICE_MUTATION = gql`
    mutation Metrics__TransferServiceMutation($id: ID!, $account: String!) {
        service(id: $id) {
            transfer(to: $account) {
                id
                account {
                    id
                }
            }
        }
    }
`;

const TOGGLE_IS_PUBLICLY_LISTED = gql`
    mutation Variant__TogglePubliclyListed_details($graphId: ID!, $variantName: String!, $isPubliclyListed: Boolean!) {
        service(id: $graphId) {
            variant(name: $variantName) {
                updateVariantIsPubliclyListed(isPubliclyListed: $isPubliclyListed) {
                    id
                }
            }
        }
    }
`;

const FederationTag = (version: string = '', isContract: boolean) => {
    let tagColour = 'error';

    if (version.indexOf('FED_1') !== -1) {
        tagColour = 'warn';
    } else if (version.indexOf('FED_2') !== -1) {
        tagColour = 'success';
    } else {
        version = 'NOT FEDERATED';
    }
    if (isContract) {
        version = 'CONTRACT';
        tagColour = 'indigo';
    }
    return <Badge variant={tagColour}>{version.replace('FED_', 'Federation ').replace('_', '.')}</Badge>;
};

const Service: FunctionComponent<PageProps> = ({ fastMode = true }) => {
    const { graphId } = useParams();
    const inputRef = useRef(null);
    const [value, update] = useState(180);
    const [days, updateDays] = useState(value);
    const [open, toggle] = useState(false);

    let queryTo: Moment | null = null;
    if (fastMode && queryTo === null) {
        queryTo = moment.utc().startOf('day').subtract(1, 'second').subtract(1, 'day');
    }

    let to = queryTo ?? moment.utc().startOf('day').subtract(1, 'second');

    const { data, loading, error } = useQuery<
        Types.Service__FullPageQuery_MetricDetails,
        Types.Service__FullPageQuery_MetricDetailsVariables
    >(SERVICE_QUERY, {
        variables: {
            id: graphId ?? '',
            from: moment.utc().startOf('day').subtract(days, 'days').toISOString(),
            to: queryTo?.toISOString() ?? undefined,
        },
        skip: !graphId,
    });

    const publicVariants = useMemo(() => data?.service?.variants?.filter((variant) => variant.isPublic), [data]);
    const [selectedPublicVariantToPubliclyList, setSelectedPublicVariantToPubliclyList] = useState<
        Types.Service__FullPageQuery_MetricDetails_Variant | undefined
    >(undefined);

    useEffect(() => {
        // @ts-ignore
        if (open && inputRef && inputRef.current) inputRef.current.focus();
    }, [open]);

    useEffect(() => {
        if (!selectedPublicVariantToPubliclyList && publicVariants?.length) {
            setSelectedPublicVariantToPubliclyList(publicVariants[0]);
        }
    }, [publicVariants, selectedPublicVariantToPubliclyList]);

    const submit = (value: number) => {
        updateDays(value);
        toggle(false);
    };

    let datasets = [];
    let successfulChecks = 0;
    let failedChecks = 0;

    if (data?.service?.registryStatsWindow?.schemaCheckStats) {
        let cache: { [key: string]: { [key: string]: number } } = {};
        data?.service?.registryStatsWindow?.schemaCheckStats.forEach((schemaCheckStat) => {
            if (!cache[schemaCheckStat.timestamp]) {
                cache[schemaCheckStat.timestamp] = {
                    success: 0,
                    fail: 0,
                };
            }

            cache[schemaCheckStat.timestamp].success += schemaCheckStat.metrics.totalSuccessfulChecks;
            cache[schemaCheckStat.timestamp].fail += schemaCheckStat.metrics.totalFailedChecks;
        });

        let success: Array<object> = [];
        let fail: Array<object> = [];

        Object.keys(cache).forEach((c) => {
            let o = cache[c];
            successfulChecks += o.success;
            failedChecks += o.fail;
            // okay yes this looks bad but dates suck
            // convert the stringified timestamp from the API, which is in GMT
            let startDate = new Date(c);
            // now get the timezone offset, which is expressed in minutes. we convert to milliseconds
            let offset = startDate.getTimezoneOffset() * 60 * 1000;
            success.push({
                // and add it to the start time. what this does is effectively treat it as GMT 0 while still being in the user's timezone
                x: new Date(startDate.getTime() + offset),
                y: o.success,
            });
            fail.push({
                // and add it to the start time. what this does is effectively treat it as GMT 0 while still being in the user's timezone
                x: new Date(startDate.getTime() + offset),
                y: o.fail,
            });
        });
        datasets.push({
            label: 'Successful checks',
            data: success,
            stack: 'check',
            borderColor: '#059669',
            backgroundColor: '#059669',
        });
        datasets.push({
            label: 'Failed checks',
            data: fail,
            stack: 'check',
            borderColor: '#b91c1c',
            backgroundColor: '#b91c1c',
        });
    }
    let totalBilling =
        data?.service?.statsWindow?.billingUsageStats.reduce((p, v) => {
            return p + v.metrics.operationCount;
        }, 0) ?? 0;
    let totalQuery = data?.service?.statsWindow?.queryStats.reduce((p, v) => p + v.metrics.totalRequestCount, 0) ?? 0;
    let diffBetweenTypes = ((totalQuery - totalBilling) / totalBilling) * 100;

    const billingCache =
        data &&
        data.service &&
        data.service.statsWindow &&
        buildUsageCache(
            data.service.statsWindow.billingUsageStats as unknown as UsageQueryData,
            usageSelector.billing,
            'day'
        );
    let multiseries = formatBillingCacheData(billingCache || {}, value);

    return (
        <Page className="mt-12">
            {loading ? (
                <div className="flex justify-content-center my-5">
                    <Spinner />
                </div>
            ) : error ? (
                error.message
            ) : !graphId ? (
                'We could not find a graph in your URL to use for lookup.'
            ) : !data || !data.service ? (
                <>
                    Service <p>{graphId}</p> does not exist.
                </>
            ) : !data.service.account ? (
                <>
                    Service <p>{graphId}</p> does not have an account, so we cannot load the page.
                </>
            ) : (
                <div className="row top-0 pt-5">
                    <div className="flex flex-col">
                        <div>
                            <h2 className="text-xl mb-2">
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={`${Config.frontendURL}graph/${data.service.id}`}
                                    className="text-heading"
                                >
                                    <b>Graph ID: </b> {data.service.id}
                                </a>
                            </h2>
                            <div className="flex flex-row flex-wrap">
                                <div className="bg-neutral shadow rounded p-2 m-2 px-4">
                                    <div className="font-bold text-lg text-heading">Details</div>
                                    <div className="flex flex-row flex-wrap gap-4">
                                        <div>
                                            <strong>Graph title</strong> <br />
                                            {data.service.title}
                                        </div>

                                        <div>
                                            <strong>Created</strong>
                                            <br />
                                            {moment(data.service.createdAt).format('MMMM DD, YYYY')}
                                        </div>
                                        <div>
                                            <strong>Last reported</strong>
                                            <br />
                                            {moment(data.service.lastReportedAt).format('MMMM DD, YYYY')}
                                        </div>

                                        <div>
                                            <strong>Graph Deployment Type</strong>
                                            <br />
                                            {data.service.graphType}
                                        </div>
                                        <div>
                                            <strong>Deleted?</strong>
                                            <br />
                                            {data.service.deletedAt
                                                ? moment(data.service.deletedAt).format('MMMM DD, YYYY')
                                                : 'Not deleted.'}
                                        </div>
                                    </div>
                                </div>
                                <div className="mr-4 bg-neutral shadow-md rounded p-2 m-2 px-4">
                                    <div className="font-bold text-lg text-heading">Actions</div>
                                    <div className="flex flex-row flex-1">
                                        <div className="pr-2">
                                            <FieldEdit
                                                overrideVariableWithInputValue="account"
                                                label="Owner"
                                                variables={{ id: graphId }}
                                                placeholder={data.service.account.id}
                                                mutation={TRANSFER_SERVICE_MUTATION}
                                            >
                                                <Link
                                                    className="inline-flex items-center hover:underline "
                                                    to={`/a/${data.service.account.id}`}
                                                >
                                                    {data.service.account.id}{' '}
                                                    <IconOutlink className="stroke-icon-primary h-3 w-3 ml-1" />
                                                </Link>
                                            </FieldEdit>
                                        </div>
                                        <div className="px-2">
                                            Undelete: <br />
                                            {data.service.deletedAt ? (
                                                <Mutation<Types.Service__UnDeleteServiceMutation>
                                                    variables={{ id: graphId }}
                                                    mutation={UNDELETE_MUTATION}
                                                >
                                                    {(mutate, { loading, error, called }) => {
                                                        if (loading) <p className="italic">Undeleting...</p>;
                                                        if (error) <>error?.message</>;
                                                        if (!loading && called) {
                                                            window.location.reload();
                                                            return <></>;
                                                        }
                                                        return (
                                                            <div>
                                                                <Button
                                                                    onClick={() => {
                                                                        mutate();
                                                                    }}
                                                                    variant={'primary'}
                                                                    size={'sm'}
                                                                >
                                                                    Undelete
                                                                </Button>
                                                            </div>
                                                        );
                                                    }}
                                                </Mutation>
                                            ) : (
                                                <>Not deleted, unable to undelete.</>
                                            )}
                                        </div>
                                        <div className="px-2">
                                            {selectedPublicVariantToPubliclyList && publicVariants && (
                                                <ul>
                                                    <label>Choose a variant to publicly list:</label>
                                                    <br />
                                                    <select
                                                        name="variants"
                                                        id="variants"
                                                        className="bg-secondary text-primary rounded border border-1 border-primary px-2 py-1"
                                                        onChange={(e) =>
                                                            setSelectedPublicVariantToPubliclyList(
                                                                publicVariants.find(
                                                                    (variant) => variant.name === e.target.value
                                                                )
                                                            )
                                                        }
                                                    >
                                                        {publicVariants.map((variant) => (
                                                            <option value={variant.name}>{variant.name}</option>
                                                        ))}
                                                    </select>
                                                    <Mutation<Types.Variant__TogglePubliclyListed>
                                                        variables={{
                                                            graphId,
                                                            variantName: selectedPublicVariantToPubliclyList.name,
                                                            isPubliclyListed:
                                                                !selectedPublicVariantToPubliclyList.isPubliclyListed,
                                                        }}
                                                        mutation={TOGGLE_IS_PUBLICLY_LISTED}
                                                    >
                                                        {(mutate, { loading, error, called }) => {
                                                            if (loading) <p className="italic">Setting...</p>;
                                                            if (error) <>error?.message</>;
                                                            if (!loading && called) {
                                                                window.location.reload();
                                                                return <></>;
                                                            }
                                                            return (
                                                                <div className="pt-2">
                                                                    <Button
                                                                        onClick={() => {
                                                                            mutate();
                                                                        }}
                                                                        variant={'primary'}
                                                                        size={'sm'}
                                                                    >
                                                                        {selectedPublicVariantToPubliclyList.isPubliclyListed
                                                                            ? 'Do not publicly list this variant'
                                                                            : 'Publicly list this variant'}
                                                                    </Button>{' '}
                                                                </div>
                                                            );
                                                        }}
                                                    </Mutation>
                                                </ul>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-neutral shadow-md rounded p-2 m-2 grow px-4">
                                    <div className="font-bold text-lg text-heading">
                                        Stats ({to.clone().subtract(days, 'days').format('MMMM D, YYYY')} -
                                        {to.clone().format('MMMM D, YYYY')})
                                    </div>
                                    <div className="flex flex-row space-x-4">
                                        <Stat>
                                            <StatLabel>Total Operations (Query) </StatLabel>
                                            <StatNumber>{new Intl.NumberFormat().format(totalQuery ?? 0)}</StatNumber>
                                        </Stat>
                                        <Stat>
                                            <StatLabel>Total Operations (Billing)</StatLabel>
                                            <StatNumber>{new Intl.NumberFormat().format(totalBilling ?? 0)}</StatNumber>
                                        </Stat>
                                        <Stat>
                                            <StatLabel>Difference</StatLabel>
                                            <StatNumber>
                                                {new Intl.NumberFormat().format(totalQuery - totalBilling)}
                                            </StatNumber>
                                            <StatHelpText>
                                                {diffBetweenTypes.toFixed(2) === '0.00' ||
                                                diffBetweenTypes.toFixed(2) === '-0.00' ? (
                                                    <div />
                                                ) : diffBetweenTypes > 0 ? (
                                                    <StatUpArrow />
                                                ) : (
                                                    <StatDownArrow />
                                                )}{' '}
                                                {diffBetweenTypes.toFixed(2)}%
                                            </StatHelpText>
                                        </Stat>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mt-2 flex flex-row items-center space-x-1">
                            <div>Data range: </div>
                            {open ? (
                                <input
                                    className="bg-input disabled:text-disabled rounded border-1 border-primary focus:ring-focused placeholder:text-placeholder py-0.5 w-24"
                                    ref={inputRef}
                                    type="number"
                                    value={value}
                                    onChange={(e) => {
                                        update(e.target.valueAsNumber);
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.keyCode === 27) {
                                            toggle(false);
                                        } else if (e.keyCode === 13) {
                                            // @ts-ignore
                                            submit(e.target.value);
                                        }
                                    }}
                                />
                            ) : (
                                <span>{value} days</span>
                            )}{' '}
                            <Button
                                onClick={() => {
                                    if (open) {
                                        submit(value);
                                    } else {
                                        toggle(!open);
                                    }
                                }}
                                variant={'primary'}
                                size={'sm'}
                            >
                                {open ? 'Submit' : 'Edit'}
                            </Button>
                        </div>
                        {/**Start data sections */}
                        <div className="border-b border-silver-darker dark:border-midnight-dark">
                            {datasets ? <BarChart data={{ datasets }} title="Checks Overall" /> : <></>}
                            <div className="flex flex-row space-x-4">
                                <Stat>
                                    <StatLabel>Total Successful Checks</StatLabel>
                                    <StatNumber>{new Intl.NumberFormat().format(successfulChecks)}</StatNumber>
                                    <StatHelpText>
                                        {to.clone().subtract(days, 'days').format('MMMM D, YYYY')}-
                                        {to.clone().format('MMMM D, YYYY')}
                                    </StatHelpText>
                                </Stat>
                                <Stat>
                                    <StatLabel>Total Failed Checks</StatLabel>
                                    <StatNumber>{new Intl.NumberFormat().format(failedChecks)}</StatNumber>
                                    <StatHelpText>
                                        {to.clone().subtract(days, 'days').format('MMMM D, YYYY')}-
                                        {to.clone().format('MMMM D, YYYY')}
                                    </StatHelpText>
                                </Stat>
                                <Stat>
                                    <StatLabel>Percent Failed Checks</StatLabel>
                                    <StatNumber>
                                        {successfulChecks + failedChecks !== 0
                                            ? ((failedChecks / (successfulChecks + failedChecks)) * 100).toFixed(2)
                                            : 0}
                                        %
                                    </StatNumber>
                                    <StatHelpText>
                                        {to.clone().subtract(days, 'days').format('MMMM D, YYYY')}-
                                        {to.clone().format('MMMM D, YYYY')}
                                    </StatHelpText>
                                </Stat>
                            </div>
                        </div>
                        <div className="divide-y divide-silver-darker dark:divide-midnight">
                            {data.service.variants.map(
                                ({
                                    name,
                                    registryStatsWindow,
                                    buildConfig,
                                    isContract,
                                    sourceVariant,
                                    derivedVariants,
                                }) => (
                                    <div key={name} className="pt-3 pb-3">
                                        <div>
                                            {FederationTag(buildConfig?.buildPipelineTrack, isContract ?? false)}
                                            <p className="inline-block ml-4 text-heading">
                                                <a
                                                    href={`${Config.frontendURL}graph/${data?.service?.id}/home?variant=${name}`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="flex flex-row items-center text-xl font-bold hover:underline hover:cursor-pointer"
                                                >
                                                    {name}
                                                    <IconOutlink className="stroke-icon-primary h-3 w-3 ml-1" />
                                                </a>
                                            </p>
                                            <br />
                                        </div>
                                        <div className="flex flex-row pt-3">
                                            <Stat>
                                                <StatLabel>Total Requests (Billing Usage)</StatLabel>
                                                <StatNumber>
                                                    {new Intl.NumberFormat().format(
                                                        Object.keys(multiseries[name] ?? []).reduce((prev, key) => {
                                                            return (
                                                                prev +
                                                                multiseries[name][key].data.reduce(
                                                                    (p: number, k: any) => {
                                                                        return p + k.y;
                                                                    },
                                                                    0
                                                                )
                                                            );
                                                        }, 0)
                                                    )}
                                                </StatNumber>
                                                <StatHelpText>
                                                    {to.clone().subtract(days, 'days').format('MMMM D, YYYY')}-
                                                    {to.clone().format('MMMM D, YYYY')}
                                                </StatHelpText>
                                            </Stat>
                                            {registryStatsWindow &&
                                                registryStatsWindow.schemaPublishStats.length > 0 && (
                                                    <Stat>
                                                        <StatLabel>Total Publishes</StatLabel>
                                                        <StatNumber>
                                                            {new Intl.NumberFormat().format(
                                                                registryStatsWindow.schemaPublishStats.reduce(
                                                                    (prev, stat) => {
                                                                        return prev + stat.metrics.totalPublishes;
                                                                    },
                                                                    0
                                                                )
                                                            )}
                                                        </StatNumber>
                                                        <StatHelpText>
                                                            {to.clone().subtract(days, 'days').format('MMMM D, YYYY')}-
                                                            {to.clone().format('MMMM D, YYYY')}
                                                        </StatHelpText>
                                                    </Stat>
                                                )}
                                        </div>
                                        {(sourceVariant || (derivedVariants && derivedVariants?.length > 0)) && (
                                            <div className="pb-2">
                                                <div className="font-bold text-lg pb-2">Contract Configuration</div>
                                                {sourceVariant && <div>Source variant: {sourceVariant.name}</div>}
                                                {derivedVariants && derivedVariants.length > 0 && (
                                                    <div>
                                                        Derived variants:
                                                        <ul className="list-disc">
                                                            {derivedVariants.map((v) => {
                                                                return <li className="ml-5">{v.name}</li>;
                                                            })}
                                                        </ul>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                        {multiseries[name] && (
                                            <>
                                                <div>
                                                    <BarChart
                                                        data={{ datasets: multiseries[name] }}
                                                        title="Usage by Variant"
                                                    />
                                                </div>
                                            </>
                                        )}
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                </div>
            )}
        </Page>
    );
};

const formatBillingCacheData = (usageCache: UsageCache, range: number) => {
    let colorArray = [
        '#2563eb',
        '#a855f7',
        '#d946ef',
        '#fbbf24',
        '#be185d',
        '#4d7c0f',
        '#84cc16',
        '#22d3ee',
        '#4f46e5',
        '#f472b6',
        '#ea580c',
        '#b91c1c',
        '#84cc16',
        '#831843',
        '#059669',
        '#fde68a',
    ];

    let series: { [key: string]: any } = {};
    Object.keys(usageCache).forEach((variant) => {
        const versions: AgentMap = usageCache[variant];
        series[variant] = Object.keys(versions).map((versionKey, index) => {
            const version = versions[versionKey];
            let color = colorArray[index % colorArray.length];
            if (!Object.keys(version).includes(moment().startOf('day').toISOString())) {
                version[moment().startOf('day').toISOString()] = { total: 0 };
            }

            let data = Object.keys(version)
                .map((timestamp) => {
                    // okay yes this looks bad but dates suck
                    // convert the stringified timestamp from the API, which is in GMT
                    let startDate = new Date(timestamp);
                    // now get the timezone offset, which is expressed in minutes. we convert to milliseconds
                    let offset = startDate.getTimezoneOffset() * 60 * 1000;
                    return {
                        // and add it to the start time. what this does is effectively treat it as GMT 0 while still being in the user's timezone
                        x: new Date(startDate.getTime() + offset),
                        y: version[timestamp].total,
                    };
                })
                .sort((a, b) => b.x.valueOf() - a.x.valueOf()); // finally, sort it from soonest to latest
            return {
                label: versionKey,
                stack: '',
                data,
                borderColor: color,
                backgroundColor: color,
            };
        });
    });

    return series;
};

export { Service as MetricDetails };
