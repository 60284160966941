import { Route, Routes } from 'react-router-dom';
import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import pluralize from '../../helpers/pluralize';
import { MetricDetails } from '../../components/MetricDetails';
import * as Types from '../../types';
import { ResultBoxPropsResults, ResultsBox } from '../../components/ResultsBox';
import { GraphPill } from '../../components/Search';
import { CheckIcon, ChevronDownIcon, InformationCircleIcon } from '@heroicons/react/24/outline';
import { Listbox, Transition } from '@headlessui/react';
import { classNames } from '../../helpers/classnames';
import { FormControl, FormLabel, Spinner, Switch, Tooltip } from '@chakra-ui/react';
import Page from '../../components/Page';

const ACCOUNT_GRAPHS_QUERY = gql`
    query Account__GraphsListQuery($id: ID!, $from: Timestamp!) {
        account(id: $id) {
            id
            graphs(includeDeleted: true) {
                id
                accountId
                title
                deletedAt
                name
                ... @defer {
                    lastReportedAt
                }
                ... @defer {
                    variants {
                        id
                        name
                        __typename
                    }
                }
                statsWindow(from: $from, to: "-0") {
                    queries: queryStats {
                        metrics {
                            totalRequestCount
                        }
                    }
                    clients: queryStats {
                        groupBy {
                            clientName
                        }
                    }
                }
            }
        }
    }
`;

const ACCOUNT_GRAPHS_HEADER = gql`
    fragment GraphsListGraph on Service {
        id
        accountId
        title
        deletedAt
        name
        variants {
            id
            name
        }
        statsWindow(from: $from) {
            queries: queryStats {
                metrics {
                    totalRequestCount
                }
            }
        }
    }

    query Account__GraphsListHeaderQuery($id: ID!, $from: Timestamp!) {
        account(id: $id) {
            id
            graphs(includeDeleted: true) {
                ...GraphsListGraph
            }
        }
    }
`;
interface PageProps {
    accountId: string;
    daysBack: number;
    fastMode?: boolean;
    setFastMode?: Function;
}

const Graphs: FunctionComponent<PageProps> = ({ accountId, daysBack }) => {
    const [fastMode, setFastMode] = useState(true);
    return (
        <>
            <>
                <GraphHeaders accountId={accountId} daysBack={daysBack} fastMode={fastMode} setFastMode={setFastMode} />
                <Routes>
                    <Route path="g/:graphId" element={<MetricDetails fastMode={fastMode} />} />
                    <Route path="" element={<GraphsList daysBack={daysBack} accountId={accountId} />} />
                </Routes>
            </>
        </>
    );
};

const GraphsList: FunctionComponent<PageProps> = ({ accountId, daysBack }) => {
    const { data, loading, error } = useQuery<Types.Account__GraphsListQuery>(ACCOUNT_GRAPHS_QUERY, {
        variables: { from: `-${daysBack * 24 * 60 * 60}`, id: accountId },
    });

    if (loading)
        return (
            <Page>
                <Spinner />
            </Page>
        );
    if (error) return <Page>{error.message}</Page>;

    if (!data || !data.account) {
        return (
            <Page>
                Account <p>{accountId}</p>'s graphs could not be found.
            </Page>
        );
    }

    if (data.account.graphs.length === 0) {
        return <Page>No graphs to display.</Page>;
    }

    let sorted = [...data.account.graphs].sort((a, b) =>
        (a.statsWindow?.queries[0]?.metrics.totalRequestCount || 0) >
        (b.statsWindow?.queries[0]?.metrics.totalRequestCount || 0)
            ? -1
            : 1
    );

    let results = sorted.map((g): ResultBoxPropsResults => {
        let graphType = g.deletedAt
            ? 'Deleted'
            : g.statsWindow?.queries[0]?.metrics.totalRequestCount &&
                g.statsWindow?.queries[0]?.metrics.totalRequestCount > 0
              ? 'Active Graph'
              : 'Inactive Graph';
        return {
            id: g.id,
            name: g.title,
            title: <>{g.title}</>,
            description: (
                <>
                    <div className="pl-3 pr-3 pt-3">
                        <div className="text-xs">
                            <p className="font-light dark:text-gray-200 w-20 inline-block">Variants: </p>
                            {g.variants?.length ?? 'Loading...'}
                        </div>
                        <div className="text-xs">
                            <p className="font-light dark:text-gray-200 w-20 inline-block">Requests: </p>
                            {pluralize(`${daysBack}d req`, g.statsWindow?.queries[0]?.metrics.totalRequestCount || 0)}
                        </div>
                        <div className="text-xs">
                            <p className="font-light dark:text-gray-200 w-20 inline-block">Clients: </p>
                            {g.statsWindow?.clients.length}
                        </div>
                    </div>
                </>
            ),
            tags: [{ label: 'State', tags: [graphType] }],
            context: (
                <>
                    <GraphPill graph={g} />
                </>
            ),
            linkUrl: `g/${g.id}`,
        };
    });
    return (
        <Page>
            <ResultsBox showFuzzyToggle={false} searchTerm="" results={results} includeBorder={false} />
        </Page>
    );
};

const GraphHeaders: FunctionComponent<PageProps> = ({ accountId, daysBack, setFastMode, fastMode }) => {
    const [graphList, setGraphList] = useState<Types.GraphsListGraph[]>([]);
    let navigate = useNavigate();
    const location = useLocation();
    const startOfNestedUrl = location.pathname.indexOf('/g/');
    const [selectedGraph, setSelectedGraph] = useState(
        startOfNestedUrl === -1 ? '' : location.pathname.substring(startOfNestedUrl + 3)
    );

    const { data, loading, error } = useQuery<
        Types.Account__GraphsListHeaderQuery,
        Types.Account__GraphsListHeaderQueryVariables
    >(ACCOUNT_GRAPHS_HEADER, {
        variables: { id: accountId, from: `-${daysBack * 24 * 60 * 60}` },
    });

    useEffect(() => {
        let sorted = [...(data?.account?.graphs ?? [])].sort((a, b) => {
            return (a.statsWindow?.queries[0]?.metrics.totalRequestCount || 0) >
                (b.statsWindow?.queries[0]?.metrics.totalRequestCount || 0)
                ? -1
                : 1;
        });
        setGraphList(sorted);
    }, [data, setGraphList]);

    useEffect(() => {
        setSelectedGraph(startOfNestedUrl === -1 ? '' : location.pathname.substring(startOfNestedUrl + 3));
    }, [location, setSelectedGraph, startOfNestedUrl]);

    if (loading) return <></>;
    if (error) {
        return <>{error}</>;
    }
    if (!selectedGraph) {
        return <></>;
    }

    return (
        <div className="sticky flex top-12 items-center h-12 w-full bg-neutral shadow-md z-10">
            <div className="pl-4 z-50">
                <Listbox value={`/a/${accountId}/graphs/g/${selectedGraph}`} onChange={(e) => navigate(e)}>
                    {({ open }) => (
                        <>
                            <div className="relative">
                                <div className="relative">
                                    <div className="inline-flex rounded shadow-sm">
                                        <Listbox.Button className="inline-flex items-center p-2 focus:outline-none">
                                            <div className="inline-flex items-center gap-x-1.5 rounded-l-md bg px-3 py-2 shadow-sm">
                                                <p className="text-sm font-semibold">{selectedGraph}</p>
                                            </div>
                                            <span className="sr-only">Change selected graph</span>
                                            <ChevronDownIcon className="h-5 w-5 text-primary" aria-hidden="true" />
                                        </Listbox.Button>
                                    </div>
                                </div>

                                <Transition
                                    show={open}
                                    as={Fragment}
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <Listbox.Options className="absolute z-40 mt-1 w-128 w-fit max-h-96 overflow-auto rounded bg-primary py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                        {graphList.map((g) => (
                                            <Listbox.Option
                                                key={`/a/${g.accountId}/graphs/g/${g.id}`}
                                                className="text-heading relative select-none py-2 pl-3 pr-3 hover:bg-secondary hover:text-primary cursor-pointer"
                                                value={`/a/${g.accountId}/graphs/g/${g.id}`}
                                            >
                                                {({ selected }) => (
                                                    <div>
                                                        <div className="flex items-center">
                                                            <span
                                                                className={classNames(
                                                                    selected ? 'font-semibold' : 'font-normal',
                                                                    'block truncate'
                                                                )}
                                                            >
                                                                {g.name} ({g.id})
                                                            </span>
                                                            <span className="ml-auto shrink">
                                                                <GraphPill graph={g} showUsage={false} />
                                                            </span>
                                                        </div>

                                                        {selected ? (
                                                            <span
                                                                className={classNames(
                                                                    'text-primary hover:text-secondary absolute inset-y-0 right-0 flex items-center pr-4'
                                                                )}
                                                            >
                                                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                            </span>
                                                        ) : null}
                                                    </div>
                                                )}
                                            </Listbox.Option>
                                        ))}
                                    </Listbox.Options>
                                </Transition>
                            </div>
                        </>
                    )}
                </Listbox>
            </div>
            <div className="pl-4 flex items-center">
                <FormControl className="space-x-2" alignItems={'center'} flexDir={'row'}>
                    <Switch
                        isChecked={fastMode}
                        onChange={(e) => {
                            if (setFastMode) {
                                setFastMode(e.target.checked);
                            }
                        }}
                        color={'blue'}
                    />
                    <FormLabel htmlFor="fastMode" className="mb-0 items-center row" display={'flex'}>
                        <div className="text-primary">Fast Mode</div>

                        <Tooltip
                            label="Fast Mode enables you to get data slightly faster at the cost of real-time data by reducing the time range by 24 hours and 1 second prior to the prior midnight UTC; this is often going to result in a 1.5 day loss in data."
                            openDelay={100}
                        >
                            <div className="text-sm items-center text-middle inline-flex cursor-help">
                                <InformationCircleIcon className="ml-2 h-5 w-5" />
                            </div>
                        </Tooltip>
                    </FormLabel>
                </FormControl>
            </div>
        </div>
    );
};
export default Graphs;
