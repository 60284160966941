/**
 * This file contains the implementation of the DeleteUserButton component.
 * The DeleteUserButton component is responsible for rendering a button that triggers a user deletion alert.
 * When the user confirms the deletion, the component sends a mutation to delete the user account.
 * If the deletion is successful, the component displays a success toast and redirects to the home page.
 * If there is an error during the deletion, the component displays an error toast.
 */

import React, { useState } from 'react';
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Button,
    Tooltip,
    useDisclosure,
    useToast,
} from '@chakra-ui/react';
import { gql } from '@apollo/react-components';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';

/**
 * Props for the DeleteUserButton component.
 */
interface DeleteButtonProps {
    userId: string;
}

/**
 * GraphQL mutation to delete a user.
 */
const USER_DELETE_MUTATION = gql`
    mutation User__DeleteUser($userId: ID!) {
        user(id: $userId) {
            hardDelete
        }
    }
`;

/**
 * DeleteUserButton component.
 * Renders a button that triggers a user deletion alert.
 * Sends a mutation to delete the user account when confirmed.
 * Displays success or error toast based on the deletion result.
 * Redirects to the home page after successful deletion.
 */
export function DeleteUserButton({ userId }: DeleteButtonProps) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const cancelRef = React.useRef<HTMLButtonElement | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const toast = useToast();
    const navigate = useNavigate();
    const [deleteUser] = useMutation(USER_DELETE_MUTATION, { variables: { userId: userId } });

    return (
        <>
            <Button
                onClick={onOpen}
                // className=" px-2 py-0 text-white bg-btn-destructive hover:!bg-btn-destructive-hover font-semibold w-32 !h-7 rounded block text-center !leading-none"
                variant={'destructive'}
                size={'sm'}
            >
                Delete User
            </Button>

            <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Delete User Account
                        </AlertDialogHeader>

                        <AlertDialogBody>Are you sure? You can't undo this action afterwards.</AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose} variant={'primary'} size={'sm'}>
                                Cancel
                            </Button>

                            <Tooltip label="Deletes the user forever">
                                <div>
                                    <Button
                                        onClick={() => {
                                            setIsLoading(true);
                                            onClose();

                                            toast.promise(deleteUser(), {
                                                loading: {
                                                    title: 'Deleting user',
                                                    description:
                                                        'Currently asking the hamsters to delete the user account, please hold.',
                                                },
                                                success: () => {
                                                    return {
                                                        title: 'User removed successfully',
                                                        description: `The user account ${userId} has been hard deleted (forever). In five (5) seconds, this will redirect to the home page.`,
                                                        duration: 5_000,
                                                        onCloseComplete: () => {
                                                            setIsLoading(false);
                                                            navigate('/', {
                                                                replace: true,
                                                            });
                                                        },
                                                    };
                                                },
                                                error: ({ name, message }) => {
                                                    return {
                                                        title: 'Error deleting user',
                                                        description: `${name}: ${message}, please try again.`,
                                                        onCloseComplete: () => {
                                                            setIsLoading(false);
                                                        },
                                                    };
                                                },
                                            });
                                        }}
                                        ml={3}
                                        isDisabled={isLoading}
                                        variant={'destructive'}
                                        size={'sm'}
                                    >
                                        Delete User
                                    </Button>
                                </div>
                            </Tooltip>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    );
}
