import { Link } from 'react-router-dom';

type SubscriptionResultBoxProps = {
    results?: Array<SubscriptionResult>;
};
export type SubscriptionResult = {
    id: string;
    linkUrl: string;
    description: JSX.Element;
    tags?: JSX.Element;
};
const SubscriptionResultBox = ({ results }: SubscriptionResultBoxProps) => {
    if (!results) {
        return <></>;
    }
    return (
        <ul className="divide-y divide-silver-darker dark:divide-midnight pt-4">
            {results.map((result) => (
                <li key={result.id} className="hover:bg-silver dark:hover:bg-midnight-darkest">
                    <Link to={result.linkUrl} className="block hover:no-underline">
                        <div className="px-4 py-4 sm:px-6">
                            <div className="flex items-center justify-between">
                                <div className="truncate text-lg font-bold text-indigo-600 dark:text-white flex flex-row">
                                    {result.id}
                                </div>
                                <div className="truncate text-lg font-bold text-indigo-600 dark:text-white flex flex-row">
                                    {result.tags}
                                </div>
                            </div>
                            <div className="mt-2 sm:flex sm:justify-between">
                                <div className="mt-2 flex items-center text-sm text-gray-500 dark:text-gray-400 sm:mt-0 italic">
                                    <div>{result.description}</div>
                                </div>
                            </div>
                        </div>
                    </Link>
                </li>
            ))}
        </ul>
    );
};

export { SubscriptionResultBox };
