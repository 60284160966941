import { Tooltip, Image, Spacer } from '@chakra-ui/react';
import { OdysseyCertificateFragment } from '../types';
import { classNames } from '../helpers/classnames';
import moment from 'moment';

// mapping certificationId to corresponding image file name
export const certificationImageFileName: { [key: string]: { image: string; name: string } } = {
    'apollo-graph-associate': { image: 'graph_associate_simplified_badge_datwzb', name: 'Apollo Graph Associate' },
    'apollo-graph-associate/v2': {
        image: 'graph_associate_simplified_badge_datwzb',
        name: 'Apollo Graph Associate v2',
    },
    'apollo-graph-professional': {
        image: 'graph_professional_simplified_badge_uivfkx',
        name: 'Apollo Graph Professional',
    },
};

export const OdysseyCertificateBadges: React.FC<{ certificates: OdysseyCertificateFragment[]; className?: string }> = ({
    certificates,
}) => {
    return (
        <div className={classNames('flex flex-row')}>
            {' '}
            {certificates && certificates.length > 0 ? (
                certificates.map((certificate) => {
                    // handle cases where we don't have an image for the certification
                    if (!certificationImageFileName[certificate.certificationId]) {
                        return null;
                    }

                    const certificateImage = `https://res.cloudinary.com/apollographql/image/upload/v1655156691/odyssey/certifications/${
                        certificationImageFileName[certificate.certificationId].image
                    }.svg`;
                    return (
                        <div key={certificate.id}>
                            <Tooltip
                                label={`${
                                    certificationImageFileName[certificate.certificationId].name
                                } earned on ${moment(certificate.earnedAt).format('MMMM Do YYYY')}`}
                                aria-label={certificationImageFileName[certificate.certificationId].name}
                            >
                                <Image
                                    boxSize="25px"
                                    src={certificateImage}
                                    title={`${certificate.certificationId}`}
                                    alt={`${certificate.certificationId}`}
                                />
                            </Tooltip>
                        </div>
                    );
                })
            ) : (
                <Spacer />
            )}
        </div>
    );
};
