import moment, { unitOfTime } from 'moment';
import { Account__UsageQuery__BillingUsage, Account__UsageQuery__QueryStats } from '../types';

// hashmap wizardry
export type OperationTypes =
    | 'total'
    | 'queries'
    | 'mutations'
    | 'subscriptions'
    | 'subscriptionRequests'
    | 'subscriptionEvents'
    | 'noReportedOpType';
export type CountMap = { [month: string]: { [operationType: string]: number } };
export type AgentMap = { [agent: string]: CountMap };
export type UsageCache = {
    [schemaVariant: string]: AgentMap;
};

/**
 *  Example of a completed UsageCache:
 *
 * {
 *    Production: {
 *      ApolloServer3.x.x: {
 *        March: {
 *             total:  4567,
 *             queries: 1000,
 *             mutations: 1000,
 *             subscriptions: 2567,
 *             subscription-requests: 103,
 *             subscription-events: 2464
 *        },
 *        April: {
 *             total:  1234,
 *             queries: 100,
 *             mutations: 100,
 *             subscriptions: 1034,
 *             subscription-requests: 134,
 *             subscription-events: 900
 *        },
 *        [...]
 *     }
 *   }
 * }
 *
 *  total should equal queries + mutations + subscriptions
 *  subscriptions should equal subscription-requests + *-events
 */

// strong types for the data select logic in case anyone expands this feature in the future.
export interface DataSelector {
    agent: 'clientName' | 'agentVersion';
    operation: 'operationCount' | 'totalRequestCount';
}

// raw query data type union type so that we can use both billing and query data in one function.
export type UsageQueryData = BillingData[] | QuerySampleData[];

export type BillingData = Account__UsageQuery__BillingUsage & {
    groupBy: { [key: string]: string };
    metrics: { [key: string]: number };
};

export type QuerySampleData = Account__UsageQuery__QueryStats & {
    groupBy: { [key: string]: string };
    metrics: { [key: string]: number };
};

// main calc func,
// this takes in the raw query data and spits out a normalized UsageCache that we can use everywhere else.
export function buildUsageCache(
    data: UsageQueryData,
    usageSelector: DataSelector,
    granularity: unitOfTime.StartOf = 'month',
    groupByService: boolean = false
): UsageCache {
    // init cache
    const cache: UsageCache = {};
    // Reducer/data tree cache builder; for loops are more performant 🤟
    for (let metric of data) {
        if (!metric.groupBy) {
            metric = Object.assign({ groupBy: {} }, metric);
        }
        const schemaVariant: string = metric.groupBy.schemaTag || 'NONE';
        const serviceId: string = metric.groupBy.serviceId || 'UNKNOWN';

        let cacheID: string = schemaVariant;
        if (groupByService) {
            cacheID = `${serviceId}@${schemaVariant}`;
        }
        const agentVersion: string = metric.groupBy[usageSelector.agent] || 'NONE';
        const operationType: string = metric.groupBy.operationType || 'NONE';
        const operationSubtype: string = metric.groupBy.operationSubtype || '';
        // okay yes this looks bad but dates suck
        // convert the stringified timestamp from the API, which is in GMT
        let startDate = new Date(metric.timestamp);
        // now get the timezone offset, which is expressed in minutes. we convert to milliseconds
        let offset = startDate.getTimezoneOffset() * 60 * 1000;
        // and add it to the start time. what this does is effectively treat it as GMT 0 while still being in the user's timezone
        const month = moment(new Date(startDate.getTime() + offset))
            .startOf(granularity)
            .toISOString();
        // if data node doesn't exist, create empty object to avoid undefined errors.
        if (!cache[cacheID]) {
            cache[cacheID] = {};
        }
        if (!cache[cacheID][agentVersion]) {
            cache[cacheID][agentVersion] = {};
        }

        cache[cacheID][agentVersion][month] = cache[cacheID][agentVersion][month] || {};

        // total operation count sum reducer
        const totalSum = cache[cacheID][agentVersion][month].total || 0;
        cache[cacheID][agentVersion][month].total = totalSum + metric.metrics[usageSelector.operation];

        // totals for specific operation types (query, mutation, subscription)
        if (operationType === 'NONE' && operationSubtype === '') {
            cache[cacheID][agentVersion][month].noReportedOpType =
                cache[cacheID][agentVersion][month].noReportedOpType || 0;
            cache[cacheID][agentVersion][month].noReportedOpType += metric.metrics[usageSelector.operation];
        } else if (operationType === 'query') {
            cache[cacheID][agentVersion][month].queries = cache[cacheID][agentVersion][month].queries || 0;
            cache[cacheID][agentVersion][month].queries += metric.metrics[usageSelector.operation];
        } else if (operationType === 'mutation') {
            cache[cacheID][agentVersion][month].mutations = cache[cacheID][agentVersion][month].mutations || 0;
            cache[cacheID][agentVersion][month].mutations += metric.metrics[usageSelector.operation];
        } else if (operationType === 'subscription') {
            cache[cacheID][agentVersion][month].subscriptions = cache[cacheID][agentVersion][month].subscriptions || 0;
            cache[cacheID][agentVersion][month].subscriptions += metric.metrics[usageSelector.operation];

            // totals for specific operation subtype (subscription-request, *-event)
            if (operationSubtype === 'subscription-request') {
                cache[cacheID][agentVersion][month].subscriptionRequests =
                    cache[cacheID][agentVersion][month].subscriptionRequests || 0;
                cache[cacheID][agentVersion][month].subscriptionRequests += metric.metrics[usageSelector.operation];
            } else if (operationSubtype === 'subscription-event') {
                cache[cacheID][agentVersion][month].subscriptionEvents =
                    cache[cacheID][agentVersion][month].subscriptionEvents || 0;
                cache[cacheID][agentVersion][month].subscriptionEvents += metric.metrics[usageSelector.operation];
            }
        }
    }
    return cache;
}

// grand total calc.
// if you pass in opType, it will return the total for that operation type only
export function calcTotal(data: UsageCache, opType: OperationTypes = 'total'): string {
    let count: number = 0;
    for (const metric of Object.values(data)) {
        for (const entry of Object.values(metric)) {
            for (const countsByOpType of Object.values(entry)) {
                count += countsByOpType[opType] || 0;
            }
        }
    }
    return count.toLocaleString();
}

// monthly total calc.
export function calcMonthTotals(data: UsageCache): CountMap {
    let cache: CountMap = {};
    for (const variants of Object.values(data)) {
        for (const values of Object.values(variants)) {
            Object.entries(values).forEach(([month, opCountByType]) => {
                cache[month] = cache[month] || {
                    total: 0,
                    queries: 0,
                    mutations: 0,
                    subscriptions: 0,
                    subscriptionRequests: 0,
                    subscriptionEvents: 0,
                    noReportedOpType: 0,
                };
                cache[month].mutations += opCountByType.mutations || 0;
                cache[month].queries += opCountByType.queries || 0;
                cache[month].subscriptions += opCountByType.subscriptions || 0;
                cache[month].subscriptionRequests += opCountByType.subscriptionRequests || 0;
                cache[month].subscriptionEvents += opCountByType.subscriptionEvents || 0;
                cache[month].noReportedOpType += opCountByType.noReportedOpType || 0;
                cache[month].total += opCountByType.total || 0;
            });
        }
    }

    // fixing ordering of months
    const orderedCache = Object.keys(cache)
        .sort()
        .reduce(
            (acc, key) => ({
                ...acc,
                [key]: cache[key],
            }),
            {}
        );

    return orderedCache;
}

// variant aggregation total calc.
// if you pass in opType, it will return the total for that operation type only
export function calcVariantTotal(data: AgentMap, opType: OperationTypes = 'total'): string {
    let cache: number = 0;
    for (const agentUsage of Object.values(data)) {
        for (const val of Object.values(agentUsage)) {
            cache += val[opType] || 0;
        }
    }
    return cache.toLocaleString();
}
