import React, { FunctionComponent } from 'react';

interface Props {
    num?: number;
    style?: object;
    children: any;
}

const Column: FunctionComponent<Props> = ({ children, num = 3, style }) => <div style={style}>{children}</div>;

export default Column;
