import { colors } from '@apollo/space-kit/colors';
import { ChakraProvider, ChakraTheme, cssVar, theme as defaultChakraTheme, extendTheme } from '@chakra-ui/react';
import _ from 'lodash';
import React from 'react';

import { customColors } from './customColors';

// https://github.com/chakra-ui/chakra-ui/issues/4695#issuecomment-991023319
const $arrowBg = cssVar('popper-arrow-bg');

export const chakraThemeOverrides: Partial<ChakraTheme> = {
    components: {
        Accordion: {
            baseStyle: {
                container: {
                    border: 'none',
                },
                button: {
                    py: '8px',
                    pr: '12px',
                    pl: '16px',
                    gap: '8px',
                    border: '1px solid',
                    borderRadius: '4px',
                    bg: customColors.bg.primary,
                    color: customColors.text.primary,
                    borderColor: customColors.border.primary,
                    _expanded: {
                        borderBottomLeftRadius: '0px',
                        borderBottomRightRadius: '0px',
                    },
                    _focusVisible: {
                        boxShadow: 'outline',
                        borderColor: customColors.border.focused,
                    },
                    _hover: {
                        bg: customColors.bg.secondary,
                        borderColor: customColors.border.primary,
                    },
                },
                panel: {
                    padding: '24px 16px',
                    alignItems: 'flex-start',
                    gap: '24px',
                    alignSelf: 'stretch',
                    borderRadius: '0px 0px 4px 4px',
                    borderRight: '1px solid',
                    borderLeft: '1px solid',
                    borderBottom: '1px solid',
                    bg: customColors.bg.primary,
                    color: customColors.text.primary,
                    borderColor: customColors.border.primary,
                    fontFeatureSettings: "'clig' off, 'liga' off",
                    fontFamily: 'var(--font-body)',
                },
                icon: {
                    color: customColors.icon.primary,
                    marginLeft: 'auto',
                },
            },
        },
        Spinner: {
            baseStyle: {
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                borderColor: 'transparent',
            },
            variants: {
                primary: {
                    backgroundColor: customColors.brand.primary,
                },
                secondary: {
                    backgroundColor: customColors.brand.secondary,
                },
                tertiary: {
                    backgroundColor: customColors.brand.tertiary,
                },
                accent: {
                    backgroundColor: customColors.brand.accent,
                },
            },
            defaultProps: {
                variant: 'primary',
            },
        },
        Modal: {
            baseStyle: {
                header: { fontSize: '2xl', borderTopRadius: 'xl', textColor: customColors.text.heading },
                dialog: { borderRadius: 'xl', p: 4, bg: customColors.bg.primary },
                body: { pt: 1 },
                footer: { gap: 3, pt: 9 },
            },
            defaultProps: { isCentered: true, variant: 'primary' },
            variants: {
                primary: {
                    backgroundColor: customColors.brand.primary,
                },
                secondary: {
                    backgroundColor: customColors.brand.secondary,
                },
                tertiary: {
                    backgroundColor: customColors.brand.tertiary,
                },
                accent: {
                    backgroundColor: customColors.brand.accent,
                },
            },
        },
        Table: {
            defaultProps: {
                variant: 'unstyled',
            },
            baseStyle: {
                th: {
                    color: customColors.text.heading,
                    textColor: customColors.text.heading,
                },
                tr: {
                    '&:nth-of-type(odd)': {
                        'th,td': {
                            borderColor: customColors.border.primary,
                            borderBottomWidth: '1px',
                        },
                        backgroundColor: customColors.bg.primary,
                    },
                    '&:nth-of-type(even)': {
                        'th,td': {
                            borderColor: customColors.border.primary,
                            borderBottomWidth: '1px',
                        },
                        backgroundColor: customColors.bg.secondary,
                    },
                },
            },
        },
        Popover: {
            sizes: { sm: {} },
            baseStyle: {
                popper: {},
                content: {
                    width: 'xs',
                    minWidth: '18.5rem',
                    borderColor: customColors.border.primary,
                    backgroundColor: customColors.bg.primary,
                    color: customColors.text.primary,
                    [$arrowBg.variable]: customColors.bg.primary,
                    padding: '0.25rem 0rem 0.5rem 0rem',
                    borderRadius: '0.25rem',
                    background: customColors.bg.primary,
                },
                header: {
                    display: 'flex',
                    padding: '0.25rem 0.75rem',
                    alignItems: 'center',
                    gap: '0.5rem',
                    alignSelf: 'stretch',
                    fontFamily: `var(--font-heading)`,
                    fontSize: '1rem',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '1.5rem',
                    borderBottom: `1px solid ${customColors.border.primary}`,
                    color: customColors.text.heading,
                },
                closeButton: {
                    color: customColors.icon.primary,
                    top: '0.5rem',
                    right: '0.75rem',
                },
                body: {
                    color: customColors.text.primary,
                    fontFamily: `var(--font-body)`,
                    fontSize: '1rem',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '1.5rem',
                    padding: '0.25rem 0.75rem 0.5rem 0.75rem',
                },
                footer: {
                    borderTopColor: 'transparent',
                    display: 'flex',
                    justifyContent: 'end',
                    gap: '0.5rem',
                    padding: '0rem 0.75rem 0.25rem 0.75rem',
                },
                arrow: {
                    // do not override styles here
                },
            },
            variants: {
                tooltip: {
                    content: {
                        borderRadius: '4px',
                        backgroundColor: customColors.bg.black,
                        border: `1px solid ${customColors.border.black}`,
                        borderColor: customColors.border.black,
                        padding: '4px 8px',
                        lineHeight: '1.25rem',
                        color: customColors.text.white,
                        opacity: '95%',
                        fontSize: '0.875rem',
                        fontWeight: 'normal',
                        [$arrowBg.variable]: customColors.bg.black,
                    },
                },
            },
        },
        Checkbox: {
            baseStyle: {
                control: {
                    borderRadius: '0.25rem',
                    borderColor: customColors.theme.c4,
                    borderWidth: '1px',
                    bg: 'white',
                },
            },
        },
        Breadcrumb: {
            baseStyle: {
                container: {
                    // The class [&_ol]:pl-0 is necessary to remove the reset css
                    // `padding-inline-start` that pushes all lists in by 40px.
                    '& ol': { pl: 0 },
                },
            },
        },
        InputGroup: {
            baseStyle: {
                isolation: 'isolate',

                group: {
                    isolation: 'isolate',
                },
            },
        },
        Input: {
            baseStyle: {
                /**
                 * Styles set within { variants } will override base styles
                 * Styles set within { sizes } will override base styles
                 * The Input component uses "md" size and "outline" variant by default.
                 *
                 * You can unset those defaults by using null in defaultProps:
                 *    defaultProps: {
                 *      size: null,
                 *      variant: null
                 *    },
                 *
                 * You will lose all default styles this way, including things like focus.
                 */
                field: {
                    // Add custom base styles here
                    borderRadius: '8',
                    color: customColors.text.primary,
                    _focus: {
                        border: '2px solid',
                        borderColor: customColors.border.focused,
                        boxShadow: 'unset',
                        color: customColors.text.primary,
                    },
                    _invalid: {
                        border: '2px solid',
                        borderColor: customColors.border.error,
                    },
                },
                element: {
                    color: customColors.icon.primary,
                },
                group: {
                    isolation: 'isolate',
                },
            },
            sizes: {
                xs: {
                    field: {
                        borderRadius: 'base',
                    },
                },
                sm: {
                    field: {
                        borderRadius: 'base',
                    },
                },
                md: {
                    field: {
                        borderRadius: 'base',
                    },
                },
                lg: {
                    field: {
                        borderRadius: 'base',
                    },
                },
            },
            variants: {
                /**
                 * Input component will use "outline" styles by default.
                 * Styles set here will override anything in { baseStyle } and { sizes }
                 */
                outline: {
                    field: {
                        borderRadius: '8',
                        background: customColors.bg.input,
                        border: '1px solid',
                        borderColor: customColors.border.primary,
                        boxShadow: 'unset',
                        _placeholder: {
                            color: customColors.text.placeholder,
                        },
                        _focus: {
                            color: customColors.text.primary,
                            border: '2px solid',
                            borderColor: customColors.border.focused,
                            boxShadow: 'unset',
                            // the focused color always takes precedence, even if we're hovering over the input
                            _hover: {
                                borderColor: customColors.border.focused,
                            },
                        },
                        _hover: {
                            borderColor: customColors.border.hover,
                            boxShadow: 'unset',
                            _placeholder: {
                                color: customColors.text.placeholder,
                            },
                        },
                        _disabled: {
                            backgroundColor: customColors.bg.disabled,
                            opacity: 1,
                            borderColor: customColors.border.disabled,
                            boxShadow: 'unset',
                            cursor: 'auto',
                            _placeholder: {
                                color: customColors.text.disabled,
                            },
                        },
                        _invalid: {
                            boxShadow: 'unset',
                            borderColor: customColors.border.error,
                            _placeholder: {
                                color: customColors.text.placeholder,
                            },
                            _hover: {
                                borderColor: customColors.border.error,
                            },
                        },
                    },
                },
                hover: {
                    field: {
                        borderRadius: '8',
                        background: customColors.bg.input,
                        border: '1px solid',
                        borderColor: customColors.border.hover,
                        boxShadow: 'unset',
                        _placeholder: {
                            color: customColors.text.placeholder,
                        },
                        _focus: {
                            borderColor: customColors.border.focused,
                        },
                    },
                },
                focus: {
                    field: {
                        borderRadius: '8',
                        background: customColors.bg.input,
                        border: '2px solid',
                        borderColor: customColors.border.focused,
                        boxShadow: 'unset',
                        color: customColors.text.primary,
                        _placeholder: {
                            color: customColors.text.placeholder,
                        },
                    },
                },
            },
            defaultProps: {
                /**
                 * Set either or both of these to null to use only what's in { baseStyle }
                 */
                size: 'md',
                variant: 'outline',
            },
        },
        Textarea: {
            baseStyle: {
                padding: '12px',
                color: customColors.text.primary,
                _focus: {
                    border: '2px solid',
                    borderColor: customColors.border.focused,
                    boxShadow: 'unset',
                    color: customColors.text.primary,
                },
                _invalid: {
                    border: '2px solid',
                    borderColor: customColors.border.error,
                },
            },
            sizes: {
                md: { height: '72px' },
                lg: {
                    height: '160px',
                    fontSize: '16px',
                },
            },
            variants: {
                outline: {
                    borderRadius: '8',
                    background: customColors.bg.input,
                    border: '1px solid',
                    borderColor: customColors.border.primary,
                    boxShadow: 'unset',
                    _placeholder: {
                        color: customColors.text.placeholder,
                    },
                    _focus: {
                        color: customColors.text.primary,
                        border: '2px solid',
                        borderColor: customColors.border.focused,
                        boxShadow: 'unset',
                        // the focused color always takes precedence, even if we're hovering over the input
                        _hover: {
                            borderColor: customColors.border.focused,
                        },
                    },
                    _hover: {
                        borderColor: customColors.border.hover,
                        boxShadow: 'unset',
                        _placeholder: {
                            color: customColors.text.placeholder,
                        },
                    },
                    _disabled: {
                        backgroundColor: customColors.bg.disabled,
                        opacity: 1,
                        borderColor: customColors.border.disabled,
                        boxShadow: 'unset',
                        cursor: 'auto',
                        _placeholder: {
                            color: customColors.text.disabled,
                        },
                    },
                    _invalid: {
                        boxShadow: 'unset',
                        borderColor: customColors.border.error,
                        _placeholder: {
                            color: customColors.text.placeholder,
                        },
                        _hover: {
                            borderColor: customColors.border.error,
                        },
                    },
                },
                focus: {
                    borderRadius: '8',
                    background: customColors.bg.input,
                    border: '2px solid',
                    borderColor: customColors.border.focused,
                    boxShadow: 'unset',
                    color: customColors.text.primary,
                    _placeholder: {
                        color: customColors.text.placeholder,
                    },
                },
                hover: {
                    borderRadius: '8',
                    background: customColors.bg.input,
                    border: '1px solid',
                    borderColor: customColors.border.hover,
                    boxShadow: 'unset',
                    _placeholder: {
                        color: customColors.text.placeholder,
                    },
                    _focus: {
                        borderColor: customColors.border.focused,
                    },
                },
                defaultProps: {
                    size: 'md',
                    variant: 'outline',
                },
            },
        },
        FormError: {
            baseStyle: {
                text: {
                    color: customColors.theme.c7,
                    margin: '0px',
                    fontSize: '0.875rem',
                    lineHeight: '1.25rem',
                    display: 'flex',
                    flexDirection: 'row',
                },
                icon: {
                    alignSelf: 'start',
                    marginTop: '0.25rem',
                },
            },
        },
        // Form is the key for FormControl
        Form: {
            baseStyle: {
                container: {
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '4px',
                },
                helperText: {
                    color: customColors.text.secondary,
                    margin: '0px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    fontSize: '0.875rem',
                    lineHeight: '1.25rem',
                },
            },
        },
        FormLabel: {
            baseStyle: {
                color: customColors.theme.c7,
                fontSize: '1rem' /* 16px */,
                lineHeight: '1.5rem' /* 24px */,
                fontWeight: 600,
                margin: '0px',
            },
        },
        Badge: {
            baseStyle: {
                display: 'inline-flex',
                gap: '0.25rem',
                padding: '0.125rem 0.25rem',
                alignItems: 'center',
                borderRadius: '0.125rem',
                fontFamily: 'var(--font-body)',
                fontSize: '0.75rem',
                fontStyle: 'normal',
                fontWeight: 700,
                lineHeight: '1rem',
                textTransform: 'uppercase',
                borderWidth: '1px',
                borderColor: 'transparent',
                width: 'fit-content',
            },
            variants: {
                brandedDark: {
                    backgroundColor: customColors.brand.primary,
                    color: customColors.text.white,
                },
                brandedLight: {
                    backgroundColor: customColors.brand.secondary,
                    color: customColors.text.white,
                },
                neutral: {
                    backgroundColor: customColors.bg.neutral,
                    borderColor: 'transparent',
                    color: customColors.text.neutral,
                },
                neutralOutlined: {
                    backgroundColor: 'transparent',
                    borderColor: customColors.border.primary,
                    color: customColors.text.primary,
                },
                info: {
                    backgroundColor: customColors.bg.info,
                    color: customColors.text.info,
                },
                success: {
                    backgroundColor: customColors.bg.success,
                    color: customColors.text.success,
                },
                warn: {
                    backgroundColor: customColors.bg.warning,
                    color: customColors.text.warning,
                },
                error: {
                    backgroundColor: customColors.bg.error,
                    color: customColors.text.error,
                },
                beta: {
                    backgroundColor: customColors.bg.beta,
                    color: customColors.text.beta,
                },
                branded: {
                    backgroundColor: 'transparent',
                    borderColor: customColors.brand.accent,
                    color: customColors.text.primary,
                },
                yellow: {
                    backgroundColor: colors.yellow.base,
                    borderColor: '#E8C63C',
                },
                indigo: {
                    backgroundColor: colors.indigo.light,
                    borderColor: '#A493EA',
                },
                green: {
                    backgroundColor: colors.green.light,
                    borderColor: '#78CD9C',
                },
                purple: {
                    backgroundColor: colors.purple.lighter,
                    borderColor: '#DCC2F2',
                },
                pink: {
                    backgroundColor: colors.pink.light,
                    borderColor: '#F29BD5',
                },
                orange: {
                    backgroundColor: colors.orange.light,
                    borderColor: '#F2B788',
                },
                blue: {
                    backgroundColor: colors.blue.base,
                    borderColor: colors.blue.dark,
                    color: colors.white,
                },
            },
            defaultProps: {
                variant: 'neutralOutlined',
            },
        },
        Button: {
            baseStyle: {
                _hover: {
                    backgroundColor: customColors.btn['secondary-hover'],
                    _disabled: {
                        backgroundColor: customColors.bg.disabled,
                    },
                },
                _disabled: {
                    border: 'none',
                    backgroundColor: customColors.bg.disabled,
                    textColor: customColors.text.disabled,
                },

                _focus: {
                    boxShadow: 'none',
                },
                _focusVisible: {
                    outline: `2px solid ${customColors.border.focused}`,
                    outlineStyle: 'solid',
                    outlineOffset: '4px',
                    boxShadow: 'none',
                },
                _active: {
                    boxShadow:
                        '0px 0px 0px 1px rgba(18, 21, 26, 0.20) inset, 0px -1px 0px 0px rgba(18, 21, 26, 0.05) inset, 0px 2px 2px 0px rgba(18, 21, 26, 0.12) inset',
                },
            },
            variants: {
                primary: {
                    backgroundColor: customColors.btn.primary,
                    textColor: colors.white,
                    _hover: {
                        backgroundColor: customColors.btn['primary-hover'],
                    },
                },
                destructive: {
                    backgroundColor: customColors.btn.destructive,
                    textColor: colors.white,
                    _hover: {
                        backgroundColor: customColors.btn['destructive-hover'],
                    },
                },
                secondary: {
                    backgroundColor: customColors.btn.secondary,
                    textColor: customColors.text.primary,
                    borderWidth: 1,
                    borderColor: customColors.border.primary,
                    _hover: {
                        backgroundColor: customColors.btn['secondary-hover'],
                    },
                },
                hidden: {
                    backgroundColor: customColors.btn.secondary,
                    textColor: customColors.text.primary,
                    _hover: {
                        backgroundColor: customColors.btn['secondary-hover'],
                    },
                    _active: {
                        boxShadow: 'none',
                        backgroundColor: customColors.btn.primary,
                        textColor: colors.white,
                    },
                },
                buttonLink: {
                    backgroundColor: customColors.btn.secondary,
                    textColor: customColors.text.primary,
                    textDecoration: 'underline',
                    _hover: {
                        backgroundColor: customColors.btn['secondary-hover'],
                    },
                    _active: {
                        boxShadow: 'none',
                    },
                },
                brandDark: {
                    bg: customColors.brand.primary,
                    color: customColors.brand.tertiary,
                    _disabled: {
                        backgroundColor: customColors.brand.primary,
                        color: customColors.brand.tertiary,
                    },
                    _loading: { color: customColors.brand.tertiary },
                    border: '1px solid transparent',
                    _hover: {
                        background: 'transparent',
                        border: `1px solid ${customColors.brand.primary}`,
                        color: customColors.brand.primary,
                    },
                    _focus: {
                        outline: `1px solid ${customColors.brand.primary}`,
                        outlineStyle: 'solid',
                        outlineOffset: '4px',
                        boxShadow: 'none',
                    },
                    _focusVisible: {
                        outline: `1px solid ${customColors.brand.primary}`,
                        outlineStyle: 'solid',
                        outlineOffset: '4px',
                        boxShadow: 'none',
                    },
                },
                brandLight: {
                    bg: customColors.brand.secondary,
                    color: customColors.brand.primary,
                    _disabled: {
                        backgroundColor: customColors.brand.secondary,
                        color: customColors.brand.primary,
                    },
                    _loading: { color: customColors.brand.primary },
                    border: '1px solid transparent',
                    _hover: {
                        background: 'transparent',
                        border: `1px solid ${customColors.brand.primary}`,
                        color: customColors.brand.primary,
                    },
                    _focus: {
                        outline: `1px solid ${customColors.brand.primary}`,
                        outlineStyle: 'solid',
                        outlineOffset: '4px',
                        boxShadow: 'none',
                    },
                    _focusVisible: {
                        outline: `1px solid ${customColors.brand.primary}`,
                        outlineStyle: 'solid',
                        outlineOffset: '4px',
                        boxShadow: 'none',
                    },
                },
            },
            sizes: {
                lg: { fontSize: 16 },
                xs: { fontSize: 14 },
            },
        },
        Menu: {
            baseStyle: {
                zIndex: 10,
                list: {
                    boxShadow: `rgb(18 21 26 / 4%) 0px 3px 4px 0px,
          rgb(18 21 26 / 8%) 0px 4px 8px 0px,
          rgb(18 21 26 / 8%) 0px 0px 0px 1px`,
                    borderRadius: '4px',
                    width: 'fit',
                    minWidth: '190px',
                    maxWidth: '350px',
                    maxHeight: '600px',
                    overflowY: 'auto',
                    border: 'none',
                    padding: '6px',
                },
                item: {
                    alignItems: 'center',
                    fontSize: '13px',
                    borderRadius: '4px',
                    height: '28px',
                    padding: '4px 12px',
                    color: colors.black.base,

                    _hover: {
                        backgroundColor: colors.silver.base,
                    },
                    _active: {
                        backgroundColor: colors.silver.base,
                    },
                },
                groupTitle: {
                    fontStyle: 'normal',
                    fontWeight: 700,
                    margin: '0.5rem',
                    marginBottom: 0,
                    fontSize: '0.75rem',
                    color: colors.grey.dark,
                    fontFeatureSettings: "'clig' off, 'liga' off",
                    lineHeight: '1rem',
                    textTransform: 'uppercase',
                },
            },
            defaultProps: {
                autoSelect: false,
            },
        },
        MenuGroup: {
            baseStyle: {
                _title: {
                    fontStyle: 'normal',
                    fontWeight: 500,
                    color: 'green',
                },
            },
        },
        Alert: {
            baseStyle: {
                container: {
                    lineHeight: '20px',
                    fontSize: '14px',
                    borderRadius: '4px',
                    borderStyle: 'solid',
                    borderWidth: '1px',
                },
            },
            variants: {
                info: {
                    container: {
                        backgroundColor: colors.blue.lightest,
                        borderColor: colors.blue.lighter,
                        color: colors.blue.darker,
                    },
                    icon: {
                        color: colors.blue.base,
                    },
                },
                error: {
                    container: {
                        backgroundColor: colors.red.lightest,
                        borderColor: colors.red.light,
                        color: colors.red.darker,
                    },
                    icon: {
                        color: colors.red.base,
                    },
                },
                warn: {
                    container: {
                        backgroundColor: colors.orange.lightest,
                        borderColor: colors.orange.light,
                        color: colors.orange.darker,
                    },
                    icon: {
                        color: colors.orange.base,
                    },
                },
                success: {
                    container: {
                        backgroundColor: colors.green.lightest,
                        borderColor: colors.green.light,
                        color: colors.green.darkest,
                    },
                    icon: {
                        color: colors.green.base,
                    },
                },
                'new-feature': {
                    container: {
                        backgroundColor: colors.blilet.lightest,
                        borderColor: colors.blilet.light,
                        color: colors.blilet.darkest,
                    },
                    icon: {
                        color: colors.blilet.base,
                    },
                },
            },
        },
        Progress: {
            baseStyle: {
                track: {
                    borderLeftRadius: 'full',
                    borderRightRadius: 'full',
                    borderColor: colors.silver.base,
                    borderWidth: '1px',
                    background: customColors.bg.disabled,
                },
            },
            defaultProps: {
                size: 'sm',
            },
        },
        Code: {
            baseStyle: {
                bg: '',
                backgroundColor: colors.silver.light,
                px: 1,
                fontFamily: 'var(--font-mono)',
            },
        },
        Avatar: {
            baseStyle: { label: { fontWeight: 'semibold' } },
            sizes: {
                '2xs': { label: { fontSize: '12' } },
                xs: { container: { height: 5, width: 5 } },
                sm: { label: { fontSize: '14' } },
            },
        },
        Select: {
            baseStyle: {
                /**
                 * Styles set within { variants } will override base styles
                 * Styles set within { sizes } will override base styles
                 * The Input component uses "md" size and "outline" variant by default.
                 *
                 * You can unset those defaults by using null in defaultProps:
                 *    defaultProps: {
                 *      size: null,
                 *      variant: null
                 *    },
                 *
                 * You will lose all default styles this way, including things like focus.
                 */
                field: {
                    // Add custom base styles here
                },
                element: {
                    color: customColors.theme.c5,
                    cursor: 'pointer',
                },
                group: {
                    isolation: 'isolate',
                },
            },
            sizes: {
                xs: {
                    field: {
                        borderRadius: 'base',
                    },
                },
                sm: {
                    field: {
                        borderRadius: 'base',
                    },
                },
                md: {
                    field: {
                        borderRadius: 'base',
                    },
                },
                lg: {
                    field: {
                        borderRadius: 'base',
                    },
                },
            },
            variants: {
                /**
                 * Input component will use "outline" styles by default.
                 * Styles set here will override anything in { baseStyle } and { sizes }
                 */
                outline: {
                    field: {
                        background: customColors.theme.c1,
                        border: '1px solid',
                        borderColor: customColors.theme.c3,
                        boxShadow: 'unset',
                        cursor: 'pointer',
                        _placeholder: {
                            color: customColors.theme.c5,
                        },
                        _focus: {
                            borderColor: colors.blue.light,
                            boxShadow: 'unset',
                        },
                        _hover: {
                            borderColor: customColors.theme.c4,
                            boxShadow: 'unset',
                        },
                        _disabled: {
                            backgroundColor: customColors.bg.disabled,
                            opacity: 1,
                            borderColor: customColors.theme.c3,
                            boxShadow: 'unset',
                            cursor: 'auto',
                            _placeholder: {
                                color: customColors.theme.c5,
                                opacity: 0.7,
                            },
                        },
                        _invalid: {
                            boxShadow: 'unset',
                        },
                    },
                },
                hover: {
                    field: {
                        background: 'inherit',
                        border: '1px solid',
                        borderColor: customColors.theme.c4,
                        boxShadow: 'unset',
                        _focus: {
                            borderColor: customColors.theme.c4,
                        },
                        _hover: {
                            borderColor: customColors.theme.c4,
                            boxShadow: 'unset',
                        },
                    },
                },
                focus: {
                    field: {
                        background: 'inherit',
                        border: '1px solid',
                        borderColor: colors.blue.light,
                        boxShadow: 'unset',
                        _focus: {
                            borderColor: colors.blue.light,
                            boxShadow: 'unset',
                        },
                        _hover: { borderColor: colors.blue.light, boxShadow: 'unset' },
                    },
                },
            },
            defaultProps: {
                /**
                 * Set either or both of these to null to use only what's in { baseStyle }
                 */
                size: 'md',
                variant: 'outline',
            },
        },
        Tooltip: {
            baseStyle: {
                borderRadius: '4px',
                backgroundColor: colors.black.base,
                padding: '4px 8px',
                lineHeight: '1.25rem',
                color: colors.white,
                opacity: '95%',
                fontSize: '0.875rem',
                [$arrowBg.variable]: colors.black.base,
            },
        },
        Radio: {
            baseStyle: {
                control: {
                    backgroundColor: colors.white,
                    borderColor: colors.grey.lighter, // TODO: replace with customColors.border.deselected,
                    borderWidth: '1px',
                    _checked: {
                        borderColor: colors.blue.base, // TODO: replace with customColors.bg.selected,
                        bg: colors.blue.base, // TODO: replace with customColors.bg.selected,
                    },
                },
            },
            variants: {
                brand: {
                    control: {
                        _checked: {
                            borderColor: customColors.brand.primary,
                            bg: customColors.brand.primary,
                        },
                    },
                },
            },
        },
        Stepper: {
            baseStyle: {
                indicator: {
                    color: customColors.icon.primary,
                    '&[data-status=complete]': {
                        bg: customColors.bg.notice,
                        textColor: customColors.text.inverted,
                        borderColor: customColors.border.neutral,
                    },
                    '&[data-status=incomplete]': {
                        bg: customColors.bg.primary,
                        borderColor: customColors.border.disabled,
                    },
                    '&[data-status=active]': {
                        bg: customColors.bg.notice,
                        borderColor: customColors.border.neutral,
                    },
                },
                number: {
                    color: customColors.icon.primary,
                    '&[data-status=complete]': {
                        textColor: customColors.text.inverted,
                    },
                    '&[data-status=incomplete]': {
                        textColor: customColors.text.disabled,
                    },
                    '&[data-status=active]': {
                        textColor: customColors.text.disabled,
                    },
                },
                stepper: {
                    bg: customColors.bg.secondary,
                    color: customColors.text.primary,
                    borderWidth: '1px',
                    borderColor: customColors.border.primary,
                    padding: '12px 12px',
                    borderRadius: '4px',
                },
                title: {
                    fontSize: 'lg',
                    fontWeight: '600',
                    '&[data-status=complete]': {
                        textColor: customColors.text.disabled,
                    },
                    '&[data-status=incomplete]': {
                        textColor: customColors.text.disabled,
                    },
                },
                step: {
                    baseStyle: {
                        label: {
                            color: customColors.text.primary,
                        },
                    },
                },
            },
        },
    },
    colors: {
        ...customColors,
        ..._.mapValues(colors, (value) =>
            typeof value === 'string'
                ? value
                : {
                      ...value,
                      200: value.lighter,
                      300: value.lighter,
                      400: value.light,
                      500: value.base,
                      600: value.dark,
                      700: value.darker,
                      // TODO: does not having these values cause problems?
                      ...('lightest' in value
                          ? {
                                50: value.lightest,
                                100: value.lightest,
                                800: value.darkest,
                                900: value.darkest,
                            }
                          : {}),
                  }
        ),
    },
    styles: {
        global: {
            body: {
                fontFamily: false,
                lineHeight: false,
                color: false,
                bg: false,
            },
            '*::placeholder': {
                color: false,
            },
            '*, *::before, &::after': {
                borderColor: false,
                wordWrap: false,
            },
        },
    },
};

export const theme = extendTheme(chakraThemeOverrides, {
    ...defaultChakraTheme,
    components: {
        ...defaultChakraTheme.components,
        Button: {
            ...defaultChakraTheme.components.Button,
            variants: {
                // TODO: remove usages of these variants and then remove these from the theme.
                // @ts-ignore
                ghost: defaultChakraTheme.components.Button.variants.ghost,
                // @ts-ignore
                outline: defaultChakraTheme.components.Button.variants.outline,
                // @ts-ignore
                link: defaultChakraTheme.components.Button.variants.link,
                // @ts-ignore
                unstyled: defaultChakraTheme.components.Button.variants.unstyled,
            },
        },
    },
});

export const StylelessChakraProvider = ({
    useNewTheme = false,
    children,
}: {
    useNewTheme?: boolean;
    children: React.ReactElement;
}) => (
    <ChakraProvider
        resetCSS={false}
        theme={{
            ...theme,
            components: {
                ...theme.components,
                Link: {
                    baseStyle: useNewTheme
                        ? {
                              fontWeight: 'semibold',
                              textDecoration: 'underline',
                              textUnderlineOffset: 4,
                          }
                        : {
                              color: customColors.text.link,
                          },
                },
            },
        }}
    >
        {children}
    </ChakraProvider>
);
