import React, { useState } from 'react';
import { TypedDocumentNode, useQuery, gql } from '@apollo/client';
import moment from 'moment';
import * as Types from '../../types';
import AccountUsageBox from '../../components/AccountUsageBox';
import { BillingData, buildUsageCache, DataSelector, QuerySampleData, UsageQueryData } from '../../helpers/UsageCalc';
import Tab from './TabNav';

// statsCache allows us to use the `refetch` function of Apollo Client to refetch data using a new range, effectively allowing
// for easier pagination
type StatsCache = {
    currentAccount: String;
    usageStats: BillingData[];
    queryStats: QuerySampleData[];
    finished: boolean;
    maxRange: number;
    shouldShowDetailedCounts: boolean;
};

const RECORD_LIMIT = 10 * 1000; // druid limit on records
let statsCache: StatsCache = {
    currentAccount: '',
    usageStats: [],
    queryStats: [],
    finished: false,
    maxRange: 90,
    shouldShowDetailedCounts: false,
};

const USAGE_QUERY: TypedDocumentNode<Types.Account__UsageQuery, Types.Account__UsageQueryVariables> = gql`
    fragment Account__UsageQuery__QueryStats on AccountQueryStatsRecord {
        timestamp
        groupBy {
            serviceId
        }
        metrics {
            totalRequestCount
        }
        groupBy {
            operationType
            operationSubtype
        }
    }

    fragment Account__UsageQuery__BillingUsage on AccountBillingUsageStatsRecord {
        metrics {
            operationCount
        }
        groupBy {
            agentVersion
            schemaTag
            operationType
            operationSubtype
            serviceId
        }
        timestamp
    }

    query Account__UsageQuery($accountId: ID!, $from: Timestamp!) {
        account(id: $accountId) {
            id
            currentPlan {
                maxRangeInDays
            }
            statsWindow(from: $from, resolution: R1D) {
                billingUsageStats {
                    ...Account__UsageQuery__BillingUsage
                }
                queryStats {
                    ...Account__UsageQuery__QueryStats
                }
            }
        }
    }
`;

export const usageSelector = {
    billing: {
        agent: 'agentVersion',
        operation: 'operationCount',
    } as DataSelector,
    query: {
        agent: 'clientName',
        operation: 'totalRequestCount',
    } as DataSelector,
};

const UsageTable: React.FC<{ accountId: string }> = ({ accountId }) => {
    const [activeTab, setActiveTab] = useState<string>('billing');

    let from = -50457600; // 548 days * 24 hours * 60 minutes * 60 seconds, which is the max storage time for most accounts
    const { data, loading, error, refetch } = useQuery<Types.Account__UsageQuery, Types.Account__UsageQueryVariables>(
        USAGE_QUERY,
        {
            variables: { accountId, from: from.toString() },
            skip: accountId === statsCache.currentAccount && statsCache.finished, // skip if we've already got data w/in the local cache
        }
    );

    if (loading) {
        return (
            <div className="d-flex justify-content-center my-5">
                <div className="spinner-grow text-primary center" role="status">
                    <span className="sr-only" />
                </div>
            </div>
        );
    }

    if (error) {
        return <div className="error">{error.message}</div>;
    }

    if (
        data?.account &&
        data.account.currentPlan &&
        data.account.statsWindow &&
        data.account.statsWindow.billingUsageStats &&
        data.account.statsWindow.queryStats
    ) {
        // reset the cache if navigating to the page again for a new company
        if (statsCache.currentAccount !== accountId) {
            statsCache = {
                currentAccount: accountId,
                usageStats: [],
                queryStats: [],
                finished: false,
                maxRange: data.account.currentPlan.maxRangeInDays ?? 90,
                shouldShowDetailedCounts: false,
            };
        }

        // This check basically means "are there any metrics without a reported operation type?" (i.e. query, mutation, subscription)
        // If the customer is reporting operationType, then we can display detailed columns, otherwise we just show the total counts
        statsCache.shouldShowDetailedCounts = data?.account?.statsWindow?.billingUsageStats
            .map((e) => e.groupBy.operationType)
            .some((e) => e !== null);
        statsCache.usageStats.push(...(data.account.statsWindow.billingUsageStats as unknown as BillingData[]));
        statsCache.queryStats.push(...(data.account.statsWindow.queryStats as unknown as QuerySampleData[]));

        let newFrom: number = 0;
        // check for cap on usage
        if (data.account.statsWindow.billingUsageStats.length === RECORD_LIMIT) {
            let now = new Date();
            let ts = new Date(data.account.statsWindow.billingUsageStats[RECORD_LIMIT - 1].timestamp);
            newFrom = Math.round((ts.valueOf() - now.valueOf()) / 1000) - 1;
        }

        // check for cap on query
        if (data.account.statsWindow.queryStats.length === RECORD_LIMIT) {
            let now = new Date();
            let ts = new Date(data.account.statsWindow.queryStats[RECORD_LIMIT - 1].timestamp);
            let temp = Math.round((ts.valueOf() - now.valueOf()) / 1000) - 1;
            // if the temp is less than the newFrom (offset), use that, otherwise use whatever it was before (including 0)
            newFrom = newFrom > temp ? temp : newFrom;
        }

        // if either were overridden, refetch...
        if (newFrom !== 0) {
            refetch({ accountId, from: newFrom.toString() });
        } else {
            // ...or we're done, and mark as so
            statsCache.finished = true;
        }
    }

    // now filter out the duplicate data since we had to potentially paginate over a day multiple times, so sanity checking
    statsCache.usageStats = statsCache.usageStats.filter(
        (v1, i, a) =>
            a.findIndex(
                (v2) =>
                    v1.timestamp === v2.timestamp &&
                    v1.groupBy.agentVersion === v2.groupBy.agentVersion &&
                    v1.groupBy.schemaTag === v2.groupBy.schemaTag &&
                    v1.groupBy.serviceId === v2.groupBy.serviceId &&
                    v1.groupBy.operationType === v2.groupBy.operationType &&
                    v1.groupBy.operationSubtype === v2.groupBy.operationSubtype
            ) === i
    );
    statsCache.queryStats = statsCache.queryStats.filter(
        (v1, i, a) =>
            a.findIndex(
                (v2) =>
                    v1.timestamp === v2.timestamp &&
                    v1.groupBy.serviceId === v2.groupBy.serviceId &&
                    v1.groupBy.operationType === v2.groupBy.operationType &&
                    v1.groupBy.operationSubtype === v2.groupBy.operationSubtype // hidden grouping that's now explicit
            ) === i
    );

    // Generate normalized cache objects to render the computed total count charts
    const billingCache = buildUsageCache(statsCache.usageStats as UsageQueryData, usageSelector.billing, 'month', true);
    const queryCache = buildUsageCache(statsCache.queryStats as UsageQueryData, usageSelector.query);
    const showTotalOperationsInfoMsg =
        (statsCache.usageStats.some((s) => s.groupBy.operationType == null) &&
            statsCache.usageStats.some((s) => s.groupBy.operationType != null)) ||
        false;
    return (
        <>
            <ul className="flex flex-shrink flex-row pb-4">
                <Tab
                    id="billing"
                    text="Billing Operations"
                    active={activeTab}
                    setActive={(key: string) => setActiveTab(key)}
                />
                <Tab
                    id="query"
                    text="Query Operations (Sampled)"
                    active={activeTab}
                    setActive={(key: string) => setActiveTab(key)}
                />
            </ul>

            {activeTab === 'query'
                ? queryCache && (
                      <AccountUsageBox
                          type="query"
                          cache={queryCache}
                          title="Query Usage Metrics (Customer sampled)"
                          groupText=""
                          shouldShowDetailedCounts={statsCache.shouldShowDetailedCounts}
                      >
                          <p className="text-sm pb-2 text-primary pt-1">
                              Query usage metrics reflect requests send to all variants of all graphs, and account for
                              customer sampling.
                              {showTotalOperationsInfoMsg ? (
                                  <>
                                      Total Operations reflects billing responsibilities; but may not add up to other
                                      columns due to different reporting mechanisms.
                                  </>
                              ) : null}
                          </p>
                          <div className="mb-1 mt-4">
                              <p>
                                  Monthly Usage (last{' '}
                                  {Math.round(moment.duration(statsCache.maxRange || 0, 'days').asMonths())} months):
                              </p>
                          </div>
                      </AccountUsageBox>
                  )
                : billingCache && (
                      <>
                          <AccountUsageBox
                              type="billing"
                              cache={billingCache}
                              title="Billing Usage Metrics"
                              groupText="🪐 Variant & 🔭 User Agent"
                              shouldShowDetailedCounts={statsCache.shouldShowDetailedCounts}
                          >
                              <p className="text-sm pb-2 text-secondary pt-1">
                                  Billing usage metrics include, non-sampled requests sent to all variants of all
                                  graphs.
                                  <br />
                                  Billing usage metrics have been available since March 2022, only for customers using
                                  Apollo Server 3.6.6+ or Router.
                                  {showTotalOperationsInfoMsg ? (
                                      <>
                                          <br />
                                          Total Operations reflects billing responsibilities; but may not add up to
                                          other columns due to different reporting mechanisms.
                                      </>
                                  ) : null}
                              </p>
                              <div className="mb-1 mt-4">
                                  <p>
                                      Monthly Usage (last{' '}
                                      {Math.round(moment.duration(statsCache.maxRange || 0, 'days').asMonths())}{' '}
                                      months):
                                  </p>
                              </div>
                          </AccountUsageBox>
                      </>
                  )}
        </>
    );
};

export default UsageTable;
