import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import UserTab from './Account/UserTab';
import MetricsTab from './Account/MetricsTab';
import BillingTab from './Account/BillingTab';
import Tab from './Account/TabNav';
import GraphsTab from './Account/GraphsTab';
import SupportTab from './Account/SupportTab';
import Config from '../config';
import { Tags } from './Subscriptions/SubscriptionTags';
import { gql, useQuery } from '@apollo/client';
import { HeaderContext } from '../helpers/HeaderContext';
import { SAFTab } from './Account/SAFTab';
import { Account__MetadataQuery, Account__MetadataQueryVariables } from '../types';
import { ReactComponent as StudioIcon } from '@apollo/icons/default/IconTelescope1.svg';
import { Button } from '@chakra-ui/react';

const ACCOUNT_METADATA_FRAGMENT = gql`
    fragment Account__AccountMetadataCommonFragment on Account {
        id
        internalID
        secondaryIDs
        name
        billingContactEmail
        avatarUrl
        sso {
            provider
            idpid
            defaultRole
        }
        billingInfo {
            cardType
            lastFour
        }
        isLocked
    }
`;

const ACCOUNT_SUBSCRIPTION_PLAN_FRAGMENT = gql`
    fragment Account__AccountSubscriptionPlanCommonFragment on Account {
        subscriptions {
            state
            activatedAt
            canceledAt
            plan {
                id
                name
            }
        }
        currentPlan {
            id
            kind
            name
            tier
        }
        currentSubscription {
            uuid
            trialExpiresAt
            activatedAt
            autoRenew
            canceledAt
            expiresAt
            currentPeriodStartedAt
            currentPeriodEndsAt
        }
    }
`;

const ACCOUNT_METADATA_QUERY = gql`
    query Account__MetadataQuery($accountId: ID!) {
        account(id: $accountId) {
            ...Account__AccountMetadataCommonFragment
            ...Account__AccountSubscriptionPlanCommonFragment
        }
    }
    ${ACCOUNT_METADATA_FRAGMENT}
    ${ACCOUNT_SUBSCRIPTION_PLAN_FRAGMENT}
`;

const Account = () => {
    const { accountId } = useParams();
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState<string>('metrics');
    const handleOnClick = useCallback(
        (path: string) => {
            setActiveTab(path);
            navigate(path, { replace: false });
        },
        [navigate]
    );
    const { data } = useQuery<Account__MetadataQuery, Account__MetadataQueryVariables>(ACCOUNT_METADATA_QUERY, {
        variables: { accountId: accountId ?? '' },
    });

    const account = data?.account;
    const { setContent } = useContext(HeaderContext);
    const tabs = useMemo(
        () => [
            {
                id: 'metrics',
                text: 'Metrics',
            },
            {
                id: 'graphs',
                text: 'Graphs',
            },
            {
                id: 'billing',
                text: 'Billing',
            },
            {
                id: 'users',
                text: 'Users',
            },
            // {
            //     id: 'support',
            //     text: 'Support',
            // },
            {
                id: 'saf',
                text: 'SAF',
            },
        ],
        []
    );

    useEffect(() => {
        if (!account) {
            return;
        }
        setContent(
            <div className="flex flex-row text-sm items-center pl-10 md:pl-0">
                {account.avatarUrl && (
                    <img
                        src={account.avatarUrl}
                        alt="avatar icon for customer account"
                        className="object-contain w-8 h-8 mr-2 hidden md:inline-flex"
                    />
                )}
                <div className="flex flex-row items-center grow">
                    <div className="flex flex-row items-center pr-4 space-x-2">
                        <div className="lg:text-lg sm:text-sm font-bold">{account.name}</div>
                        <Tags account={account} isLocked={!!account.isLocked} includeBilling={false} />
                    </div>
                    <div className="flex flex-row flex-shrink items-center">
                        {tabs.map((tab) => (
                            <Tab
                                key={tab.id}
                                id={tab.id}
                                text={tab.text}
                                active={activeTab}
                                setActive={(key: string) => handleOnClick(key)}
                            />
                        ))}
                    </div>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        className="font-bold ml-auto pr-2"
                        href={`${Config.frontendURL}account/${account.id}`}
                    >
                        <Button type="button" variant={'primary'} size={'sm'}>
                            <StudioIcon className="size-4 lg:size-3 fifill-icon-primary" />
                            <div className="hidden lg:inline-flex pl-2">View in Studio</div>
                        </Button>
                    </a>
                </div>
            </div>
        );
    }, [account, activeTab, setActiveTab, handleOnClick, setContent, tabs]);

    return (
        <div className="">
            {!accountId ? (
                'We could not find an account in your URL to use for lookup.'
            ) : (
                <>
                    <>
                        <main>
                            <Routes>
                                <Route
                                    path="metrics"
                                    key="metrics"
                                    element={<MetricsTab setActiveTab={setActiveTab} accountId={accountId} />}
                                />
                                <Route
                                    path="billing"
                                    key="billing"
                                    element={<BillingTab setActiveTab={setActiveTab} accountId={accountId} />}
                                />
                                <Route
                                    path="users"
                                    key="users"
                                    element={<UserTab setActiveTab={setActiveTab} accountId={accountId} />}
                                />
                                <Route
                                    path="graphs/*"
                                    key="graphs"
                                    element={
                                        <GraphsTab accountId={accountId} daysBack={90} setActiveTab={setActiveTab} />
                                    }
                                />
                                <Route
                                    path="support"
                                    key="support"
                                    element={<SupportTab setActiveTab={setActiveTab} accountId={accountId} />}
                                />
                                <Route
                                    path="saf/*"
                                    element={<SAFTab setActiveTab={setActiveTab} accountId={accountId} />}
                                    key="saf"
                                />
                                <Route
                                    path="/"
                                    element={<MetricsTab setActiveTab={setActiveTab} accountId={accountId} />}
                                />
                            </Routes>
                        </main>
                    </>
                </>
            )}
        </div>
    );
};

export default Account;
