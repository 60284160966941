import React, { FunctionComponent } from 'react';
import { useQuery, gql } from '@apollo/client';
import Config from '../config';

import * as Types from '../types';

const LOGOUT_QUERY = gql`
    query Auth__LogOutQuery {
        me {
            id
        }
    }
`;

type Props = {
    children?: React.ReactNode;
};
const Section: FunctionComponent<Props> = () => (
    <div className="logout">
        <a
            href={`${Config.frontendURL}logout?cb=${window.location.href}`}
            onClick={(e) => {
                e.preventDefault();
                // clearing out the saf localstorage key since clearing all localstorage will potentially affect non-admin related pages
                localStorage.removeItem('safLocalStore');
                window.location.href = `${Config.frontendURL}logout?cb=${window.location.href}`;
            }}
        >
            <button>Logout</button>
        </a>
    </div>
);

const Logout: FunctionComponent = () => {
    const { data, loading, error } = useQuery<Types.Auth__LogOutQuery>(LOGOUT_QUERY);

    if (loading) return <Section>Loading...</Section>;
    if (error) return <Section>{error && error.message}</Section>;
    if (!data || !data.me) return <Section>Nothing to show here</Section>;

    return <Section />;
};

export default Logout;
