import { useEffect } from 'react';
import { DeletedMetadata } from '../pages/Account/DeletedMetadata';
import { TabContentProps } from '../pages/Account/TabNav';

interface BillingTabProps extends TabContentProps {}

export default function BillingTab({ accountId, setActiveTab }: BillingTabProps) {
    useEffect(() => {
        setActiveTab('billing');
    });
    return <DeletedMetadata internalAccountId={accountId} />;
}
